import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalsService } from '@core/core-services/globals.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';

@Injectable()
export class AuthenticationService {
  public token: string;
  public user: any;
  private userSnap = new BehaviorSubject<any>(this.userMapper());

  private isLoggedIn = new Subject<boolean>();
  public isLoggedIn$ = this.isLoggedIn.asObservable();

  apiURL = environment.api + '/api/auth';

  constructor(
    private http: HttpClient,
    public gs: GlobalsService,
    private store: Store
  ) {
    // set token if saved in local storage
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = 'currentUser && currentUser.token';
  }

  isUserLoggedIn(): Observable<boolean> {
    // return (this.token && this.token.length > 1) as Observable<boolean>;
    //     console.log(JSON.parse(localStorage.getItem('User')), this.isLoggedIn || JSON.parse(localStorage.getItem('User')));
    return this.isLoggedIn as Observable<boolean>;
  }

  getLoginStatus() {
    return this.isLoggedIn;
  }

  updateLoginStatus(status: boolean) {
    this.isLoggedIn.next(status);
  }

  private userMapper(): Observable<any> {
    return this.user;
  }

  getUser(): Observable<any> {
    // return (this.token && this.token.length > 1);
    return this.userSnap as Observable<any>;
  }

  postLogout(): void {
    this.updateLoginStatus(false);
    this.http.post(this.apiURL + '/logout', {});
  }

  getLogo(domain: string): Observable<any> {
    return this.http.get(
      environment.api + '/api/domain-mapping?domain=' + domain
    );
  }

  // login(username: string, password?: string, token?: string): Observable<boolean> {
  //     localStorage.clear();
  //     return this.http
  //         .post(this.apiURL  + '/authenticate', {
  //             email: username,
  //             password: password,
  //             token: token
  //         }).pipe(map((r: HttpResponse<any>) => {
  //             let token = r['success'] && r['response']['token'];
  //             console.log(r);
  //             if (token) {
  //                 // set token property
  //                 this.token = token;
  //                 this.store.dispatch(new UserActions.AddUserData((r['response']) as UserInterface)).subscribe(() => {
  //                     localStorage.setItem('User', JSON.stringify(r['response']));
  //                     this.updateLoginStatus(true);
  //                     return true;
  //                 });
  //
  //                 // // store username and jwt token in local storage to keep user logged in between page refreshes
  //                 // localStorage.setItem('User', JSON.stringify(r['response']));
  //                 // this.updateLoginStatus(true);
  //             }
  //             return (r['success']) as boolean
  //         })) as Observable<boolean>;
  // };

  login2(username: string, password?: string, token?: string): Observable<any> {
    return this.http.post(this.apiURL + '/authenticate', {
      email: username,
      password: password,
      token: token,
    });
  }

  // lionLogin(): Observable<boolean> {
  //     return this.http
  //         .post(this.apiURL  + '/authenticate', {caller: document.location.origin}).pipe(map((r: HttpResponse<any>) => {
  //             // console.log(r['response']);
  //             window.open(r['response'], "_self");
  //             return false;
  //         })) as Observable<boolean>;
  // }
  //

  ppcLogin(ppctoken): Observable<boolean> {
    return this.http
      .post(this.apiURL + '/authenticate', { ppctoken: ppctoken })
      .pipe(
        map((r: HttpResponse<any>) => {
          let token = r['success'] && r['response']['token'];
          if (token) {
            // set token property
            this.token = token;
            // store username and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('User', JSON.stringify(r['response']));
            this.updateLoginStatus(true);
          }
          return r['success'] as boolean;
        })
      ) as Observable<boolean>;
  }

  requestPasswordById(userId: string): Observable<any> {
    return this.http.put(this.apiURL + '/password/resend', { userId });
  }

  forgotPassword(email: string, captchaToken: string): Observable<any> {
    return this.http.post(this.apiURL + '/password/forgot', {
      email,
      captchaToken,
    });
  }

  verifyCachedObject(cacheId: string): Observable<any> {
    return this.http.get(this.apiURL + `/cache/${cacheId}`);
  }

  updateUserPassword(
    userId: string,
    password: string,
    cacheId: string
  ): Observable<any> {
    return this.http.put(this.apiURL + `/${userId}/set-password`, {
      password,
      cacheId,
    });
  }

  clearStorage() {
    this.store.dispatch(new StateClear()).subscribe(() => {
      localStorage.clear();
    });
  }
}
