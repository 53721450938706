<div class="wrapper">
  <div class="container">
    <div class="main" [ngClass]="{'main--fullWidth': sidebar.children.length === 0}">
      <div class="cursor-pointer absolute -right-2 -top-2 p-2" (click)="showSidebar = !showSidebar">
        <span class="eva text-h4"
              [matTooltip]="showSidebar ? 'Collapse sidebar' : 'Expand View'"
              [ngClass]="{ 'eva-expand-outline': showSidebar, 'eva-collapse-outline': !showSidebar }">
        </span>
      </div>

      <ng-content select="[main]"></ng-content>
    </div>

    <div [hidden]="!showSidebar" #sidebar [ngClass]="{sidebar: sidebar.children.length > 0 }">
      <ng-content select="[sidebar]"></ng-content>
    </div>
  </div>
</div>
