import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Directive({
  selector: '[appDigitinputter]'
})
export class DigitinputterDirective implements OnInit {

  timer;
  @Input() number_fmt = '1.0-0';
  @Input() locale = 'en-US';

  constructor(private el: ElementRef, private _decimalPipe: DecimalPipe) {
  }

  ngOnInit() {
    this.formatValue(0);
  }

  @HostListener('change', ['$event'])
  onChange(event: KeyboardEvent) {
    this.formatValue();
  }

  formatValue(delay = 50) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // let num = parseFloat(this.el.nativeElement.value.replace(/,/g,''));
      const num = parseFloat(this.el.nativeElement.value.replace(/[^\d\.\-]/g, ''));
      const new_value = this._decimalPipe.transform(num, this.number_fmt, this.locale);
      if (new_value !== this.el.nativeElement.value) {
        this.el.nativeElement.value = new_value;
      }
    }, delay);
  }
}
