import { Component, OnInit } from '@angular/core';
import { UserService } from '@core/core-services/user.service';
import { GlobalsService } from '@core/core-services/globals.service';
import { AuthenticationService } from '../modules/auth/services/authentication.service';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AppActions } from '@core/@store/app/app.actions';
import { Observable } from 'rxjs';
import { AppsState } from '@core/@store/app/app.store';
import { User } from '@core/interceptors/models/user';
import { GroupByPipe } from '@shared/pipes/pipes/group-by.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  client: any = null;
  market: any = null;
  appGroups: any[];
  user: User;
  selectedTab = 0;

  @Select(AppsState.getAppList) clientAppList$: Observable<any>;

  loading = true;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public gs: GlobalsService,
    private store: Store,
    private groupByPipe: GroupByPipe
  ) {}

  ngOnInit() {
    this.clientAppList$.subscribe((apps) => {
      this.selectedTab = 0;
      if (apps && apps.length) {
        this.appGroups = this.groupByPipe.transform(apps, 'grp');
        const allApps = this.mergeAllGroups(this.appGroups);
        this.appGroups.splice(0, 0, { key: 'All', value: allApps });
      }
      this.loading = false;
      if (
        this.appGroups &&
        this.appGroups.length === 2 &&
        this.appGroups[0].value.length === 1
      ) {
        this.gotoRoute(this.appGroups[0].value[0], false);
      }
    });
  }

  setupApps() {
    this.userService.getApps().subscribe((appGroups) => {
      this.appGroups = appGroups.apps.map((x) => {
        const grp = x;
        grp['grp'] = x['group']['name'];
        return grp;
      });
      this.gs.setGlobalApps(this.appGroups);
      // check if there is only no app
      this.loading = false;
    });
  }

  gotoRoute(feature, isExternal) {
    this.store
      .dispatch(new AppActions.SetSelectedApp(feature))
      .subscribe(() => {
        if (isExternal) {
          this.router.navigate(['/app', feature._id]);
        } else {
          this.router.navigate([feature.route]);
        }
      });
  }

  login() {
    this.router.navigate(['auth', 'login']);
  }

  mergeAllGroups(groupData: any[]) {
    const data = [];
    groupData.forEach((group: any) => {
      data.push(...group.value);
    });
    return data;
  }
}
