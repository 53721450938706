import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadDataUrlService {
  download(name: string, dataUrl: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
