import { State, Action, StateContext, Selector } from '@ngxs/store';
import { MosaicStateModel } from './mosaic.model';
import { MosaicActions } from './mosaic.actions';
import AddSortByValue = MosaicActions.AddMosaic;
import { Injectable } from '@angular/core';

@State<MosaicStateModel>({
  name: 'mosaic',
  defaults: {
    mosaic: {
      width: 0,
      height: 0,
      tilesX: 0,
      tilesY: 0,
    },
  },
})
@Injectable()
export class MosaicState {
  constructor() {}

  @Selector()
  static getCurrentMosaic(state: MosaicStateModel) {
    return state && state.mosaic;
  }

  @Action(AddSortByValue)
  addSortByValue(
    { patchState }: StateContext<MosaicStateModel>,
    { payload }: MosaicActions.AddMosaic
  ) {
    // const state = ctx.getState();
    // ctx.setState({
    //   ...state,
    //   mosaic: {
    //     ...state.mosaic,
    //     ...payload,
    //   }
    // });

    patchState({
      mosaic: {
        width: payload.width,
        height: payload.height,
        tilesX: payload.tilesX,
        tilesY: payload.tilesY,
      },
    });
  }

  @Action(MosaicActions.ClearMosaic)
  clearSortByValue({ patchState }: StateContext<MosaicStateModel>) {
    patchState({
      mosaic: {
        width: 0,
        height: 0,
        tilesX: 0,
        tilesY: 0,
      },
    });
  }
}
