import { State, Action, StateContext, Selector } from '@ngxs/store';
import { UserActions } from './user.actions';
import { tap } from 'rxjs/operators';
import { UserStateModel } from './user.model';
import { Router } from '@angular/router';
import { UserService } from '@core/core-services/user.service';
import { Injectable } from '@angular/core';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: {
      id: '',
      username: '',
      token: '',
      givenName: '',
      surName: '',
      picture: ''
    },
    role: {},
    clientTree: {},
    loading: false,
  },
})
@Injectable()
export class UserState {
  constructor(private userAPI: UserService, private router: Router) {}

  @Selector()
  static getCurrentUser(state: UserStateModel) {
    return state && state.user;
  }

  @Selector()
  static getUserClientTree(state: UserStateModel) {
    return state && state.clientTree;
  }

  @Selector()
  static Role(state: UserStateModel) {
    return state ? state.role : {};
  }

  @Selector()
  static SelectedClient(state: UserStateModel){
    return state && state.clientTree && state.clientTree.clients && state.clientTree.clients.filter(
        (c) => c._id === state.clientTree.landingClient
    )[0];
  }

  @Selector()
  static getUserPicture(state: UserStateModel) {
    return state && state.user && state.user.picture;
  }

  @Action(UserActions.AddUserData)
  addUserData(
    { patchState }: StateContext<UserStateModel>,
    { payload }: UserActions.AddUserData
  ) {
    patchState({
      user: {
        id: payload.id,
        username: payload.username,
        token: payload.token,
        givenName: payload.givenName,
        surName: payload.surName,
        picture: payload['picture']
        ? 'data:image/jpg;base64,' + payload['picture']
        : 'assets/icons/icon-person.svg',
      },
    });
  }

  @Action(UserActions.AddUserClientTree)
  addUserClientTree(
    { patchState }: StateContext<UserStateModel>,
    { payload }: UserActions.AddUserClientTree
  ) {
    patchState({
      clientTree: payload,
    });
  }

  @Action(UserActions.SetLandingClient)
  setLandingClient(
    ctx: StateContext<UserStateModel>,
    { payload }: UserActions.SetLandingClient
  ) {
    const state = ctx.getState();
    ctx.patchState({
      clientTree: {
        ...state.clientTree,
        landingClient: payload,
      },
    });
  }

  @Action(UserActions.SetLandingMarket)
  setLandingMarket(
    ctx: StateContext<UserStateModel>,
    { payload }: UserActions.SetLandingMarket
  ) {
    const state = ctx.getState();
    ctx.patchState({
      clientTree: {
        ...state.clientTree,
        landingMarket: payload,
      },
    });
  }

  @Action(UserActions.PurgeUserData)
  purgeUserData({ patchState }: StateContext<UserStateModel>) {
    patchState({
      user: {
        id: '',
        username: '',
        token: '',
        givenName:'',
        surName:'',
        picture:''
      },
    });
  }

  @Action(UserActions.GetMyAccessLevels)
  getAccessLevels(
    ctx: StateContext<UserStateModel>,
    action: UserActions.GetMyAccessLevels
  ) {
    ctx.dispatch(new UserActions.ToggleLoadingRules(true));
    return this.userAPI.getMyAccess().pipe(
      tap(
        (data: any) => {
          if (data) {
            ctx.setState({
              ...ctx.getState(),
              role: data,
            });
          }
          ctx.dispatch(new UserActions.ToggleLoadingRules(false));
        },
        (err) => {
          this.router.navigate(['login']);
          // this.purgeUserData({});
        }
      )
    );
  }
}
