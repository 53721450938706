import { MosaicInterface } from './mosaic.interface';

export namespace MosaicActions {
  export class AddMosaic {
    static readonly type = '[Mosaic] Add Mosaic Value';

    constructor(public payload: MosaicInterface) {}
  }

  export class ClearMosaic {
    static readonly type = '[Mosaic] Clear Mosaic Value';

    constructor() {}
  }
}
