import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-no-content',
    templateUrl: './no-content.component.html',
    styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    goHome(): void {
        this.router.navigate(['/']);
    }
}
