import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterIterableElementAt'
})
export class FilterIterableElementAtPipe implements PipeTransform {

    transform(value, keys: string, location: number) {
        // if (!term) return value;
        return (value || []).filter(f => f[location] !== keys);
    }

}
