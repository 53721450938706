import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserManagmentService {
  res = null;
  error = null;
  token = null;

  constructor(private http: HttpClient) {}

  // TODO: Client-methods (move to seperatete)
  getClientList(queryString?: string, ilimit?: string): Observable<any> {
    let limit = ilimit || '50';
    let qs = queryString || '';
    return this.http.get<any>(
      environment.api +
        '/api/client/allclients/?query=' +
        qs +
        '&limit=' +
        limit
    );
  }

  // TODO: Client-methods (move to seperatete)
  getPartnerList(queryString?: string, ilimit?: string): Observable<any> {
    let limit = ilimit || '50';
    let qs = queryString || '';
    return this.http.get(
      environment.api + '/api/partner/?query=' + qs + '&limit=' + limit
    );
  }

  getClient(clientId: string): Observable<any> {
    return this.http.get(environment.api + '/api/client/' + clientId);
  }

  getMarket(marketId: string): Observable<any> {
    return this.http.get(environment.api + '/api/client/markets/' + marketId);
  }

  saveClient(clientId: string, clientObject: any): Observable<any> {
    return this.http.put(environment.api + '/api/client/' + clientId, {
      clientObj: clientObject,
    });
  }

  addClient(clientObject): Observable<any> {
    return this.http.post(environment.api + '/api/client/add', {
      client: clientObject,
    });
  }

  // Roles
  getRoles(): Observable<any> {
    return this.http.get(environment.api + '/api/admin/roles');
  }

  saveRoles(roleId: string, roleObject: any): Observable<any> {
    return this.http.put(environment.api + '/api/admin/roles/' + roleId, {
      role: roleObject,
    });
  }

  getUserFeatureList(): Observable<any> {
    return this.http.get(environment.api + '/api/userfeatures');
  }

  // Markets

  getMarketList(
    clientId: string,
    queryString?: string,
    ilimit?: string
  ): Observable<any> {
    let limit = ilimit || '50';
    let qs = queryString || '';
    return this.http.get(
      environment.api +
        '/api/client/' +
        clientId +
        '/markets?query=' +
        qs +
        '&limit=' +
        limit
    );
  }

  getAllMarkets(): Observable<any> {
    return this.http.get(environment.api + '/api/client/markets/all');
  }

  addMarket(clientId: string, marketObject: any): Observable<any> {
    return this.http.post(
      environment.api + '/api/client/' + clientId + '/market/add',
      { market: marketObject }
    );
  }

  // User List
  getUserList(pageSize?: number, skipRecords?: number): Observable<any> {
    return this.http.get(
      environment.api + '/api/users/?limit=' + pageSize + '&skip=' + skipRecords
    );
  }

  // Get User
  getUser(userId): Observable<any> {
    return this.http.get(environment.api + '/api/users/' + userId);
  }

  // Set default client for the user
  setDefaultClient(userId: string, clientId: string): Observable<any> {
    return this.http.put(
      environment.api + '/api/users/' + userId + '/default/client',
      { defaultClient: clientId }
    );
  }

  saveUser(userId: string, userObj): Observable<any> {
    return this.http.put(environment.api + '/api/users/' + userId, {
      user: userObj,
    });
  }

  getFeatureList(): Observable<any> {
    return this.http.get(environment.api + '/api/features');
  }

  canUserAccessFeature(routeName: string): Observable<any> {
    console.log('working out access');
    console.log(routeName);
    return this.http.get(environment.api + '/api/users/access/' + routeName);
  }
}
