import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'summary'
})

export class SummaryPipe implements PipeTransform {
    pipeLength;
    transform(value: string, limit?: number) {
        if (!value) {
            return null;
        }

        const pipeLength = (limit) ? (limit) : 100;
        if (value.length > limit) {
            return value.substr(0, pipeLength ) + '...';
        } else {
            return value;
        }
    }
}