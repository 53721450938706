import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hNumber'
})
export class HNumberPipe implements PipeTransform {

  transform(value: any): number {
    return this.localeString(value);
  }

  localeString(value) {

    return value.toFixed(2);
  }
}