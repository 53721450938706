import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

// import {DecimalPipe} from '@angular/common';

@Pipe({
  name: 'kpi'
})
export class KpiPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {
  }

  transform(value: any, kpiType: string, keepNumeric = false): any {
    const numberArr = ['impressions', 'clicks', 'media cost'];
    const costKpiRatios  = ['cpcv'];
    const percentArr = ['ctr', 'percent', 'vcr', 'vtr'];
    // const currencyArr = ['media cost'];

    const kpi = kpiType.toLocaleLowerCase();
    let result;
    if (numberArr.includes(kpi)) {
      const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T'];
      const i = 0 === value ? value : Math.floor(Math.log(value) / Math.log(1000));
      result = parseFloat((value / Math.pow(1000, i)).toFixed(2));
      return this.decimalPipe.transform(result, '0.0-2') + COUNT_ABBRS[i];
    }
    if (percentArr.includes(kpi)) {
      if (!keepNumeric) {
        return this.decimalPipe.transform(value * 100, '0.0-2') + '%';
      }
      return (value * 100).toFixed(2);
    }
    if (costKpiRatios.includes(kpi)) {
      return this.decimalPipe.transform(value, '0.0-3');
    }
    return this.decimalPipe.transform(value, '0.0-2');
  }

}
