<div class="flex flex-row-reverse">
  <div class="cursor-pointer eva eva-close-outline text-h3" [mat-dialog-close]='true'></div>
  <div class="flex flex-col items-center px-11 py-10">
    <p class="font-bold leading-6 text-center text-h">{{data.title}}</p>
    <p class="font-normal leading-6 text-center text-body-text">{{data.message}}</p>
    <div class="pt-4 cursor-pointer flex items-center" [mat-dialog-close]='true'>
      <i class="eva eva-arrow-back-outline mx-1 text-primary"></i>
      <p class="my-0 font-normal leading-6 text-center text-primary text-body-text">Back to {{data.backToMsgText}}</p>
    </div>
  </div>
</div>
