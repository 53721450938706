import { PanelInterface } from './panel.interface';

export namespace PanelActions {
  export class AddPanelDisplayType {
    static readonly type = '[Panel] Add Display Type';

    constructor(public payload: PanelInterface) {}
  }

  // export class ClearPanelDisplayType {
  //   static readonly type = '[Panel] Clear Display Type';

  //   constructor() {
  //   }
  // }

  export class AddRankType {
    static readonly type = '[Panel] Add Rank Type';

    constructor(public payload: PanelInterface) {}
  }

  // export class ClearRankType {
  //   static readonly type = '[Panel] Clear Rank Type';

  //   constructor() {
  //   }
  // }

  export class AddBrandSettings {
    static readonly type = '[Panel] Add Brand Settings';

    constructor(public payload: PanelInterface) {}
  }

  export class ClearBrandSettings {
    static readonly type = '[Panel] Clear Brand Settings';

    constructor() {}
  }
}
