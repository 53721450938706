import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'app-data-label',
  templateUrl: './data-label.component.html',
  styleUrls: ['./data-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataLabelComponent implements OnInit, OnChanges {
  @Input() color: 'red' | 'amber' | 'green';
  @Input() title: string;
  @Input() description: string;
  tippyProps: NgxTippyProps;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title || changes.description) {
      this.tippyProps = {
        arrow: false,
        placement: 'bottom-start',
        hideOnClick: false,
        allowHTML: true,
        content: `<div class="data-label__tooltip data-label__tooltip--${this.color}">
                    <div class="data-label__tooltip__title">
                      ${this.title}
                    </div>
                    <div class="data-label__tooltip__description">
                      ${this.description}
                    </div>
                  </div>`,
      };
    }
  }
}
