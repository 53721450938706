import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() fullScreen = false;
  selectedMsg;
  msgArr = [
    'Reticulating splines...',
    'Generating witty dialog...',
    'Swapping time and space...',
    'Spinning violently around the y-axis...',
    'Tokenizing real life...',
    'Bending the spoon...',
    'Filtering morale...',
    "Don't think of purple hippos...",
    '640K ought to be enough for anybody',
    'The architects are still drafting',
    'The bits are breeding',
    "We're building the buildings as fast as we can",
    'Would you prefer chicken, steak, or tofu?',
    '...and enjoy the elevator music...',
    'Please wait while the little elves draw your map',
    'Would you like fries with that?',
    'Checking the gravitational constant in your locale...',
    'Go ahead -- hold your breath!',
    "...at least you're not on hold...",
    'Hum something loud while others stare',
    "We're testing your patience",
    'As if you had any other choice',
    'Follow the white rabbit',
    "Why don't you order a sandwich?",
    'While the satellite moves into position',
    'The bits are flowing slowly today',
    "It's still faster than you could draw it",
    "The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
    'My other loading screen is much faster.',
    "Testing on Timmy... We're going to need another Timmy.",
    'Just count to 10',
    'Why so serious?',
    "We're making you a cookie.",
    'Creating time-loop inversion field',
    'Loading the enchanted bunny...',
    'Looking for exact change',
    'I feel like im supposed to be loading something. . .',
    'What do you call 8 Hobbits? A Hobbyte.',
    'Is this Windows?',
    'Adjusting flux capacitor...',
    'Please wait until the sloth starts moving.',
    "I swear it's almost done.",
    "Let's take a mindfulness minute...",
    'Unicorns are at the end of this road, I promise.',
    "Keeping all the 1's and removing all the 0's...",
    'Putting the icing on the cake. The cake is not a lie...',
    'Cleaning off the cobwebs...',
    "Making sure all the i's have dots...",
    'We need more dilithium crystals',
    'Where did all the internets go',
    'Connecting Neurotoxin Storage Tank...',
    'Granting wishes...',
    'Time flies when you’re having fun.',
    'Spinning the hamster…',
    '99 bottles of beer on the wall..',
    'Stay awhile and listen..',
    'Load it and they will come',
    'Convincing AI not to turn evil..',
    'There is no spoon. Because we are not done loading it',
    'Your left thumb points to the right and your right thumb points to the left.',
    'How did you get here?',
    'Wait, do you smell something burning?',
    'Computing the secret to life, the universe, and everything.',
    'When nothing is going right, go left!!...',
    "I love my job only when I'm on vacation...",
    "i'm not lazy, I'm just relaxed!!",
    'Why are they called apartments if they are all stuck together?',
    'Life is Short – Talk Fast!!!!',
    'Whenever I find the key to success, someone changes the lock.',
    'I’ve got problem for your solution…..',
    'User: the word computer professionals use when they mean !!idiot!!',
    'Adults are just kids with money.',
    'I think I am, therefore, I am. I think.',
    'You don’t pay taxes—they take taxes.',
    'Coffee, Chocolate, Men. The richer the better!',
    'I am free of all prejudices. I hate everyone equally.',
    'Constructing additional pylons...',
    'Roping some seaturtles...',
    'If you type Google into Google you can break the internet',
    'What is the airspeed velocity of an unladen swallow?',
    'The Elders of the Internet would never stand for it.',
    'Space is invisible mind dust, and stars are but wishes.',
    "Didn't know paint dried so quickly.",
    'Everything sounds the same',
    'Dividing by zero...',
    'If I’m not back in five minutes, just wait longer.',
    'Cracking military-grade encryption...',
    'Simulating traveling salesman...',
    'Proving P=NP...',
    'Entangling superstrings...',
    'Twiddling thumbs...',
    'Searching for plot device...',
    'Trying to sort in O(n)...',
    'Please wait while the intern refills his coffee.',
    'Please hold on as we reheat our coffee',
    'Winter is coming...',
    'Finding someone to hold my beer',
    '@todo Insert witty loading message',
    "Let's hope it's worth the wait",
    'Aw, snap! Not..',
    'Ordering 1s and 0s...',
    'Updating dependencies...',
    "Whatever you do, don't look behind you...",
    'Please wait... Consulting the manual...',
    "It is dark. You're likely to be eaten by a grue.",
    'Loading funny message...',
    "It's 10:00pm. Do you know where your children are?",
    'Waiting Daenerys say all her titles...',
    'Feel free to spin in your chair',
    'What the what?',
    'format C: ...',
    'Forget you saw that password I just typed into the IM ...',
    "What's under there?",
    'Your computer has a virus, its name is Windows!',
    'Go ahead, hold your breath and do an ironman plank till loading complete',
    'Please wait while the minions do their work',
    'Grabbing extra minions',
    'Doing the heavy lifting',
    "We're working very Hard .... Really",
    'Waking up the minions',
    'You are number 2843684714 in the queue',
    'Please wait while we serve other customers...',
    'Our premium plan is faster',
  ];

  constructor() {}

  ngOnInit() {
    this.selectedMsg = this.msgArr[(this.msgArr.length * Math.random()) | 0];
  }
}
