<div class='root-container'>
  <ng-container *ngIf='isloggedIn && user && !isIframed && (role$ |async) as role'>
    <div class='nav'>
      <a class='nav__logo' routerLink='/vaa' *ngIf='isloggedIn && user'>
        <!-- Temporary change -->
        <!--        <img class="nav__logo__img" *ngIf="client?.brandingLogo" [src]="client?.brandingLogo" />-->
        <img class='nav__logo__img' [src]='appTitleService.data.imgUrl' />
        <!--        <img class="nav__logo__img" src="/assets/img/optimum.svg" />-->

        <div class='nav__logo__title'>
          {{ appTitleService.data?.title }}
        </div>
      </a>

      <div class='nav__toolBar'>
        <div class='nav__toolBar__single'
             *ngIf='isloggedIn && user && clientTree?.clients && clientTree?.clients?.length'>

          <span class='eva eva-search-outline text-h5 align-middle pr-2'></span>
          <mat-form-field class='pb-0 no-label'>
            <mat-select class='nav__select' [formControl]='selectedClient' #singleSelect>
              <mat-option>
                <ngx-mat-select-search [formControl]='clientName' placeholderLabel='Find client...'
                                       noEntriesFoundLabel='No matching client found'>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor='let userClient of filteredClients | async' [value]='userClient'>
                {{userClient.clientName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class='nav__toolBar__single'
             *ngIf='isloggedIn && user && clientTree?.clients  && clientTree?.clients.length && landingClient && client?.markets '>
          <mat-form-field class='pb-0 no-label'>
            <mat-select class='nav__select' [(value)]='landingMarket' (selectionChange)='updateDefaultMarket()'>
              <mat-option *ngFor='let userMarket of client?.markets' [value]='userMarket._id'>
                {{ userMarket.marketName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class='nav__toolBar__single' *ngIf='isloggedIn && user'>
          <!-- <div [matMenuTriggerFor]='appMenu' class='nav__toolBar__userMenu'>
            <div class='nav__toolBar__userMenuText'>
              <i class='nav__toolBar__userIcon eva eva-person-outline'></i>
              <img [src]="userPicture" class="rounded-full" />
              <span class='usersm'> {{(user.givenName || user.name || user.username) | truncate: 17}}</span>
            </div>

            <div class='mat-select-arrow-wrapper'>
              <div class='ml-4 mat-select-arrow'></div>
            </div>
          </div> -->
          <div [matMenuTriggerFor]="appMenu" class="nav__toolBar__userMenu mb-4">
            <img [src]="userPicture" class="rounded-full" />
          </div>
          <mat-menu #appMenu='matMenu'>
            <button mat-menu-item routerLink='/admin' *ngIf='role.admin_admin'>Admin</button>
            <button mat-menu-item (click)='logOut();'>Sign Out</button>
          </mat-menu>
        </div>

        <!--        <div class="nav__toolBar__single">-->
        <!--          <div class="eva eva-grid-outline"></div>-->
        <!--        </div>-->
      </div>
    </div>
  </ng-container>

  <button *ngIf='isIframed' class='dropdown homeLinkBtn btn' routerLink='/'>
    <img class='adminBurger' src='assets/img/HomeBlack.svg' />
  </button>
  <app-breadcrumbs *ngIf="isloggedIn && user" ></app-breadcrumbs>
  <main class='app-main' [@fadeAnimation]='getRouterOutletState(o)'>
    <router-outlet #o='outlet'></router-outlet>
  </main>

  <footer *ngIf="isloggedIn && user" class="footer-logged-in">
    <div>
      <img src="assets/logos/publicis-groupe.svg" alt="Publicis Groupe" />
    </div>
    <div class="footer-logged-in__copyright">
      ©2024 Publicis Groupe
    </div>

    <div class="footer-logged-in__links">
      <a class="footer-logged-in__link" (click)="openTermsDialog()">Terms and Conditions</a>
      <a class="footer-logged-in__link" (click)="openPrivacypolicy()">Privacy Policy</a>
      <a class="footer-logged-in__link" href="mailto:support@publicismmedia.ai">Contact Us</a>
    </div>
  </footer>

  <footer *ngIf="!isloggedIn" class="footer">
    <div>
      <img src="assets/logos/publicis-groupe.svg" alt="Publicis Groupe" />
    </div>
    <div class="footer__copyright">©2024 Publicis Groupe</div>

    <div class="footer__links">
      <a class="footer__link" (click)="openTermsDialog()">Terms and Conditions</a>
      <a class="footer__link" (click)="openPrivacypolicy()">Privacy Policy</a>
      <a class="footer__link" (click)="openContact()">Contact Us</a>
    </div>

    <!-- <div class="footer__help">
      <a>
        <span class="eva eva-question-mark-circle-outline"></span>
        Help & Support
      </a>
    </div> -->

    <!-- <div class="footer__chat">
      <a>
        <span class="footer__circle"></span>
        <span
          class="footer__circle__chat eva eva-message-square-outline"></span>
      </a>
    </div> -->
  </footer>
  <app-custom-snack-bar *ngIf='progressState?.isOpen'></app-custom-snack-bar>


  <app-spinner [fullScreen]='true' *ngIf='loadingService.loading$ | async'></app-spinner>
</div>
