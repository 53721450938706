import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-link',
  templateUrl: './text-link.component.html',
  styleUrls: ['./text-link.component.scss'],
})
export class TextLinkComponent implements OnInit {
  @Input() href: string;

  @Input() evaIconBefore: string;
  @Input() evaIconAfter: string;
  @Input() small = false;

  constructor() {}

  ngOnInit(): void {}
}
