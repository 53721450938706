import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {
  times = {
    year: 31557600,
    month: 2629746,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  }

  transform(seconds: any, ...args: any[]): any {
    let d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    let timeString = '';
    timeString = `${h > 0 ? h : ''}${h > 0 ? ':' : ''}${m > 0 ? m : '0'}:${s < 10? '0'+s : s}`;
    return timeString;
  }

}
