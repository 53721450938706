import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: 'safeSvg'
})
export class SafeSvgPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }
    transform(iconData) {
        let blob = new Blob([iconData], {type: 'image/svg+xml'});
        return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    }
}
