import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from '../modules/auth/services/authentication.service';
import { UserService } from './core-services/user.service';
import { VideosDataService } from './services/videos-data.service';
import { UserManagmentService } from './core-services/user-managment.service';
import { GlobalsService } from './core-services/globals.service';
import { OptimumAdminService } from './services/optimum-admin.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { firebase } from '../../environments/firebase';
import { StoreModule } from '@core/@store/store.module';
import { HttpConfigInterceptor } from '@core/interceptors/httpconfig.interceptor';
import { AppTitleService } from '@core/core-services/title/title.service';
import { SpinnerService } from '@core/spinner/spinner.service';

@NgModule({
  imports: [
    StoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebase),
  ],
  providers: [
    SpinnerService,
    AuthenticationService,
    UserService,
    GlobalsService,
    UserManagmentService,
    AppTitleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },

    VideosDataService,
    OptimumAdminService,
  ],
  exports: [
    StoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule,
  ],
})
export class CoreModule {}
