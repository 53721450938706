import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SortByStateModel } from './sortby.model';
import { SortByActions } from './sortby.actions';
import AddSortByValue = SortByActions.AddSortByValue;
import { Injectable } from '@angular/core';

@State<SortByStateModel>({
  name: 'sortBy',
  defaults: {
    sortBy: {
      value: null,
      direction: null,

      first: null,
      second: null,
    },
  },
})
@Injectable()
export class SortByState {
  constructor() {}

  @Selector()
  static getCurrentSortByValue(state: SortByStateModel) {
    return state && state.sortBy;
  }

  @Action(AddSortByValue)
  addSortByValue(
    ctx: StateContext<SortByStateModel>,
    { payload }: SortByActions.AddSortByValue
  ) {
    const state = ctx.getState();

    // initialize the state
    if (state.sortBy.first === null && state.sortBy.second === null) {
      ctx.setState({
        sortBy: {
          value: payload.value,
          direction: payload.direction,

          first: {
            value: payload.value,
            direction: payload.direction,
          },

          second: state.sortBy.second,
        },
      });
    } else if (state.sortBy.second !== null && state.sortBy.first === null) {
      ctx.setState({
        sortBy: {
          value: payload.value,
          direction: payload.direction,

          first: state.sortBy.first,
          second: {
            value: payload.value,
            direction: payload.direction,
          },
        },
      });
    } else if (payload.value === 'none') {
      let currentDirection = payload.direction;

      if (currentDirection === state.sortBy.first.direction) {
        ctx.setState({
          sortBy: {
            value: null,
            direction: null,

            first: null,
            second: state.sortBy.second,
          },
        });
      } else {
        ctx.setState({
          sortBy: {
            value: null,
            direction: null,

            first: state.sortBy.first,
            second: null,
          },
        });
      }
    } else if (state.sortBy.first.direction === payload.direction) {
      ctx.patchState({
        sortBy: {
          value: payload.value,
          direction: payload.direction,

          first: {
            value: payload.value,
            direction: payload.direction,
          },

          second: state.sortBy.second,
        },
      });
    } else if (state.sortBy.first.direction !== payload.direction) {
      ctx.patchState({
        sortBy: {
          value: payload.value,
          direction: payload.direction,

          first: state.sortBy.first,

          second: {
            value: payload.value,
            direction: payload.direction,
          },
        },
      });
    }
  }

  @Action(SortByActions.ClearSortByValue)
  clearSortByValue({ patchState }: StateContext<SortByStateModel>) {
    patchState({
      sortBy: {
        value: null,
        direction: null,

        first: null,
        second: null,
      },
    });
  }
}
