import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, Subscription, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { User } from './models/user';
import { Router } from '@angular/router';
import { SpinnerService } from '@core/spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private readonly spinnerService: SpinnerService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = this.getUser() ? this.getUser().token : '';
    if (token) {
      // request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
      request = request.clone({
        headers: request.headers.set('x-access-token', token),
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>', event);
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.router.navigate(['auth', 'logout']);
        } else if (error.status === 501) {
          console.log('Error from user services:' + JSON.stringify(error));
        } else {
          console.log('Error from user services:' + JSON.stringify(error));
        }
        // let data = {};
        // data = {
        //     reason: error && error.error.reason ? error.error.reason : '',
        //     status: error.status
        // };
        // this.errorDialogService.openDialog(data);
        return throwError(error);
      })
    );
  }

  // getUser
  getUser(): User {
    const x = localStorage.getItem('User');
    return x ? (JSON.parse(x) as User) : null;
  }
}
