export namespace CompetitiveStudyActions {

  export class SetCurrentStudy {
    static readonly type = '[Competitive Study] Set Resource Links';
    constructor(public payload: any) {
    }
  }

  export class GetCurrentStudy {
    static readonly type = '[Competitive Study] Get Resource Links';

    constructor() {
    }
  }
}
