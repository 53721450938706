import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  apiUrl = environment.api + '/api/admin/';

  constructor(private http: HttpClient) {}

  getRoles(): Observable<any> {
    return this.http.get(this.apiUrl + 'role');
  }

  getRoleByID(id): Observable<any> {
    return this.http.get(this.apiUrl + 'role/one/' + id);
  }

  addRole(data): Observable<any> {
    return this.http.post(this.apiUrl + 'role', data);
  }

  editRole(id, data) {
    return this.http.post(this.apiUrl + 'role/one/' + id, data);
  }

  deleteRole(id): Observable<any> {
    return this.http.delete(this.apiUrl + 'role/one/' + id);
  }

  // Rules
  getRulesCategory(): Observable<any> {
    return this.http.get(`${this.apiUrl}rule/rule-types`);
  }

  getRules(): Observable<any> {
    return this.http.get(`${this.apiUrl}rule`);
  }

  addRule(data): Observable<any> {
    return this.http.post(`${this.apiUrl}rule`, data);
  }

  toggleRuleState(id, state): Observable<any> {
    return this.http.post(`${this.apiUrl}rule/one/${id}`, { state });
  }

  deleteRule(id): Observable<any> {
    return this.http.delete(`${this.apiUrl}rule/one/${id}`);
  }
}
