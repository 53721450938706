import { Injectable } from '@angular/core';
import { ConfirmComponent } from '@shared/components/dialogs/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirmDialog(title: string, message: string, backToMsgText: string) {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: title,
        message: message,
        backToMsgText: backToMsgText,
      },
      //  panelClass: ['border', 'border-accent-dark', 'border-solid']
    });
  }
}
