import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { UserState } from './user/user.store';
import { AppsState } from './app/app.store';
import { environment } from '../../../environments/environment';
import { RuleState } from './rules/rule.store';
import { RoleState } from './role/role.store';
import { SortByState } from './competetive-analysis/sortby/sortby.store';
import { MosaicState } from './competetive-analysis/mosaic/mosaic.store';
import { PanelState } from './competetive-analysis/panel/panel.store';
import { ProgressState } from '@core/@store/progress-state/progress-state.store';
import { CompetitiveStudyState } from '@core/@store/competetive-analysis/competitive-study.store';

@NgModule({
  declarations: [],
  imports: [
    NgxsModule.forRoot(
      [
        UserState,
        AppsState,
        RuleState,
        RoleState,
        SortByState,
        MosaicState,
        PanelState,
        ProgressState,
        CompetitiveStudyState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: { suppressErrors: false }
      }
    ),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot()
  ],
  exports: [NgxsModule, NgxsReduxDevtoolsPluginModule, NgxsResetPluginModule]
})
export class StoreModule {
}
