import { Injectable } from '@angular/core';

const MINUTES_UNITL_AUTO_LOGOUT = 10; // in mins
const CHECK_INTERVAL = 15000; // in ms
const STORE_KEY = 'lastAction';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  currentUrl: string;

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }

  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor() {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft =
      this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      // this.ngZone.run(() => {
      //     this.router.events
      //         .subscribe((event) => {
      //             if (event instanceof NavigationEnd) {
      //                 this.currentUrl = event.url;
      //                 if (this.currentUrl.includes('/auth/')) {
      //
      //                 } else {
      //                     localStorage.clear();
      //                     this.router.navigate(['./auth/logout']);
      //                     alert("You have been logged out due to inactivity");
      //                 }
      //                 //   switch (this.currentUrl) {
      //                 //     case '/auth/logout':
      //                 //       break;
      //                 //     case '/auth/login':
      //                 //       break;
      //                 //     default:
      //                 //       localStorage.clear();
      //                 //       this.router.navigate(['./auth/logout']);
      //                 //       alert("You have been logged out due to inactivity");
      //                 //       break;
      //                 //   }
      //             }
      //         })
      // });
    }
  }
}
