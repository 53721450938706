import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationHelperService {
  private _routerWithUnsavedChangesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  routerWithUnsavedChanges$ = this._routerWithUnsavedChangesSubject.asObservable();

  private _routerBrowserBackClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  routerBrowserBackClicked$ = this._routerBrowserBackClickedSubject.asObservable();

  constructor() {}

  setrouterWithUnsavedChanges(value: boolean) {
    this._routerWithUnsavedChangesSubject.next(value);
  }

  getrouterWithUnsavedChanges(): boolean {
    return this._routerWithUnsavedChangesSubject.value;
  }

  setBrowserBackButtonClicked(value: boolean) {
    this._routerBrowserBackClickedSubject.next(value);
  }

  getBrowserBackButtonClicked(): boolean {
    return this._routerBrowserBackClickedSubject.value;
  }
}
