<span class="eva eva-close-outline" (click)="dialogRef.close()"></span>

<div class="help-container">
  <!-- <i class="eva eva-close" (click)="close()"></i> -->
  <div class="text-center">
    <h2>
      {{data.title}}
    </h2>
  </div>
  <form class="form" [formGroup]="contactForm" autocomplete="disabled">
    <div class="mb-8" *ngIf="data.subTitle"> {{data.subTitle}}</div>
    <mat-form-field>
      <input matInput class="form-control" placeholder="Name" formControlName="name" autocomplete="off" maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-control" placeholder="Email" formControlName="email" autocomplete="off"
        maxlength="100">
      <mat-error *ngIf="contactForm.get('email').hasError('email') && !contactForm.get('email').hasError('required')">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-control" placeholder="Subject" formControlName="subject" autocomplete="off"
        maxlength="50">
    </mat-form-field>
    <mat-form-field>
      <textarea matInput class="form-control" placeholder="Details" formControlName="body" autocomplete="off"
        maxlength="500"></textarea>
    </mat-form-field>


    <button  color="primary" mat-stroked-button  color="primary" class="w-full h-12 mt-4" (click)="submit()" type="submit"
      [disabled]="!contactForm.valid">Send
    </button>

  </form>

</div>
