import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseConvertPipe } from '@shared/pipes/pipes/case-convert.pipe';
import { FeatureStringPipe } from '@shared/pipes/pipes/feature-string.pipe';
import { FilterIterablePipe } from '@shared/pipes/pipes/filter-iterable.pipe';
import { FilterIterableElementAtPipe } from '@shared/pipes/pipes/filter-iterable-element-at.pipe';
import { FilterMutablesPipe } from '@shared/pipes/pipes/filter-mutables.pipe';
import { HNumberPipe } from '@shared/pipes/pipes/h-number.pipe';
import { MapToIterablePipe } from '@shared/pipes/pipes/map-to-iterable.pipe';
import { RemoveCommasPipe } from '@shared/pipes/pipes/remove-commas.pipe';
import { SafePipe } from '@shared/pipes/pipes/safe.pipe';
import { SortPipe } from '@shared/pipes/pipes/sort.pipe';
import { SplitPipe } from '@shared/pipes/pipes/split.pipe';
import { CamelCasePipe } from '@shared/pipes/pipes/camel-case.pipe';
import { GroupByPipe } from '@shared/pipes/pipes/group-by.pipe';
import { GroupByParentPipe } from '@shared/pipes/pipes/group-by-parent.pipe';
import { SummaryPipe } from '@shared/pipes/pipes/summary.pipe';
import { PercentPipe } from '@shared/pipes/pipes/percent.pipe';
import { SafeSvgPipe } from '@shared/pipes/pipes/safe-svg.pipe';
import { DynamicPipePipe } from './pipes/dynamic-pipe.pipe';
import { DecimalPipe } from '@angular/common';
import { TruncatePipe } from '@shared/pipes/pipes/truncate.pipe';
import { SecondsToTimePipe } from '@shared/pipes/pipes/seconds-to-time.pipe';
import { KpiPipe } from '@shared/pipes/pipes/kpi.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CamelCasePipe,
    CaseConvertPipe,
    FeatureStringPipe,
    FilterIterablePipe,
    FilterIterableElementAtPipe,
    FilterMutablesPipe,
    GroupByPipe,
    GroupByParentPipe,
    HNumberPipe,
    MapToIterablePipe,
    PercentPipe,
    RemoveCommasPipe,
    SafePipe,
    SafeSvgPipe,
    SortPipe,
    SplitPipe,
    SummaryPipe,
    DynamicPipePipe,
    TruncatePipe,
    SecondsToTimePipe,
    KpiPipe
  ],
  exports: [
    CamelCasePipe,
    CaseConvertPipe,
    DynamicPipePipe,
    FeatureStringPipe,
    FilterIterablePipe,
    FilterIterableElementAtPipe,
    FilterMutablesPipe,
    GroupByPipe,
    GroupByParentPipe,
    HNumberPipe,
    MapToIterablePipe,
    PercentPipe,
    RemoveCommasPipe,
    SafePipe,
    SafeSvgPipe,
    SortPipe,
    SplitPipe,
    SummaryPipe,
    TruncatePipe,
    SecondsToTimePipe,
    KpiPipe
  ],
  providers: [DecimalPipe]
})
export class PipesModule { }
