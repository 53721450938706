import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

    transform(input: string, separator: string): string[] {
        return input.split(separator);
    }

}
