<div class="cell-wrapper-inner" [id]="'cell-'+cellInput.column+'-'+cellInput.row"
  [ngClass]="{'locked':cellInput.locked}" [ngStyle]="{
  'background-color':cellInput.backgroundColor,
  'border-top-color':cellInput.borderTopColor,
  'border-right-color':cellInput.borderRightColor,
  'border-bottom-color':cellInput.borderBottomColor,
  'border-left-color':cellInput.borderLeftColor,
  'border-top-style':cellInput.borderTopStyle,
  'border-right-style':cellInput.borderRightStyle,
  'border-bottom-style':cellInput.borderBottomStyle,
  'border-left-style':cellInput.borderLeftStyle,
  'border-top-width':cellInput.borderTopWidth+'px',
  'border-right-width':cellInput.borderRightWidth+'px',
  'border-bottom-width':cellInput.borderBottomWidth+'px',
  'border-left-width':cellInput.borderLeftWidth+'px',
  'color':cellInput.color,
  'font-weight':cellInput.fontWeight,
  'text-align':cellInput.textAlign
}">

  {{cellInput.value | dynamicPipe: cellInput.format }}
  <div class="mat-icon-wrapper">
    <mat-icon *ngFor="let button of cellInput.buttons" (click)="cellFunction(button.function)">
      {{button.materialIcon}}
    </mat-icon>
  </div>

</div>