import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  dispatch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loading$ = this.dispatch.asObservable();

  show() {
    this.dispatch.next(true);
  }

  hide() {
    this.dispatch.next(false);
  }
}
