import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss'],
})
export class SortByComponent implements OnInit {
  public options;
  @Output() sort = new EventEmitter<string>();
  @Input() fields;

  selected = '';

  constructor() {}

  ngOnInit() {
    this.selected = this.fields[0];
    this.options = [
      {
        value: 'Last updated',
        id: '-updatedAt',
      },
      {
        value: 'Name (Ascending)',
        id: '-clientName',
      },
      {
        value: 'Name (Descending)',
        id: 'clientName',
      },
      {
        value: 'Name (Ascending)',
        id: '-name',
      },
      {
        value: 'Name (Descending)',
        id: 'name',
      },
      {
        value: 'Start Date',
        id: 'startDate',
      },
      {
        value: 'Creation Date',
        id: 'createdAt',
      },
      {
        value: 'State',
        id: 'state',
      },
      {
        value: 'Campaign Name',
        id: 'title',
      },
      {
        value: 'State',
        id: 'status',
      },
      {
        value: 'Display Name',
        id: 'displayName',
      },
      {
        value: 'Last Uploaded',
        id: 'uploadedAt',
      },
      {
        value: 'Role',
        id: 'role._id',
      },
      {
        value: 'Last Login',
        id: 'lastLogin',
      },
    ].filter((x) => this.fields.indexOf(x.id) > -1);
  }

  onChange() {
    this.sort.emit(this.selected);
  }
}
