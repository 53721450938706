import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByParent'
})
export class GroupByParentPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any {
    let parents = value.filter(e => !e.parent);
    const children = value.filter(e => e.parent);

    parents = parents.map(e => {
      const kids = [];
      children.forEach(f => {
        if (f.parent === e._id) {
          kids.push(f);
        }
      });

      return {
        ...e,
        children: kids
      };
    });

    return parents;
  }

}
