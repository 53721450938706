<span class="cursor-pointer eva eva-close-outline float-right" (click)="snackBarRef.dismiss()"></span>
<div class="text-center " *ngIf='data.customTitle!=""'>

  <h1> {{ data.customTitle}}</h1>
</div>
<div class="text-center " *ngIf='data.customTitle===""'>
  <h1>{{data.type | titlecase}}</h1>
</div>

<div [innerHTML]="data.message" class="p-3 my-3">
<!--  {{data.message}}-->
</div>
<div class="text-center ">
  <button mat-flat-button color="{{data.type}}" (click)='snackBarRef.dismiss()'>{{data.buttonText}}</button>
</div>
