import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailServiceService } from '../../services/email-service.service';
import { ToastrService } from 'ngx-toastr';
// import {EmailServiceService} from '@shared/services/email-service.service';
// import {MatSnackBar} from '@angular/material/snack-bar';
import { DialogService } from '@core/services/ui/dialog.service';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    private emailApi: EmailServiceService,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogService: DialogService
  ) {}

  contactForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    subject: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    body: new FormControl('', Validators.required),
  });

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
  // this.contactForm.get('subject').value,
  submit() {
    this.dialogRef.close();
    this.emailApi
      .contactUsEmail(
        this.contactForm.get('name').value,
        this.contactForm.get('email').value,
        this.contactForm.get('subject').value,
        this.contactForm.get('body').value,
        'Login'
      )
      .subscribe((res) => {
        // this.toast.success('Your query has been submitted');
        this.dialogRef.close();
        this.dialogService.confirmDialog(
          'Your Demo is now a step closer',
          'We will get back to you shortly to arrange a suitable time.',
          'login'
        );
      });
  }
}
