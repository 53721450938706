<span class="cursor-pointer eva eva-close-outline text-h3" (click)="dialogRef.close(false)"></span>
<!-- <h5>Confirm</h5> -->
<div class="m-6">
  <h3 class="mt-4">{{ confirmMessage }}</h3>
  <ng-container *ngIf="doubleVerify">
    <!--<span style="color:rgba(255, 255, 255, 0.7)">Code</span><br />-->
    <!--<span style="font-size: 10pt;user-select: none;">{{oneTimeCode}}</span>-->
    <mat-form-field>
      <mat-label>Type in `{{oneTimeCode}}` to confirm</mat-label>
      <input matInput type="number" [(ngModel)]="userInput" placeholder="Please type in Code" required>
    </mat-form-field>
  </ng-container>
  <ng-container>
<p>{{actionDescription}}</p>
  </ng-container>
  <div mat-dialog-actions align="end" style="margin-top:2rem">
    <button class="cancelBtn" (click)="dialogRef.close(false)" mat-dialog-close>{{cancelText}}</button>
    <!-- <button class="primaryBtn ml-4" [disabled]="!isValid()" (click)="dialogRef.close(true)"
      [mat-dialog-close]="true">{{confirmText}}</button> -->
      <button class="deleteBtn ml-4" [disabled]="!isValid()" (click)="dialogRef.close(true)"
      [mat-dialog-close]="true">{{confirmText}}</button>
  </div>
</div>
<!-- dialog-confirm -->