import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import CreateRole = RoleActions.CreateRole;
import AddRole = RoleActions.AddRole;
import EditRole = RoleActions.EditRole;
import { RoleActions } from './role.actions';
import { RoleStateModel } from './role.state';
import { RolesService } from '@core/core-services/roles.service';

@State<RoleStateModel>({
  name: 'Role',
  defaults: {
    roles: [],
  },
})
@Injectable()
export class RoleState {
  constructor(private roleService: RolesService) {}

  @Selector()
  static getRoles(state: RoleStateModel) {
    return state.roles;
  }

  @Action(CreateRole)
  createRole({ patchState, getState }: StateContext<RoleStateModel>, payload) {
    const currentState = getState();
    patchState({
      ...currentState,
      roles: payload.payload,
    });
  }

  @Action(AddRole)
  addRole(ctx: StateContext<any>, action: AddRole) {
    return this.roleService.addRole(action.role).pipe(
      tap((data: any) => {
        if (data) {
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            roles: [data, ...state.roles],
          });
        }
      })
    );
  }

  @Action(EditRole)
  editRole(ctx: StateContext<RoleStateModel>, action: EditRole) {
    const state = ctx.getState();
    return this.roleService.editRole(action.id, action.data).pipe(
      tap((data: any) => {
        if (data) {
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            roles: [...state.roles].map((e: any) => {
              if (e._id === data._id) {
                return data;
              }
              return e;
            }),
          });
        }
      })
    );
  }
}
