import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  apiUrl = environment.api + '/api/creative-analysis/assets';
  addDeleteAssest = new BehaviorSubject(true);

  constructor(private http: HttpClient) { }

  getAssetList(
    limit = 24,
    index = 0,
    query = '',
    sortOption = 'uploadedAt',
    filterBy = ''
  ): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `?limit=${limit}&skip=${index}&query=${query}&sort=${sortOption}&${filterBy}`
    );
  }

  getAssetById(assetId: string): Observable<any> {
    return this.http.get(this.apiUrl + `/one/${assetId}`);
  }

  getUploadLink(fileName, type, uid): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `/upload-url?contentName=${fileName}&contentType=${type}&uid=${uid}`
    );
  }

    getShotLink(assetId, path): Observable<any> {
        return this.http.post(this.apiUrl + `/assets/${assetId}/shots/shotpreviewlink`, {path: path});
    }

  getShotLink2(assetId, path): Observable<any> {
    return this.http.post(this.apiUrl + `/${assetId}/shots/shotpreviewlink`, {path: path});
  }

  createAsset(data): Observable<any> {
    return this.http.post(this.apiUrl, data);
  }

  addManualLabelsToShots(
    assetId: string,
    shotId: string,
    label
  ): Observable<any> {
    return this.http.put(
      this.apiUrl + `/${assetId}/shots/${shotId}/add-label`,
      { label }
    );
  }

  deleteManualLabel(assetId: string, shotId: string, label): Observable<any> {
    return this.http.put(
      this.apiUrl + `/${assetId}/shots/${shotId}/delete-label`,
      { label }
    );
  }

  deleteAiLabel(assetId: string, shotId: string, label: string): Observable<any> {
    return this.http.put(this.apiUrl + `/${assetId}/shots/${shotId}/delete-ai-label`, { label });
  }

  updateFriendlyName(assetId: string, friendlyName: string): Observable<any> {
    return this.http.put(this.apiUrl + `/${assetId}/shots/vaa-study-assets/friendly-name`,{ friendlyName });
  }

  getAssetDetails(assetId: string) {
    return this.http.get(`${this.apiUrl}/${assetId}`);
  }

}
