<button
  mat-button class="optimum-button"
  [color]="color" [disabled]="disabled"
  [ngClass]="{
    'mat-stroked-button'         : outline,
    'mat-flat-button'            : !outline,
    'optimum-button--fullWidth'  : fullWidth,
    'optimum-button--chunky'     : chunky,
    'optimum-button--small'      : small
  }">
  <span *ngIf="evaIconBefore" matPrefix class="eva pr-1.5 eva-{{ evaIconBefore }}"></span>
  <ng-content></ng-content>
  <span *ngIf="evaIconAfter" matSuffix class="eva pl-1.5 eva-{{ evaIconAfter }}"></span>
</button>

