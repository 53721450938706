import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./dialog-confirm.scss'],
})
export class DialogConfirm {
  doubleVerify = false;
  oneTimeCode = null;
  userInput = '';
  public cancelText = 'Cancel';
  public confirmText = 'Confirm';
 public actionDescription: string;
  constructor(public dialogRef: MatDialogRef<DialogConfirm>) {
    this.generateOneTimeCode();
  }

  public confirmMessage: string;

  /**
   * Helper function
   * @param max - max number
   */
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  /**
   * Generates one time code for the user which are random numbers.
   * This gives the impression of the gravity of the situation as well as time to think while typing.
   * @param length
   * @param maxNumber
   */
  public generateOneTimeCode(length = 6, maxNumber = 9) {
    const lstRandomNumbers = [];
    for (let i = 0; i < length; i++) {
      lstRandomNumbers.push(this.getRandomInt(maxNumber));
    }
    this.oneTimeCode = parseInt(lstRandomNumbers.join(''), 10);
  }

  /**
   * checks to make sure userInput matchines OTC
   */
  isCodeMatched() {
    return this.oneTimeCode === this.userInput;
  }

  /**
   * checks if all is okay to allow the user to proceed to confirm
   */
  isValid() {
    const confirmedOneTimeCode = this.isCodeMatched();
    if (this.doubleVerify) {
      return confirmedOneTimeCode;
    } else {
      return true;
    }
  }
}
