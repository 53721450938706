<!-- <div class="breadcrumbs">
  <div
    routerLink="/"
    class="breadcrumbs__home">
    <span class="eva eva-home-outline"></span>
  </div>

  <div class="breadcrumbs__singles">
    <div class="breadcrumbs__single"
         *ngFor="let crumb of breadcrumbs; let i = index"
         [routerLink]="crumb.destination"
         [routerLinkActive]="'breadcrumbs__single--selected'"
         [routerLinkActiveOptions]="{exact: true}"
    >
      <span>{{ crumb.name }}</span>
      <span *ngIf="i !== breadcrumbs.length - 1" class="breadcrumbs__chevron eva eva-chevron-right"></span>
    </div>
  </div>
</div>

<div class="-mx-1">
  <div class="flex">
    <div class="px-1" >
      <div class="flex items-center">

      </div>
    </div>
  </div>
</div>
 -->
<nav  class="breadcrumbs p-2 rounded-md shadow-md"  *ngIf="breadcrumbs.length > 1 || (breadcrumbs.length === 1 && breadcrumbs[0].label !== 'Home')">
  <span class="eva eva-home-outline mr-2" [ngClass]="{'text-accent-dark': breadcrumbs[0].label === 'Home'}"></span>

  <ul class="breadcrumb-list flex text-sm text-gray-600">
    <li *ngFor="let breadcrumb of breadcrumbs; let isLast = last" class=" breadcrumbs flex items-center">
      <a *ngIf="!isLast" class="hover:underline text-accent-dark cursor-pointer" (click)="navigateTo(breadcrumb.url)">{{ breadcrumb.label }}</a>
      <span *ngIf="isLast" class="font-semibold text-gray-800">{{breadcrumb.label}}</span>
      <mat-icon *ngIf="!isLast" class="text-accent-dark breadcrumbs__chevron">chevron_right</mat-icon>
    </li>
  </ul>
</nav>
