import { SortByInterface } from './sortby.interface'

export namespace SortByActions {
  export class AddSortByValue {
    static readonly type = '[SortBy] Add Sort By Value';

    constructor(public payload: SortByInterface) {
    }
  }

  export class ClearSortByValue {
    static readonly type = '[SortBy] Clear Sort By Value';

    constructor() {
    }
  }

}
