<!-- {{sheet.data[1][0].expanded}} -->

<div class="spreadsheet" *ngIf="sheet" [style.grid-template-columns]="'repeat(auto-fit, minmax(0, auto))'|safe:'style'"
    [style.grid-template-rows]="'0px minmax(30px, 1fr) repeat(auto-fit, 30px)'|safe:'style'"
    (mouseenter)="sheetMouseEnter()" (mouseleave)="sheetMouseLeave()">


    <!-- grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-template-columns: 40px 50px auto 50px 40px;
    grid-template-rows: 25% 100px auto; -->


    <!-- <span [ngStyle]="{'grid-area': '1 / 1 / span 1 / span 1'}"></span> -->
    <!-- <ng-container *ngFor="let header of sheet.headers;let c = index">
        <span [ngStyle]="{'grid-area': '1 / '+(c+2)+' / span 1 / span 1'}" (mouseup)="cellUp(c,0)">{{header}}</span>
    </ng-container> -->
    <ng-container *ngFor="let row of sheet let r = index; let even = even">
        <!-- <span [ngStyle]="{'grid-area': (r+2)+' / 1 / span 1 / span 1'}" (mouseup)="cellUp(0,r)">{{r+1}}</span> -->


        <ng-container *ngFor="let column of row; let c = index">
            <div #cell class="cell"
                [style.grid-area]="(r+2)+' / '+(c+2)+' / span '+column.rSpan+' / span 1' | safe:'style'"
                *ngIf="column.rSpan!=0" (mousedown)="cellDown(c,r,cell,$event,column.rSpan)" (mouseover)="cellOver(c,r)"
                (mouseup)="cellUp(c,r)" [id]="'cell-'+c+'-'+r" [ngClass]="{'number-value': 
                column.type==='percent'|| column.noneInput,
                'check-input': column.type==='checkbox',
                'header-cell': column.type==='header',
                'delete': column.type==='delete',
                'locked':column.locked,'even':even,
                'th':r===0,
                'number-cell':column.type==='number',
                'center':column.type==='checkbox',
                'summary-cell':column.summary===true,
                'edited-cell':column.editedSpend===true,
                'percent-cell':column.percent===true,
                'red':column.red,
                'editable':column.editable,
                'combined':column.combined,
                'sticky1':column.sticky1,
                'sticky2':column.sticky2,
                'sticky3':column.sticky3,
                'no-sales':column.noSales
            }" (contextmenu)="onContextMenu($event,{c:c,r:r})">

                <mat-icon class="reset" *ngIf="column.red" (click)="reset(r)">
                    refresh
                </mat-icon>

                <!-- <div #cell class="cell" [ngStyle]="{'grid-area': (r+2)+' / '+(c+2)+' / span 1 / span 1'}"
            *ngFor="let column of row; let c = index" (mousedown)="cellDown(c,r,cell,$event)"
            (mouseover)="cellOver(c,r)" (mouseup)="cellUp(c,r)" [id]="'cell-'+c+'-'+r"
            (contextmenu)="onContextMenu($event,{c:c,r:r})"> -->
                <ng-container *ngIf="column.type==='header' && !column.expandAll">{{column.value}}</ng-container>
                <ng-container *ngIf="column.locked && !column.noneInput">{{column.value | number}}</ng-container>
                <ng-container *ngIf="column.type==='delete'">
                    <mat-icon class="right chart-option" aria-hidden="false" (click)="deleteBrand(r-1)">
                        delete_outline
                    </mat-icon>
                </ng-container>
                <input *ngIf="column.type==='string' && !column.locked" class="cell-input" [(ngModel)]="column.value"
                    (blur)="evaluate()">

                <input *ngIf="column.type==='number' && !column.noneInput && !column.locked && column.comma"
                    class="cell-input" (blur)="evaluate()" (change)='column.value =$event.srcElement.value'
                    [ngModel]="column.value" inputWithComma>

                <input *ngIf="column.type==='number' && !column.noneInput && !column.locked && !column.comma"
                    class="cell-input" (blur)="evaluate()" (change)='column.value =$event.srcElement.value'
                    [ngModel]="column.value">
                <!-- 
                <input *ngIf="column.type==='percent' && !column.locked && !column.comma" class="cell-input"
                    (blur)="evaluate($event,column)" [ngModel]="column.value| percent:'1.2-2'"> -->

                <ng-container *ngIf="column.type==='percent'">{{column.value*100 | number:'1.2-2'}}%</ng-container>

                <ng-container *ngIf="column.noneInput">{{column.value | number:'1.0-0'}}</ng-container>

                <!-- <mat-checkbox *ngIf="column.type==='checkbox'&&!column.summary" [(checked)]="column.value"
                    [(ngModel)]="column.value" (change)="updateCheck()">
                </mat-checkbox> -->

                <mat-icon (click)="toggleExpand(column,$event)"
                    *ngIf="column.type==='checkbox'&&column.summary&&column.expanded">
                    remove_circle
                </mat-icon>
                <mat-icon (click)="toggleExpand(column,$event)"
                    *ngIf="column.type==='checkbox'&&column.summary&&!column.expanded">
                    add_circle
                </mat-icon>


                <div *ngIf="column.type==='green'&&column.summary" class="green-light"></div>
                <div *ngIf="column.type==='amber'&&column.summary" class="amber-light"></div>

                <ng-container *ngIf="column.type==='header' && column.expandAll">
                    <div class="side-by-side">
                        <mat-icon (click)="expandAll(false)">
                            remove_circle
                        </mat-icon>
                        <mat-icon (click)="expandAll(true)">
                            add_circle
                        </mat-icon>
                    </div>

                </ng-container>

                <!-- <input *ngIf="column.properties.type==='checkbox'" class="cell-input" [(ngModel)]="column.value"
                 (blur)="evaluate($event,column)"> -->

            </div>
        </ng-container>



    </ng-container>
    <div class=" select-bounds" *ngIf="sheetActive && selectBounds.r>=0 && selectBounds.c>=0 "
        [style.grid-area]="(selectBounds.r+2)+' / '+(selectBounds.c+2)+' / span '+selectBounds.rSpan+' / span '+selectBounds.cSpan | safe:'style'">
    </div>

    <div *ngIf="selectBounds.rSpan>1 && sheetActive && selectBounds.r>=0 && selectBounds.c>=0 " class="select-mask"
        [style.grid-area]="(selectBounds.r+3)+' / '+(selectBounds.c+2)+' / span '+(selectBounds.rSpan-1)+' / span '+selectBounds.cSpan | safe:'style'">
    </div>
    <div *ngIf="selectBounds.cSpan>1 &&sheetActive && selectBounds.r>=0 && selectBounds.c>=0 " class="select-mask"
        [style.grid-area]="(selectBounds.r+2)+' / '+(selectBounds.c+3)+' / span 1 / span '+(selectBounds.cSpan-1) | safe:'style'">
    </div>

    <div class="drag-bounds" *ngIf="sheetActive && selectBounds.r>=0 && selectBounds.c>=0 "
        [style.grid-area]="(dragBounds.r+2)+' / '+(dragBounds.c+2)+' / span '+dragBounds.rSpan+' / span '+dragBounds.cSpan | safe:'style'">
        <div [ngClass]="{'click-through': copying}" class="select-handle"></div>
        <div [ngClass]="{'click-through': copying}" class="select-handle" (mousedown)="handleDown(0,0)"></div>
    </div>

    <div *ngIf="copyingSelection" class="copy-bounds marching-ants"
        [style.grid-area]="(copyBounds.r+2)+' / '+(copyBounds.c+2)+' / span '+copyBounds.rSpan+' / span '+copyBounds.cSpan | safe:'style'">
    </div>
</div>

<!-- <div class="addRow">
    <button (click)="add()" mat-stroked-button>Add</button>
    <input matInput type="text" value="addRows" [(ngModel)]="addRows">
    <p>rows</p>
</div> -->


<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button *ngFor="let context of contexts" mat-menu-item
            (click)="onContextMenuAction(item,context.key)">{{context.name}}</button>

        <!-- <button mat-menu-item (click)="onContextMenuAction1(item)">Copy</button>

        <button mat-menu-item (click)="onContextMenuAction1(item)">Copy</button>
        <button mat-menu-item (click)="onContextMenuAction2(item)">Paste</button>
        <button mat-menu-item (click)="onContextMenuAction3(item)">Insert Row</button> -->
        <!-- <button mat-menu-item (click)="onContextMenuAction2(item)">Action 2</button> -->
    </ng-template>
</mat-menu>

<div id="drag-handler" (mouseenter)="scrollEnter()" (mouseleave)="scrollLeave()"></div>
