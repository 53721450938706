import {Pipe, PipeTransform} from '@angular/core';

@ Pipe({
    name: 'filterIterable'
})
export class FilterIterablePipe implements PipeTransform {

    transform(value, keys: string, term: any, drop?: boolean, excludeFromDrop?: string, special?: boolean) {
        if (term === null || typeof term === 'undefined' ) return value;
        if (typeof term === 'string') {
            if (!drop) {
                return (value || []).filter(item => item[keys] === term);
            } else {
                return (value || []).filter(item => item[keys] !== term);
            }
        }
        else if (typeof term === 'number') {
            if (!drop) {
                return (value || []).filter(item => item[keys] === term);
            } else {
                return (value || []).filter(item => item[keys] !== term);
            }
        }
        else if (['string', 'number'].includes(typeof term) && excludeFromDrop && excludeFromDrop.length > 0) {
            if (!drop) {
                return (value || []).filter(item => term.indexOf(item[keys]) > -1);
            } else {
                return (value || []).filter(item => term.indexOf(item[keys]) === -1 || item[keys] === excludeFromDrop);
            }
        }
        if (!drop) {
            return (value || []).filter(item => term.indexOf(item[keys]) > -1);
        } else {
            return (value || []).filter(item => term.indexOf(item[keys]) === -1);
        }

        // return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
    }
}
