import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserManagmentService } from '@core/core-services/user-managment.service';

@Injectable()
export class AuthorizeGuard implements CanActivate {
  // constructor(private router: Router, private userManagmentService: UserManagmentService) {
  // }
  constructor(
    private router: Router,
    private userManagmentService: UserManagmentService
  ) {}

  canActivate(next: ActivatedRouteSnapshot) {
    let user = JSON.parse(localStorage.getItem('User'));
    if (!user) {
      this.router.navigate(['auth', 'login']);
      return false;
    }

    // logged in so return true
    return this.userManagmentService.getUser(user['id']).pipe(
      map((rmuser) => {
        let client = rmuser.response.landingClient
          ? rmuser.response.landingClient
          : user['clientList'][0];
        if (
          client.features
            .map((x) => x.featureCode)
            .indexOf(next.routeConfig.path) >= 0
        ) {
          return true;
        } else {
          this.router.navigate(['no-access']);

          // this.router.navigate(['/']);
          return false;
        }
      })
    );
  }

  // canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {
  //     let user = JSON.parse(localStorage.getItem('User'));
  //     console.log(next.routeConfig.path);
  //     console.log(next.routeConfig.path=='images');
  //     console.log(user);
  //
  //     if (!user){
  //         this.router.navigate(['auth', 'login']);
  //         return false;
  //     }
  //
  //
  //     // So the user is logged in
  //     //Check if the client is allowed to visit this route
  //     this.userManagmentService.getUser(user['id']).subscribe(rmuser => {
  //         let client = rmuser.response.landingClient ? rmuser.response.landingClient : user['clientList'][0];
  //         // console.log(client);
  //         // And retoute to dashbaord
  //         // console.log(client.features.map(x=>x.featureCode).indexOf(next.routeConfig.path) );
  //         if (client.features.map(x=>x.featureCode).indexOf(next.routeConfig.path) >= 0){
  //             console.log('all is well');
  //             // this.router.navigate([nex])
  //             return true;
  //         } else {
  //             this.router.navigate(['auth', 'login']);
  //             return false;
  //         }
  //     });
  //
  //
  // }

  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }
}
