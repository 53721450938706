<div>
    <span class="eva eva-close-outline" (click)="dialogRef.close()"></span>
    <div class="content">
       <div  
       class="text-center"><h2 >Visual Asset Analyser Terms and Conditions</h2>
     </div> 
     <div>
        <p>
            These terms and conditions (“Terms”) form a binding legal agreement between you and Publicis Limited  (“we”, “our”, “us”) and govern your use of VAA.
             By access or using VAA, you accept and agree to be bound by these Terms.
            Any other terms or conditions sought to be imposed by you (including, without limitation, terms in any email or communication with us) are expressly excluded and shall not be binding on us.
             Any individual registering and/or using VAA does so on behalf of a legal entity and warrants and represents that they have authority to bind such entity.
            References to “you”, “your” in these Terms are to the legal entity in whose name the VAA account is registered.
        </p>
        <p>
            We may update these Terms from time to time and any changes will be posted on VAA and will take effect from the date that they are posted. 
            Your continued use of VAA after the updated Terms have been posted will constitute your acceptance of such updated Terms.
        </p>
     </div>
       
        <div>
            <h3 class="mt-12"> 1. Definitions and Interpretation</h3>
            <p>
               1.1  &emsp;The following terms have the following meanings:
            </p>
        </div>
        <div>
            <h3 class="mt-12">Applicable Laws</h3>
            <p>means all laws, legislation, regulations and codes of practice applicable to your access to and use of VAA.</p>   
        </div>
        <div>
            <h3 class="mt-12">Confidential Information </h3>
            <p>means all information of a confidential nature (in any form) which is imparted or disclosed to,
                 or otherwise obtained by a party (whether directly or indirectly) including the other party’s know-how,
                  trade secrets, financial, commercial, technical, tactical, strategic, marketing or customer information,
                   employee information, any information agreed to be or marked as confidential, any other information a party knows,
                    or could be reasonably expected to know, is confidential and any other such information related to or concerning a party’s business.</p>
        </div>
        <div>
            <h3 class="mt-12">Data Protection Laws </h3>
            <p> means any laws relating to privacy or to the processing, privacy and/or use of personal data as applicable to VAA.</p>
        </div>
        <div>
            <h3 class="mt-12">Intellectual Property Rights </h3>
            <p> means copyright, rights in inventions, patents, know-how, trade secrets, trade marks and trade names,
                 service marks, design rights, rights in get-up, database rights and rights in data,
                  semiconductor chip topography rights, goodwill, the right to sue for passing off or unfair competition,
                   utility models, domain names and all similar rights and, in each case:</p>

                   <ol>
                    <li>(a)&emsp;whether registered or not</li>
                    <li>(b)&emsp; including any applications to protect or register such rights;</li>
                    <li>(c)&emsp;including all renewals and extensions of such rights or applications;</li>
                    <li>(d)&emsp;whether vested, contingent or future; and</li>
                    <li>(e)&emsp;wherever existing;</li>
                  </ol>
        </div>
        <div>
            <h3 class="mt-12">VAA</h3>
            <p>means the Visual Asset Analyser tool owned, provided and controlled by us and includes all materials in VAA.</p>   
        </div>
        <div>
            <h3 class="mt-12">Virus</h3>
            <p>means anything or device (including software, code, file or programme) which may prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunication service, equipment or network or any other device or any programme or data or otherwise adversely affect the user experience, including any worms, trojan  horses, viruses, adware, malware, bit torrents and other similar things.</p>   
        </div>
        <div>
            <h3 class="mt-12">Your Materials</h3>
            <p>means any of your materials uploaded to VAA for the purposes of your use of VAA and or provision of VAA to you and other VAA users including those that you upload directly and those that you indicate for upload by means of a particular URL or social media handle.</p>   
        </div>
        <p>
            1.2  &emsp;In these Terms:
        </p>
        <p>
            1.2.1  &emsp;any words that follow <i> ‘include’, ‘includes’, ‘including’, ‘in particular’</i> or any similar words and expressions shall be construed as illustrative only and shall not limit the sense of any word, phrase, term, definition or description preceding those words.
        </p>
        <p>
            1.2.1  &emsp;a reference to legislation is a reference to that legislation as amended, extended, re-enacted or consolidated from time to time.
        </p>
        <div>
            <h3 class="mt-12"> 2. Account Registration </h3>   
            <p>2.1&emsp;When registering with VAA you must provide accurate and complete information. You are solely responsible for keeping your account details safe and secure, including any password.</p>
            <p>2.2&emsp;You are responsible for all activities and actions carried out via your account and you must not transfer your account to any third party or otherwise allow a third party to use your account. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
            <p>2.3&emsp;Where you register an account on behalf of your organisation (i.e. where you register an account with the intent to, or under which, you make use of VAA for the purposes of an organisation with which your are affiliated as an employee, representative, contractor, agent or in any other capacity), these Terms apply to your organisation as party to it and references to “you” and “your” apply to your organisation as though it were you.</p>
        </div>
        <div>
            <h3 class="mt-12"> 3. Use of VAA</h3>   
            <p>3.1&emsp;Subject to your compliance with these Terms, we grant you a non-exclusive, non-transferrable, revocable licence to use VAA for your own internal business purposes only.</p>
            <p>3.2&emsp;Except as may be allowed by any Applicable Law which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under these Terms, you will not:</p>
            <ol>
                <li>3.2.1&emsp;attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit or distribute all or any of VAA in any form or media by any means;</li>
                <li>3.2.2&emsp;attempt to decompile, reverse compile, disassemble, reverse engineer or otherwise reduce to human perceivable form all or any part of VAA;</li>
                <li>3.2.3&emsp;access all or any part of VAA in order to build a product or service which competes with VAA;</li>
                <li>3.2.4&emsp;license, sell, rent, lease, transfer, assign, distribute or commercially exploit VAA;</li>
                <li>3.2.5&emsp;use the materials within VAA outside VAA;</li>
                <li>3.2.6&emsp;use VAA to provide services to any third party;</li>
                <li>3.2.7&emsp;use VAA for any unlawful activity including to gain unauthorised access to computers, data, systems and networks;</li>
                <li>3.2.8&emsp;use VAA in a manner that gives rise to harm, loss or damage to other VAA users or inhibits the provision of VAA to other VA users (for example in manner that would disable or overload the relevant systems through which VAA is provided);</li>
                <li>3.2.9&emsp;introduce, or permit the introduction of, any Virus or vulnerability into VAA or our network and systems, and you will take steps in accordance with good industry practice to prevent this including the use of appropriate virus detection software.</li>
              </ol>
            <p>3.3&emsp;You shall:</p>
            <ol>
                <li>3.3.1&emsp;cooperate with us in all matters relating to your use of VAA and respond promptly to any request by us for information reasonably required in connection with your use of VAA;</li>
                <li>3.3.2&emsp;be solely responsible for procuring, maintaining and securing your network connections and telecommunications links and for all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to your network connections or telecommunications links or otherwise caused by the internet;</li>
                <li>3.3.3&emsp;ensure that you have all rights, permissions, licences and consents to upload all Your Materials and to provide to us the rights granted under clause 6.3;</li>
                <li>3.3.4&emsp;be solely responsible for all decisions you make based on VAA output.</li>
            </ol>

        </div>
        <div>
            <h3 class="mt-12"> 4. Warranties </h3>   
            <p>4.1&emsp;You represent and warrant that you have the authority and requisite corporate power to accept these Terms.  You represent and warrant (and where relevant on behalf of your oganisation) that: </p>
            <ol>
                <li>4.1.1&emsp;you are not located in a country that is subject to a U.S Government embargo, or that has been designated by the U.S Government as a “terrorist supporting” country;</li>
                <li>4.1.2&emsp;you have all necessary rights, permissions, licences and consents to upload and use VAA in relation to Your Materials;</li>
            </ol>
            <p>4.2&emsp;VAA is provided AS IS without any warranties, guarantees and representations including as to satisfactory quality, merchantability, fitness for purpose or availability of VAA. Without limiting the foregoing, we do not warrant that: </p>
            <ol>
                <li>4.2.1&emsp;VAA will be available, uninterrupted or error-free;</li>
                <li>4.2.2&emsp;VAA will meet your requirements;</li>
                <li>4.2.3&emsp;VAA will be free from Viruses. </li>

            </ol>
        </div>
        <div>
            <h3 class="mt-12"> 5. Indemnity</h3>   
            <p>5.1&emsp;You will defend, indemnify and keep us fully indemnified from and against all liabilities, losses, costs, expenses and damages, (including reasonable legal fees) suffered or incurred by us (including as a result of an claim by a Publisher or action taken by a relevant regulator) arising out of or in connection with any breach by you of these Terms.</p>
        </div>
        <div>
            <h3 class="mt-12"> 6. Intellectual Property Rights</h3>   
            <p>6.1&emsp;Except for Your Materials, all Intellectual Property Rights in VAA belong to us and/or our licensors. The rights in VAA are licensed to you and not sold.  You acknowledge and agree that you have no rights in, or to, VAA other than the right to use it in accordance with these Terms.  </p>
            <p>6.2&emsp;To the extent that VAA is the subject of any third party claim, we may procure the right for you to continue using VAA or to replace or modify VAA so that it becomes non-infringing or, if such remedies are not reasonably available in our sole opinion, we may terminate your account without any additional liability or obligation to pay damages or costs to you. The provisions of this clause 6.2 constitute your sole remedies in the event of any such third party claim. </p>
            <p>6.3&emsp;You are the owner or licensee of all Intellectual Property Rights in Your Materials. You grant to us a worldwide, non-exclusive, sub-licensable, irrevocable, fully paid up, royalty-free licence to:   </p>
            <ol>
                <li>6.3.1&emsp;use Your Materials to provide VAA in accordance with these Terms (to you and to other VAA users);</li>
                <li>6.3.2&emsp;use Your Materials to improve VAA;</li>
                <li>6.3.3&emsp;use your name, corporate logos and details in any internal reporting or analysis and for marketing and promotional purposes. </li>

            </ol>
        </div>
        <div>
            <h3 class="mt-12"> 7. Suspension and Termination</h3>   
            <p>7.1&emsp;We may temporarily block, suspend or terminate your account at any time at our discretion including where:</p>
            <ol>
                <li>7.1.1&emsp;you have otherwise breached any of these Terms; and</li>
                <li>7.1.2&emsp;there are security reasons requiring us to do so.</li>
            </ol>
            <p>7.2&emsp;You may terminate your account at any time by logging in to your account and initiating a termination. </p>
        </div>
        <div>
            <h3 class="mt-12"> 8.	Consequences of Termination</h3>   
            <p>8.1&emsp;If your account is terminated for any reason:</p>
            <ol>
                <li>8.1.1&emsp;you shall cease to have any right to use VAA;</li>
                <li>8.1.2&emsp;you shall destroy or return (at our election) all Confidential Information in your possession or under your control and all copies of such information;</li>
                <li>8.1.3&emsp;we may continue to use Your Materials for the purposes of the continued provision of VAA to other users and the continued improvement of VAA.</li>

            </ol>
            <p>8.2&emsp;Termination or expiry of your account shall not affect any of our rights, remedies, obligations or liabilities that have accrued up to the date of termination or expiry, including the right to claim damages in respect of any breach of these Terms which existed at or before the date of termination or expiry.</p>
            <p>8.3&emsp;Termination of your account for whatever reason shall not operate to affect any provisions that expressly or by implication survive termination. </p>
        </div>
        <div>
            <h3 class="mt-12"> 9.	Limitation of Liability</h3>   
            <p>9.1&emsp;We shall have no liability to you for any loss, damage, expense or other claims arising as a result of or relating to: </p>
            <ol>
                <li>9.1.1&emsp;any third party claims made in relation to Your Materials;</li>
                <li>9.1.2&emsp;use of VAA in any manner inconsistent with these Terms;</li>
                <li>9.1.3&emsp;the input by you of any inaccurate, incomplete, illegible or incorrect information into VAA; </li>
                <li>9.1.4&emsp;any communications or network defects, delays or failures that you may experience in trying to access and use VAA; and/or </li>
                <li>9.1.5&emsp;your negligence and/or wilful misconduct. </li>
            </ol>
            <p>9.2&emsp;ubject to clause 9.4, we shall not be liable in contract, tort (including negligence) breach of statutory duty or otherwise for any:</p>
            <ol>
                <li>9.2.1&emsp;loss of profit;</li>
                <li>9.2.2&emsp;loss of opportunity;</li>
                <li>9.2.3&emsp;loss of goodwill or reputation; </li>
                <li>9.2.4&emsp;loss of anticipated saving; </li>
                <li>9.2.5&emsp;loss of revenue; </li>
                <li>9.2.6&emsp;loss of data or information; and/or </li>
                <li>9.2.7&emsp;any indirect, special or consequential loss.</li>
            </ol>
            <p>9.3&emsp;Subject to clause 9.2 and 9.4, our maximum liability to you under or in connection with these Terms, whether arising from contract, tort (including negligence), breach of statutory duty or otherwise shall not exceed the greater of the total amount paid or payable by you for access to VAA in the relevant calendar year.</p>
            <p>9.4&emsp;Nothing in these Terms will limit or exclude either party’s liability for death or personal injury resulting from its own negligence, fraud or any other liability that cannot be limited or excluded by law.</p>
            <p>9.5&emsp;Except as expressly set out in these Terms, all conditions, warranties, terms and undertakings, express or implied, statutory or otherwise in respect of the obligations of the parties under these Terms are excluded insofar as it is possible to do in law.</p>
        </div>
        <div>
            <h3 class="mt-12"> 10.	Confidential Information</h3>   
            <p>10.1&emsp;Subject to clauses 10.2 and 10.3, each party agrees that it will: </p>
            <ol>
                <li>10.1.1&emsp;keep the Confidential Information confidential and will not disclose it to any third party; </li>
                <li>10.1.2&emsp;only use the Confidential Information as strictly necessary for the performance of its obligations under these Terms; and</li>
                <li>10.1.3&emsp;take all reasonable measures to maintain the confidentiality of the Confidential Information in its possession or control, which will be of a standard which is at least equivalent to the measures that it uses to maintain the confidentiality of its own Confidential Information of similar nature and importance.</li>
            </ol>
            <p>10.2&emsp;Where strictly necessary for the performance of its obligations under these Terms, a party may disclose the other party’s Confidential Information to consultants, sub-contractors, agents or professional advisors, provided always that that party ensures that each such third party to whom Confidential Information is disclosed is aware of the confidential nature of the Confidential Information prior to any such disclosure and that it complies with this clause 10. </p>
            <p>10.3&emsp;The provisions of clause 10.1 shall not apply to:</p>
            <ol>
                <li>10.3.1&emsp;any information that is, or was already known or available to the receiving party, otherwise than pursuant to or through breach of any confidentiality obligation owed to the disclosing party;</li>
                <li>10.3.2&emsp;any information that is, or becomes, in the public domain other than through any breach of these Terms;</li>
                <li>10.3.3&emsp;information that is disclosed to the receiving party, without any obligation of confidence to the disclosing party, by a third party that is not itself under or in breach of any obligation of confidentiality;</li>
                <li>10.3.4&emsp;information that is independently developed by or on behalf of the receiving party without using information supplied by the disclosing party; and</li>
                <li>10.3.5&emsp;any disclosure required by law or a regulatory authority or otherwise by the  </li>
            </ol>
        </div>
        <div>
            <h3 class="mt-12"> 11.	Data protection, cookies and tracking technologies</h3>   
            <p>11.1&emsp;Each party shall comply at all times with all requirements of the Data Protection Laws.</p>
            <p>11.2&emsp;We shall process personal data in accordance with the terms of our <a routerLink="" (click)="openPrivacypolicy()"><b>[Privacy Notice]</b></a></p>
            <p>11.3&emsp;You shall not set cookies or other tracking technologies (which includes use of any technology in connection with the collection, storage, accessing or other processing of any data in any manner that is subject to any Data Protection Laws) in Your Materials.</p>
        </div>
        <div>
            <h3 class="mt-12">12.	Updates</h3>   
            <p>12.1&emsp;We may release changes, updates or enhancements to VAA at any time. </p>
        </div>
        <div>
            <h3 class="mt-12">13.	Force Majeure</h3>   
            <p>13.1&emsp;We shall not be liable if delayed in or prevented from performing our obligations under these Terms due to circumstances beyond our reasonable control including, but not limited to war, terrorism, strikes, lock outs or other industrial action, breakdown of systems or network access, explosion, fire, corrosion, flood, epidemics, pandemics, natural disaster, or adverse weather conditions.</p>
        </div>
        <div>
            <h3 class="mt-12">14.	Notices</h3>   
            <p>14.1&emsp;You agree that we may communicate with you electronically about any important information regarding your account or VAA generally.</p>
            <p>14.2&emsp;Any notice or other communication given by you shall be in writing and in English and sent to us at <b>vaa-support@thisisvaa.com</b></p>
            <p>14.3&emsp;This clause does not apply to notices given in legal proceedings.</p>
        </div>
        <div>
            <h3 class="mt-12">15.	General</h3> 
            <p>15.1&emsp;These Terms constitute the entire agreement between us and you and supersedes all previous agreements, understandings and arrangements between us and you in respect of its subject matter, whether in writing or oral. </p>
            <p>15.2&emsp;You acknowledge that you have not entered into these Terms in reliance on, and shall have no remedies in respect of, any representation or warranty that is not expressly set out in these Terms. </p>
            <p>15.3&emsp;You may not assign, sub-contract, transfer, sub-licence, mortgage, charge, declare a trust of or deal in any other manner with any or all of your rights or obligations under these Terms. </p>
            <p>15.4&emsp;The parties are independent and are not partners or principal and agent and these Terms do not establish any joint venture, trust, fiduciary or other relationship between them, other than the contractual relationship expressly provided for in it. Neither party shall have, nor shall represent that it has, any authority to make any commitments on the other party’s behalf. </p>
            <p>15.5&emsp;If any provision of these Terms (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of these Terms shall not be affected. </p> 
            <p>15.6&emsp;If any provision of these Terms (or part of any provision) is or becomes illegal, invalid or unenforceable but would be legal, valid and enforceable if some part of it was deleted or modified, the provision or part-provision in question shall apply with such deletions or modifications as may be necessary to make the provision legal, valid and enforceable. In the event of such deletion or modification, the parties shall negotiate in good faith in order to agree the terms of a mutually acceptable alternative provision. </p>
            <p>15.7&emsp;No failure, delay, or omission by either party in exercising any right, power or remedy provided by law or under this Terms shall operate as a waiver of that right, power or remedy, nor shall it preclude or restrict any future exercise of that or any other right, power or remedy. </p>
            <p>15.8&emsp;No single or partial exercise of any right, power or remedy provided by law or under these Terms shall prevent any future exercise of it or the exercise of any other right, power or remedy.</p>
            <p>15.9&emsp;A waiver of any term, provision, condition or breach of these Terms shall only be effective if given in writing and signed by the waiving party, and then only in the instance and for the purpose for which it is given.</p>
            <p>15.10&emsp;A person who is not a party to these Terms shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any of its provisions.</p>
        </div>
        <div>
            <h3 class="mt-12">16.	Governing Law and Jurisdiction</h3>   
            <p>16.1&emsp;These Terms and any dispute or claim arising out of, or in connection with, them, their subject matter or formation (including non-contractual disputes or claims) shall be governed by, and construed in accordance with, the laws of England and Wales. </p>
            <p>16.2&emsp;The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of, or in connection with, these Terms, its subject matter or formation (including non-contractual disputes or claims). </p>
        </div>

        <div>
            <p>* These Terms were last updated on 8 September 2022</p>
        </div>
        
    </div>
</div>