import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

// Routing
import { LinksComponent } from '@shared/components/links/links.component';

import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LinksComponent,
  ],
  // Please do not add anything here.
  // Any Global Service should go to CoreModule (e.g. AuthService)
  // and anything that will shared (e.g. DownloadDataUrlService, AngularToastifyModule) should go to the SharedModule
  imports: [CoreModule, SharedModule, AppRoutingModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
