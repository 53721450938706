import { Pipe, PipeTransform } from '@angular/core';
import { formatPercent } from '@angular/common';
@Pipe({
  name: 'percent'
})
export class PercentPipe implements PipeTransform {

  transform(value: number, digitsInfo = undefined) {
    if (value !== value){ //if is NaN
      return '';
    }
    if ( Math.abs(value) < 1e-3){ //don't display if less than one thousandth
      return '';
    }
    if (digitsInfo === undefined) {
      digitsInfo = "1.1-1";
    }
    let percent_str = formatPercent(value, "en-US", digitsInfo);
    if (value > 0) {
      return "+" + percent_str;
    }
    return percent_str;
  }
}
