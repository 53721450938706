import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ProgressStateModel } from './progress-state.model';
import { ProgressStateActions } from './progress-state.actions';
import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '@shared/components/custom-snack-bar/custom-snack-bar.component';
import { AssetsService } from '../../../modules/vaa/creative-analysis/services/assets.service';
import { StudyService } from '../../../modules/vaa/creative-analysis/services/study.service';

@State<ProgressStateModel>({
  name: 'CreativeAnalysisProgress',
  defaults: {
    ProgressState: {
      isOpen: false,
      job: {}
    }
  }
})
@Injectable()
export class ProgressState {
  constructor(private assetsAPI: AssetsService, private studyAPI: StudyService) {
  }

  // get current panel settings
  @Selector()
  static getCurrentPanelSettings(state: ProgressStateModel) {
    return state && state.ProgressState;
  }

  // Set Progress Display Settings
  @Action(ProgressStateActions.SetProgressStateDisplayState)
  setProgressStateDisplay(
    ctx: StateContext<ProgressStateModel>,
    { payload }: ProgressStateActions.SetProgressStateDisplayState
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ProgressState: {
        isOpen: payload.isOpen,
        job: payload.job
      }
    });
  }

  // Set Progress Display Job
  @Action(ProgressStateActions.SetProgressStateJob)
  async setProgressStateJob(
    ctx: StateContext<ProgressStateModel>,
    { payload }: ProgressStateActions.SetProgressStateJob
  ) {
    const state = ctx.getState();
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    const result = payload.job;
    console.log(result);
    if (result && Object.keys(result).length && result['assetList']) {
      ctx.patchState({
        ProgressState: {
          isOpen: true,
          job: {
            ...result,
            status: 'Preparing to upload files',
            stage: `Uploading ${result.assetList.length} assets`
          }
        }
      });
      const links = await Promise.all(result.assetList.map(async a => {
        const url = await this.assetsAPI.getUploadLink(a.name, a.type, Date.now()).toPromise();
        return {
          url,
          file: a
        };
      }));
      if (typeof Worker !== 'undefined') {
        // Create a new worker
        const assetUploadWorker = new Worker(new URL('./../../../modules/vaa/creative-analysis/workers/upload.worker', import.meta.url), {
          type: 'module'
        });
        const mediaUploadWorker = new Worker(new URL('./../../../modules/vaa/creative-analysis/workers/media-data.worker', import.meta.url), {
          type: 'module'
        });
        let uploadCount = 0;
        assetUploadWorker.onmessage = async ({ data }) => {
          if (data.cat === 'fileUploadUpdate') {
            uploadCount += 1;
            ctx.patchState({
              ProgressState: {
                isOpen: true,
                job: {
                  ...result,
                  status: `Completed uploading file ${data.file.name}`,
                  stage: `Uploaded ${uploadCount} of ${result.assetList.length} assets`
                }
              }
            });
          }
          if (data.cat === 'fileUploadCompleted') {
            ctx.patchState({
              ProgressState: {
                isOpen: true,
                job: {
                  ...result,
                  status: `Completed uploading all ${result.assetList.length} files. Now adding them to Analysis`,
                  stage: `Uploaded ${uploadCount} of ${result.assetList.length} assets`
                }
              }
            });
            const assets = await Promise.all(data.links.map(async l => {
              const asset = await this.assetsAPI
                .createAsset({
                  filePath: l.url.fileKey,
                  fileName: l.file.name,
                  category: l.file.type
                }).toPromise();
              return asset.asset._id;
            }));
            const studyAssets = await this.studyAPI.addAssetsToStudy(data.studyId, assets).toPromise();
            ctx.patchState({
              ProgressState: {
                isOpen: true,
                job: {
                  ...result,
                  status: `Added ${result.assetList.length} to Analysis. Now uploading performance data`,
                  stage: `Adding Assets to Analysis.`
                }
              }
            });
            // finally upload the media file
            mediaUploadWorker.postMessage({
              studyId: result.studyId,
              studyAssets: studyAssets.response,
              mediaUploadLink: result.mediaUploadLink,
              mediaFile: result.mediaFile
            });
          }
        };

        mediaUploadWorker.onmessage = async ({ data }) => {
          ctx.patchState({
            ProgressState: {
              isOpen: true,
              job: {
                ...result,
                status: `Performance data has been added. We will start the analysis and let you know when done. This message will self destruct in 5 seconds.`,
                stage: `Performance Data`
              }
            }
          });
          await this.studyAPI.createStudyMediaFile(data.studyId, data.mediaUploadLink.fileKey, data.studyAssets).toPromise();
          await sleep(5000);
          ctx.patchState({
            ProgressState: {
              isOpen: false,
              job: {
                status: 'Compleeted',
                stage: `Compleeted`
              }
            }
          });

        };

        assetUploadWorker.postMessage({
          studyId: result.studyId,
          links
        });
      }
    }
  }
}
