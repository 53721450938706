import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserManagmentService } from '@core/core-services/user-managment.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private userManagmentService: UserManagmentService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let user = JSON.parse(localStorage.getItem('User'));
    if (!user) {
      this.router.navigate(['auth', 'login']);
      return false;
    }
    let _url: string = '';
    state.url.split('/').forEach((element) => {
      if (_url === '') if (element !== '') _url = element;
    });
    if (_url === 'admin') {
      return this.userManagmentService.canUserAccessFeature(_url).pipe(
        map((access) => {
          return true;
          // if (access.access) {
          //   return true;
          // }
          // this.toastr.error('You do not have access to this app group');
          // this.router.navigate([this.router.url]);
          // return false;
        })
      ) as Observable<boolean>;
    }

    // logged in so check if this user can go to this app route or not (should also take into account the child routes)
    return this.userManagmentService
      .canUserAccessFeature(next.routeConfig.path)
      .pipe(
        map((access) => {
          if (access.access) {
            return true;
          }
          this.toastr.error('You do not have access to this app group');
          this.router.navigate([this.router.url]);
          return false;
        })
      ) as Observable<boolean>;
  }
}
