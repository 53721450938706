import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VideosDataService {
  requests = [];
  res = null;
  error = null;
  token = null;

  constructor(private http: HttpClient) {}

  // Videos Methods
  getVideos(client?: string): Observable<any[]> {
    let _client = client || 'visa';
    let url =
      'https://storage.googleapis.com/optimum-assets/' + _client + '/info.json';
    return this.http.get<any[]>(url);
  }

  // Videos Methods
  getVideoTags(client?: string): Observable<any[]> {
    let _client = client || 'visa';
    let url =
      'https://storage.googleapis.com/optimum-assets/' + _client + '/tags.json';
    return this.http.get<any[]>(url);
  }

  getVideodetail(client?: string, id?: string): Observable<any[]> {
    let _client = client || 'visa';
    let _id = id || 'visa';
    if (_id === 'visa') {
      _id = _id.substr(-11);
    }
    let url =
      'https://storage.googleapis.com/optimum-assets/' +
      _client +
      '/details/' +
      _id +
      '.json';
    return this.http.get<any[]>(url);
  }
}
