export const environment = {
  production: false,
  api: 'https://vaa-api.zenithmedia.io/dev',
  firebaseConfig: {
    apiKey: 'AIzaSyCui7TZPPNyogDjZLRaIibTdPbadbtg8mI',
    databaseURL: 'https://vaa-fb-dev.europe-west1.firebasedatabase.app/',
    authDomain: 'vaa-fb-dev',
    storageBucket: 'vaa-fb-dev.appspot.com',
  },
};
