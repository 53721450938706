import { AppInterface } from './app.interface';

export namespace AppActions {

    export class ToggleLoading {
        static readonly type = '[Apps] Toggle loading';
        constructor(public loading: boolean) { }
    }

    export class SetApps {
        static readonly type = '[Apps] set apps';
        constructor(public appList: any) { }
    }


    export class SetSelectedApp {
        static readonly type = '[Apps] setting default apps';
        constructor(public selectedApp: any) { }
    }


    // Gets all clients a user has access to
    export class UpdateApps {
        static readonly type = '[Apps] update apps';
        constructor(public market: any) { }
    }



    export class SetClientFeatures {
        static readonly type = '[Apps] set client features';
        constructor(public market: any) { }
    }


}
