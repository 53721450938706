import {RolesInterface} from "./role.interface";

export namespace RoleActions {
    export class CreateRole {
        static readonly type = '[Admin] Create Role';

        constructor(public payload: RolesInterface[]) {
        }
    }

    export class EditRole {
        static readonly type = '[Admin] Edit Role';

        constructor(public id, public data) {
        }
    }

    export class RemoveRole {
        static readonly type = '[Admin] Remove Role';

        constructor(public payload: number) {
        }
    }

    export class AddRole {
        static readonly type = '[Admin] Add Role';

        constructor(public role: any) {
        }
    }


}