import {
  Directive,
  OnDestroy,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[ngModel][inputWithComma]',
  providers: [DecimalPipe, NgModel],
})
export class NumberCommaDirective implements OnInit, OnDestroy {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  // onInputChange($event){
  //     this.value = $event.target.value.toUpperCase()
  //     this.ngModelChange.emit(this.value)
  //     }
  position = 0;

  private subscription: Subscription;

  constructor(
    private ngControl: NgControl,
    private decimal: DecimalPipe,
    private el: ElementRef
  ) {}

  ngOnInit() {
    const control = this.ngControl.control;
    this.subscription = control.valueChanges
      .pipe(
        map((value) => {
          let checker = false;
          if (value == '-1000' || value === '-1,000') {
            checker = true;
          }
          if (typeof value === 'number' || typeof value === 'string') {
            if (value !== '-') {
              let negative = false;
              if (value[0] === '-' || value < 0) {
                negative = true;
                if (typeof value === 'string') {
                  value = value.substring(1);
                }
                if (typeof value === 'number') {
                  value = -value;
                }
              }
              const parts = value.toString().split('.');
              const length1 = parts[0].length;
              parts[0] = this.decimal.transform(
                parts[0].replace(/[^0-9]/g, '')
              );
              let length2;
              if (parts[0]) {
                length2 = parts[0].length;
              } else {
                length2 = 0;
              }
              if (length2 - length1 >= 0) {
                this.position =
                  this.el.nativeElement.selectionStart + length2 - length1;
              } else {
                this.position = this.el.nativeElement.selectionStart;
              }

              if (negative) {
                if (typeof parts[0] === 'string') {
                  return '-' + parts[0];
                }
                if (typeof parts[0] === 'number') {
                  return -parts[0];
                }
              } else {
                return parts[0];
              }
            }

            return '-';
          }
        })
      )
      .subscribe((v) => {
        control.setValue(v, { emitEvent: false });
        this.el.nativeElement.setSelectionRange(this.position, this.position);
        // this.ngModelChange.emit(Number(v.replace(/[^0-9]/g, '')));
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
