// import { Pipe, PipeTransform } from '@angular/core';
//
// @Pipe({
//   name: 'safe'
// })
// export class SafePipe implements PipeTransform {
//
//   transform(value: any, args?: any): any {
//     return null;
//   }
//
// }

import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'safe',
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(
    value: any,
    type: string = 'resourceUrl',
    fileType?: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      case 'svg':
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(new Blob([value], { type: 'image/svg+xml' }))
        );
      case 'img':
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(new Blob([value], { type: fileType }))
        );
      case 'vid':
        return this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(value)
        );
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
