import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirm } from '@shared/components/dialog-confirm/dialog-confirm';
import { NavigationHelperService } from "@shared/ui-components/navigation/breadcrumbs/navigation-helper.service";
import { last, set } from 'lodash';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<{ label: string, url: string }[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();
  private breadcrumbs: { label: string, url: string }[] = [];
  private updatedBreadcrumbs = new Map<string, string>();

  private clickedBreadcrumbRouteSubject = new BehaviorSubject<string>('');
  clickedBreadcrumbRoute$ = this.clickedBreadcrumbRouteSubject.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private navigationHelperService: NavigationHelperService
  ) {
    this.router.events.subscribe(() => {
      this.updateBreadcrumbs();
    });
  }

  updateBreadcrumbs(): void {
    if (!this.hasUnsavedChanges() || !this.isBrowserBackClicked()) {
      this.updateBreadcrumbsInternal();
    }
  }
  
  confirmNavigation(): void {
    const dialogRef = this.dialog.open(DialogConfirm, {
      disableClose: false
    });
    dialogRef.componentInstance.confirmText = 'Leave';
    dialogRef.componentInstance.confirmMessage = 'You have unsaved changes. Are you sure you want to leave?';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.navigationHelperService.setrouterWithUnsavedChanges(false);
        this.router.navigateByUrl(this.clickedBreadcrumbRouteSubject.value);
      }
    });
  }

  private hasUnsavedChanges(): boolean {
    return this.navigationHelperService.getrouterWithUnsavedChanges();
  }

  private isBrowserBackClicked(): boolean {
    return this.navigationHelperService.getBrowserBackButtonClicked();
  }

  private updateBreadcrumbsInternal(): void {
    const breadcrumbs: { label: string, url: string }[] = [];
    const addedLabels = new Set<string>();
    let url = '';
    
    // Function to recursively traverse the route tree and gather breadcrumbs
    const gatherBreadcrumbs = (currentRoute: ActivatedRoute, parentUrl: string): void => {
        const routeConfig = currentRoute.routeConfig;
        const currentSegment = currentRoute.snapshot.url.map(segment => segment.path).join('/');
        
        if (routeConfig) {
            let breadcrumbData = routeConfig.data?.breadcrumb;
            
            // If there is an updated label, use it instead of the original breadcrumb
            if (this.updatedBreadcrumbs.has(breadcrumbData)) {
                breadcrumbData = this.updatedBreadcrumbs.get(breadcrumbData)!;
            }

            if (breadcrumbData && !addedLabels.has(breadcrumbData)) {
                // Create full URL path
                url = `${parentUrl}/${currentSegment}`.replace(/\/+/g, '/'); // Ensure the URL doesn't contain duplicate slashes
                
                // Add breadcrumb to the list
                breadcrumbs.push({
                    label: breadcrumbData,
                    url: url,
                });
                
                // Track the added label
                addedLabels.add(breadcrumbData);
            }
        }
        
        // Recursively process child routes if any
        currentRoute.children.forEach(childRoute => {
            gatherBreadcrumbs(childRoute, url);
        });
    };

    // Start gathering breadcrumbs from the root
    gatherBreadcrumbs(this.route.root, url);
    
    // Update the breadcrumbs list and notify subscribers
    this.breadcrumbs = breadcrumbs;
    this.breadcrumbsSubject.next(this.breadcrumbs);
  }

  updateSpecificBreadcrumb(currentLabel: string, newLabel: string): void {
    // Update the breadcrumb in the breadcrumbs array
    const breadcrumbToUpdate = this.breadcrumbs.find(breadcrumb => breadcrumb.label === currentLabel);
    
    if (breadcrumbToUpdate) {
        breadcrumbToUpdate.label = newLabel;
        
        // Notify subscribers of the updated breadcrumbs
        this.breadcrumbsSubject.next(this.breadcrumbs);
    }

    // Store the updated breadcrumb in the dictionary
    this.updatedBreadcrumbs.set(currentLabel, newLabel);
  }

  setClickedBreadcrumbRoute(route: string): void {
    this.clickedBreadcrumbRouteSubject.next(route);
    if (!this.hasUnsavedChanges()) {
      this.router.navigateByUrl(this.clickedBreadcrumbRouteSubject.value);
    } else {
      this.confirmNavigation();
    }
  }
}