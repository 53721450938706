import { ProgressStateInterface } from './progress-state.interface';

export namespace ProgressStateActions {
  export class SetProgressStateDisplayState {
    static readonly type = '[Progress] Set Display State';
    constructor(public payload: ProgressStateInterface) {}
  }

  export class SetProgressStateJob {
    static readonly type = '[Progress] Set Display State';
    constructor(public payload: ProgressStateInterface) {}
  }
}
