import { State, Action, StateContext, Selector } from '@ngxs/store';
import { PanelStateModel } from './panel.model';
import { PanelActions } from './panel.actions';
import { Injectable } from '@angular/core';

@State<PanelStateModel>({
  name: 'Panel',
  defaults: {
    Panel: {
      displayType: '',
      rankType: '',
      brandSettings: '',
    },
  },
})
@Injectable()
export class PanelState {
  constructor() {}

  // get current panel settings
  @Selector()
  static getCurrentPanelSettings(state: PanelStateModel) {
    return state && state.Panel;
  }

  // Panel display type
  @Action(PanelActions.AddPanelDisplayType)
  addPanelDisplayType(
    ctx: StateContext<PanelStateModel>,
    { payload }: PanelActions.AddPanelDisplayType
  ) {
    const state = ctx.getState();

    ctx.patchState({
      Panel: {
        displayType: payload,
        rankType: state.Panel.rankType,
        brandSettings: state.Panel.brandSettings,
      },
    });
  }

  // @Action(PanelActions.ClearPanelDisplayType)
  // clearPanelDisplayType({ patchState }: StateContext<PanelStateModel>) {
  //   patchState({
  //     Panel: {
  //       displayType: '',
  //       rankType: '',
  //     },
  //   })
  // }

  // Panel rank by engagement
  @Action(PanelActions.AddRankType)
  addRankType(
    ctx: StateContext<PanelStateModel>,
    { payload }: PanelActions.AddRankType
  ) {
    const state = ctx.getState();

    ctx.patchState({
      Panel: {
        displayType: state.Panel.displayType,
        rankType: payload,
        brandSettings: state.Panel.brandSettings,
      },
    });
  }

  // @Action(PanelActions.ClearRankType)
  // clearRankType({ patchState }: StateContext<PanelStateModel>) {

  // }

  // Panel brand settings
  @Action(PanelActions.AddBrandSettings)
  addBrandSettings(
    ctx: StateContext<PanelStateModel>,
    { payload }: PanelActions.AddBrandSettings
  ) {
    const state = ctx.getState();

    ctx.patchState({
      Panel: {
        ...state.Panel,
        brandSettings: payload,
      },
    });
  }

  // @Action(PanelActions.ClearBrandSettings)
  // clearBrandSettings({ patchState }: StateContext<PanelStateModel>) {

  // }
}
