import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss'],
})
export class TermsDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TermsDialogComponent>, private dialog: MatDialog,) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  openPrivacypolicy() {
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
      width: '65%',
      height: '500px'
    });
  }
  
}
