import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EmailServiceService {
  apiUrl = environment.api + '/api/email/';

  constructor(private http: HttpClient) {
  }

  contactUsEmail(fromName: string, fromEmail: string, emailSubject: string, emailBody: string, emailCategory: string): Observable<any> {
    return this.http.post(this.apiUrl + 'contact-us', {fromName, fromEmail, emailSubject, emailBody, emailCategory});
  }


}
