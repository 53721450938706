import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { map, take, tap } from 'rxjs/operators';
import { UserState } from '../../@core/@store/user/user.store';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardGuard implements CanActivate {
  constructor(private router: Router) {}
  @Select(UserState.Role) userRole$: Observable<any>;

  canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<boolean> | Observable<boolean> | boolean {
    return this.checkIsAdmin();
  }

  checkIsAdmin() {
    return this.userRole$.pipe(
      take(1),
      map((role: any) => {
        return role.admin_admin || false;
      }),
      tap(async (isAdmin: any) => {
        if (!isAdmin) {
          this.router.navigate(['no-access']);
          return false;
        }
        return isAdmin;
      })
    );
  }
}
