import {UserInterface} from './user.interface';

export namespace UserActions {

    export class ToggleLoadingRules {
        static readonly type = '[User] Toggle Rule Loading';

        constructor(public loading: Boolean) {
        }
    }

    export class GetMyAccessLevels {
        static readonly type = '[User] Get My Access Levels';

        constructor() {
        }
    }

    export class AddUserData {
        static readonly type = '[User] Add User data';

        constructor(public payload: UserInterface) {
        }
    }

    export class AddUserClientTree {
        static readonly type = '[User] Add User Client Tree';

        constructor(public payload: UserInterface) {
        }
    }

    export class PurgeUserData {
        static readonly type = '[User] Purge User data';

        constructor() {
        }
    }

    export class SetLandingClient {
        static readonly type = '[User] Updating Landing Client';

        constructor(public payload: any) {
        }
    }

    export class SetLandingMarket {
        static readonly type = '[User] Updating Landing Market';

        constructor(public payload: any) {
        }
    }


}
