import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyService {
  apiUrl = environment.api + '/api/creative-analysis/study';

  constructor(private http: HttpClient) {
  }

  createStudy(studyObject: any): Observable<any> {
    return this.http.post(this.apiUrl + `/`, {
      ...studyObject
    });
  }

  deleteStudy(studyId: string): Observable<any> {
    return this.http.delete(this.apiUrl + `/one/${studyId}`);
  }

  getStudies(query = '', skip = 0, limit = 10): Observable<any> {
    return this.http.get(
      this.apiUrl + `/?query=${query}&skip=${skip}&limit=${limit}`
    );
  }

  getStudyList(query = '', skip = 0, limit = 10): Observable<any> {
    return this.http.get(
      this.apiUrl + `/study-list?query=${query}&skip=${skip}&limit=${limit}`
    );
  }

  addAssetsToStudy(studyId: string, assets: any): Observable<any> {
    return this.http.post(this.apiUrl + `/${studyId}/assets`, {
      assets
    });
  }

  removeAssetsFromStudy(studyId: string, assetsToRemove): Observable<any> {
    return this.http.put(this.apiUrl + `/${studyId}/assets`, {
      assetsToRemove
    });
  }

  addManualLabelsToStudyAssets(studyId: string, labelsToAdd: any): Observable<any> {
    return this.http.post(this.apiUrl + `/${studyId}/assets/add-manual-labels`,
      labelsToAdd
    );
  }

  updateStudy(studyId: string, update: any): Observable<any> {
    return this.http.put(this.apiUrl + `/one/${studyId}`, { ...update });
  }


  // use this to get mediadata
  getMediaData(studyId: string, assetId: string): Observable<any> {
    return this.http.get(this.apiUrl + `/${studyId}/assets/one/${assetId}`);
  }

  getOneStudyAsset(studyId: string, assetId: string): Observable<any> {
    return this.http.get(this.apiUrl + `/${studyId}/assets/one/${assetId}`);
  }

  // use this to get top five assets
  getTopAssetListByStudy(
    studyId: string,
    numOfAssets: number,
    key: string
  ): Observable<any> {
    return this.http.get(
      this.apiUrl + `/get-top-assets/${studyId}/${numOfAssets}/${key}`
    );
    // /get-top-assets/:studyId/:numOfAssets/:key'
  }

  getLabelCount(studyId: string): Observable<any> {
    return this.http.get(this.apiUrl + `/get-asset-count-per-label/${studyId}`);
  }


  getSingleStudy(studyId: string): Observable<any> {
    return this.http.get(this.apiUrl + `/one/${studyId}`);
  }

  getStudyAssetList(studyId: string, limit = 24, index = 0, query = '', sortOption = 'impressions', filterBy = ''): Observable<any> {
    return this.http.get(this.apiUrl + `/${studyId}/assets` + `?limit=${limit}&skip=${index * limit}&query=${query}&sort=${sortOption}&${filterBy}`);
  }

  checkIfAssetsWithSameNameExistOnStudy(studyId: string, selectedFiles): Observable<any> {
    return this.http.post(this.apiUrl + `/${studyId}/assets/check-for-duplicates`, {
      selectedFiles
    });
  }

  getQuartileAnalysis(studyId: string, payload = {}): Observable<any> {
    return this.http.post(this.apiUrl + `/${studyId}/analysis/quartile`, payload);
  }

  getDecompAnalysis(studyId: string, payload = {}): Observable<any> {
    return this.http.post(this.apiUrl + `/${studyId}/analysis/decomp`, payload);
  }


  /// ===== media data ===== ///
  getMediaDataUploadLink(studyId: string, uid: string, fileName: string, fileType: string): Observable<any> {
    return this.http.get(
      this.apiUrl + `/${studyId}/media-data/upload-link?contentName=${fileName}&contentType=${fileType}&uid=${uid}`
    );
  }

  createStudyMediaFile(studyId: string, mediaFilePath: string, studyAssets): Observable<any> {
    return this.http.post(this.apiUrl + `/${studyId}/media-data`, {
      mediaFilePath: mediaFilePath,
      assetList: studyAssets.map(s => s._id)
    });
  }

  getStudyAssetAnalysis(studyId: string, studyAssetId: string): Observable<any> {
    return  this.http.get(this.apiUrl + `/${studyId}/assets/one/${studyAssetId}/analysis`);
  }

  /// ====== study assets by id =======///
  getStudyAssetsByLabel(studyId: string, selectedLabel: string) : Observable<any>{
    return this.http.get(this.apiUrl + `/${studyId}/assets/by-label?queryLabel=${selectedLabel}`)
  }
}
