import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'caseConvert'
})
export class CaseConvertPipe implements PipeTransform {

    // transform(value: any, ...args: any[]): any {
    //     if (!value) {
    //         return '';
    //     }
    //
    //     const lstAcronyms = this.hasAcronyms(value, 4);
    //     if (value.length <= 4 ){
    //         return value.toUpperCase();
    //     }
    //
    //     // console.log(lstAcronyms);
    //     value = value.replace(/[A-Z]/g, letter => ` ${letter[0].toUpperCase() + letter.substr(1).toLowerCase()}`)
    //     return value[0].toUpperCase() + value.substr(1);
    // }

    hasAcronyms(str, maxLength = 3, minLength = 1) {
        const lstAcronyms = [];
        let _str = String(str);
        _str = _str.replace(new RegExp('[^a-zA-Z]', 'gi'), ' ');
        _str.split(' ').forEach(element => {
            // tslint:disable-next-line: triple-equals
            if (element == element.toUpperCase() && element.length <= maxLength && element.length > minLength) {
                // commenting this out to optimise for performance from indexOf
                // const i = str.indexOf(element);
                // const j = i + element.length;
                // lstAcronyms.push({acronym: element, start: i, end: j});
                lstAcronyms.push({acronym: element});
            }
        });
        return lstAcronyms;
    }

    transform(value: any, ...args: any[]): any {
        if (!value) {
            return '';
        }
        const lstAcronyms = this.hasAcronyms(value, 4);
        let _value = String(value);
        // set to lowercase acronyms as it breaks next replaces
        lstAcronyms.forEach((acronym) => {
            _value = _value.split(acronym.acronym).join(acronym.acronym.toLowerCase());
        });
        _value = _value
            .replace(/(\_\w)/g, (m) => m[1].toUpperCase())
            .replace(/[A-Z]/g, letter => `${letter[0].toUpperCase() + letter.substr(1).toLowerCase()}`);
        // replace back to upper case - replace back
        lstAcronyms.forEach((acronym) => {
            _value = _value.split(acronym.acronym.toLowerCase()).join(acronym.acronym);
        });
        return _value[0].toUpperCase() + _value.substr(1);
    }


}
