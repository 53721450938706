import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { UserService } from '@core/core-services/user.service';
import { AppActions } from './app.actions';
import { AppStateModel } from './app.model';
import { Injectable } from '@angular/core';

@State<AppStateModel>({
  name: 'apps',
  defaults: {
    appIds: [],
    selectedApp: null,
  },
})
@Injectable()
export class AppsState {
  constructor(private userApi: UserService) {}

  @Selector()
  static getCurrentAppInformation(state: AppStateModel) {
    return state.selectedApp;
  }

  @Selector()
  static getAppList(state: AppStateModel) {
    return state && state.appIds;
  }

  @Action(AppActions.SetApps)
  setClientApps(ctx: StateContext<AppStateModel>, action: AppActions.SetApps) {
    ctx.patchState({
      ...ctx.getState(),
      appIds: action.appList,
      selectedApp: null,
    });
  }

  @Action(AppActions.SetSelectedApp)
  setSelectedApp(
    ctx: StateContext<AppStateModel>,
    action: AppActions.SetSelectedApp
  ) {
    const selectedApp = action.selectedApp;
    ctx.patchState({
      ...ctx.getState(),
      ...{ selectedApp },
    });
  }

  // @Action(AppsActions.UpdateApps)
  // updateClientApps(ctx: StateContext<AppsInterface>, action: AppsActions.UpdateApps) {
  //     const state = ctx.getState();
  //
  //
  //     const newState = this.generateGroupsAndFeatures((action.market.features as Array<any>).concat(state.clientFeatures));
  //
  //     ctx.dispatch(new AppsActions.ToggleLoading(false));
  //     ctx.patchState({
  //         ...state,
  //         ...newState,
  //         all: action.market.features,
  //         loading: false
  //     });
  //
  // }

  // generateGroupsAndFeatures(features: Array<any>): any {
  //
  //     if (features) {
  //         const groups = features.map(e => {
  //             return e.group || {};
  //         });
  //
  //         const out = [];
  //         groups.forEach(e => {
  //             if (!out.includes(e.name)) {
  //                 out.push(e.name);
  //             }
  //         });
  //         out.sort();
  //
  //         return {
  //             groups: out,
  //             features
  //         };
  //     } else {
  //         return {
  //             groups: [],
  //             features: []
  //         };
  //     }
  //
  //
  // }
}
