export class CellClass {
  backgroundColor?: string = 'white';

  //TODO(Harry White): border should be able to handle multiple inputs including color, style and width
  border?: string = 'd4d4d4';

  borderTopColor?: string = '#d4d4d4';
  borderRightColor?: string = '#d4d4d4';
  borderBottomColor?: string = '#d4d4d4';
  borderLeftColor?: string = '#d4d4d4';

  borderTopStyle?: string = 'solid';
  borderRightStyle?: string = 'solid';
  borderBottomStyle?: string = 'solid';
  borderLeftStyle?: string = 'solid';

  borderTopWidth?: number = 1;
  borderRightWidth?: number = 0;
  borderBottomWidth?: number = 0;
  borderLeftWidth?: number = 1;

  color?: string = 'black';

  fontWeight?: string = 'normal';

  format?: string = 'general';
  textAlign?: string = 'left';

  value?: number | string = 'test';
  type?: string = 'body';

  row?: number = 0;
  column?: number = 0;
  rowSpan?: number = 1;
  columnSpan?: number = 1;
  // r?: number = 0;
  // c?: number = 0;
  rSpan?: number = 1;
  cSpan?: number = 1;

  expanded?: true;

  locked?: boolean = false;

  icon?= {
    enabled: <boolean>false,
    materialIcon: <string>''
  };

  function?= {
    enabled: <boolean>false,
    key: <string>''
  };

  buttons?= [];

  constructor(cellInput: CellClass = {}) {
    Object.assign(this, cellInput);
    //TODO(Harry White): border should be able to handle multiple inputs including color, style and width
    if (cellInput.border) {
      this.borderTopColor = cellInput.border;
      this.borderRightColor = cellInput.border;
      this.borderBottomColor = cellInput.border;
      this.borderLeftColor = cellInput.border;
    }
  }
}
