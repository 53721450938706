import { NgModule } from '@angular/core';
import { AutoCompleteDirective } from '@shared/directives/directives/auto-complete.directive';
import { DigitinputterDirective } from '@shared/directives/directives/digit-inputter.directive';
import { NumberCommaDirective } from '@shared/directives/directives/number-comma.directive';
import { CopyClipboardDirective } from '@shared/directives/directives/copy-clipboard.directive';
import { DebounceDirective } from '@shared/directives/directives/debounce.directive';

const directives = [
  AutoCompleteDirective,
  DigitinputterDirective,
  NumberCommaDirective,
  CopyClipboardDirective,
  DebounceDirective,
];

@NgModule({
  declarations: [...directives],
  exports: [...directives],
})
export class DirectivesModule {}
