import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AuthenticationService } from '../../modules/auth/services/authentication.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
// import { JwtHelperService } from '@auth0/angular-jwt';
// const jwtHelper = new JwtHelperService();

import { AutoLogoutService } from '../../modules/auth/services/auto-logout.service';
import { User } from '@core/interceptors/models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnInit {
  isloggedIn: boolean;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private autoLogoutService: AutoLogoutService
  ) {}

  ngOnInit() {
    this.authenticationService.getLoginStatus().subscribe((loggedIn) => {
      this.isloggedIn = loggedIn;
    });

    // this.autoLogoutService.check();
  }

  // getUser
  getUser(): User {
    let x = localStorage.getItem('User');
    return x ? (JSON.parse(x) as User) : null;
  }

  // getDecodedUserToken() {
  //   let x = localStorage.getItem('User');
  //   return jwtHelper.decodeToken(JSON.parse(x)['token']);
  // }

  clearUser() {
    localStorage.clear();
  }

  getMe(token?: string): Observable<any> {
    if (!token) {
      let user = this.getUser();
      if (!user) {
        console.log('returning null user');
        return of();
      } else {
        token = this.getUser().token;
      }
    }
    return this.http.get(environment.api + '/api/my/?token=' + token);
  }

  validateSSOToken(validationUrl: string, token: string): Observable<any> {
    const verificationUrl =
      'https://q4esh2w5s9.execute-api.eu-west-1.amazonaws.com/default/TokenVerifcation';
    const valUrl = validationUrl.split('/');
    return this.http.get(
      verificationUrl +
        '?token=' +
        token +
        '&api=' +
        valUrl[2] +
        '&method=' +
        valUrl[3]
    );
  }

  getDefaultClient(): Observable<any> {
    return this.http.get(environment.api + '/api/my/clients');
  }

  getDefaultMarket(clientId: string): Observable<any> {
    return this.http.get(environment.api + '/api/my/markets/' + clientId);
  }

  getApps(): Observable<any> {
    // console.log('my apps called');
    return this.http.get(environment.api + '/api/my/apps');
  }

  getAdminApps(): Observable<any> {
    return this.http.get(environment.api + '/api/my/admin-apps');
  }
  getDataApps(): Observable<any> {
    return this.http.get(environment.api + '/api/my/data-apps');
  }

  // Set default client for the user
  setDefaultClient(clientId: string): Observable<any> {
    return this.http.put(environment.api + '/api/my/default-client', {
      defaultClient: clientId,
    });
  }

  setDefaultMarket(marketId: string): Observable<any> {
    return this.http.put(environment.api + '/api/my/default-market', {
      defaultMarket: marketId,
    });
  }

  getMyAccess(): Observable<any> {
    return this.http.get(environment.api + '/api/my/admin-apps');
  }
}
