import { Component, OnInit, HostListener, EventEmitter, ViewChild, Input, Output, Renderer2, ElementRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { CellClass } from './cell/cell.class';

@Component({
  selector: 'app-new-sheet',
  templateUrl: './new-sheet.component.html',
  styleUrls: ['./new-sheet.component.scss']
})
export class NewSheetComponent implements OnInit {
  @Input() options: any;
  @Input() sheet;
  cells;
  @Input() contexts: any[];
  @Input() id: string;
  @Output() sheetChange: EventEmitter<any> = new EventEmitter();
  @Output() deleteRow: EventEmitter<any> = new EventEmitter();
  @Output() contextFire: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() resetSplit: EventEmitter<any> = new EventEmitter();
  @Output() functionOutput = new EventEmitter();
  @ViewChild('floatingInput', { static: true }) floatingInput: ElementRef;
  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;

  columnWidths: Array<number> = [];
  gridTemplateColumns = '';

  contextMenuPosition: { x: string; y: string } = { x: '0px', y: '0px' };
  addRows = 10;
  select: {
    c: number;
    r: number;
    cSpan: number;
    rSpan: number;
    cStart: number;
    rStart: number;
  } = { c: -1, r: -1, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };

  dragBounds = { c: 0 - 1, r: -1, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };
  copyBounds = { c: 0, r: 0, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };
  selecting = false;
  copying = false;
  ctrlKey = false;
  cell: any;
  copyArray;
  copyingSelection = false;
  shiftKey = false;
  log = [];
  sheetActive = false;
  state = {
    editing: false
  };

  scrollWindow: boolean;
  currentRSpan = 1;
  lowerRBand = 0;
  floatingInputProperties: any = {
    top: -999,
    left: -999,
    height: 0,
    width: 0,
    value: '',
    alignment: 'right'
  };

  showObjectArray = false;
  objectArray = [];
  border = 'white';

  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event) {
    if (this.sheetActive) {
      if (!this.state.editing && event.button === 0) {
        if (this.selecting) {
          this.dragBounds = this.clone(this.select);
          this.selecting = false;
        }
        if (this.copying) {
          this.copy(this.select);
          this.dragPaste(this.dragBounds);
          this.select = this.clone(this.dragBounds);
          this.copying = false;
        }
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    this.select;
    if (this.sheetActive) {
      // keystorkes when not editing a cell
      if (!this.state.editing) {
        // none special keys
        if (!event.ctrlKey && !event.metaKey && !this.isSpecialKey(event.key)) {
          this.cell = this.renderer.selectRootElement(
            '#' + this.id + ' #cell-' + this.select.c + '-' + this.select.r,
            true
          );
          this.floatingInputProperties.top = this.cell.offsetTop;
          this.floatingInputProperties.left = this.cell.offsetLeft;
          this.floatingInputProperties.height = this.cell.offsetHeight;
          this.floatingInputProperties.width = this.cell.offsetWidth;
          this.floatingInput.nativeElement.focus();
          this.state.editing = true;
        }
        // escape key when not editing
        if (event.key === 'Escape') {
          // this.floatingInput.nativeElement.blur();
          this.copyingSelection = false;
        }
        // delete key when not editing
        if (event.key === 'Delete') {
          for (
            let i = this.select.r;
            i < this.select.r + this.select.rSpan;
            i++
          ) {
            for (
              let h = this.select.c;
              h < this.select.c + this.select.cSpan;
              h++
            ) {
              this.cells[i][h].value = '';
              this.evaluate();
            }
          }
        }
        // select all
        if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
          this.select.c = 0;
          this.select.r = 0;
          this.select.cSpan = this.cells[0].length;
          this.select.rSpan = this.cells.length - 1;
          this.dragBounds = this.clone(this.select);
          return false;
        }
        // copy
        if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
          this.copyBounds = this.clone(this.select);
          this.copyingSelection = true;
          this.copy(this.copyBounds);
          this.copyToClipboard(this.copyArray);
        }
        // paste
        if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
          if (this.copyingSelection) {
            this.paste();
          } else {
            this.pasteFromClipboard();
          }
          this.copyingSelection = false;
        }
        // down when not editing (checking to make sure selection doesnt go off the bottom)
        if (
          (event.key === 'ArrowDown' || event.key === 'Enter') &&
          this.select.r + this.select.rSpan < this.cells.length
        ) {
          event.preventDefault();
          if (event.shiftKey) {
            this.select.rSpan++;
          } else {
            const currentR = this.select.r;
            this.select.r =
              currentR + this.cells[currentR][this.select.c].rSpan;
            this.select.rSpan =
              this.cells[currentR + this.cells[currentR][this.select.c].rSpan][
                this.select.c
              ].rSpan;
            this.select.cSpan = 1;
            // this.unfocusCell();
          }
          this.dragBounds = this.clone(this.select);
        }
        // up when not editing
        if (event.key === 'ArrowUp') {
          let currentR;
          event.preventDefault();
          if (event.shiftKey) {
            if (this.select.rSpan > 1) {
              this.select.rSpan--;
            }
          } else if (this.select.r > 0) {
            currentR = this.select;
            this.select.r--;
            this.select.rSpan = 1;
            this.select.cSpan = 1;
          }
          this.dragBounds = this.clone(this.select);
          // this.unfocusCell();
        }
        // right when not editing  (checking to make sure selection doesnt go off the right)
        if (
          event.key === 'ArrowRight' &&
          this.select.c + this.select.cSpan < this.cells[0].length
        ) {
          event.preventDefault();
          if (event.shiftKey) {
            this.select.cSpan++;
          } else {
            this.select.c++;

            this.select.rSpan = 1;
            this.select.cSpan = 1;
          }
          this.dragBounds = this.clone(this.select);
          // this.unfocusCell();
        }
        // left when not editing
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          if (event.shiftKey) {
            if (this.select.cSpan > 1) {
              this.select.cSpan--;
            }
          } else if (this.select.c - 1 >= 0) {
            this.select.c--;
            this.select.rSpan = 1;
            this.select.cSpan = 1;
          }
          this.dragBounds = this.clone(this.select);
          // this.unfocusCell();
        }
      }
      // keystorkes when editing a cell
      if (this.state.editing) {
        // down when editing  (checking to make sure selection doesnt go off the bottom)
        if (
          (event.key === 'ArrowDown' || event.key === 'Enter') &&
          this.select.r + this.select.rSpan < this.cells.length
        ) {
          event.preventDefault();
          this.cells[this.select.r][this.select.c].value =
            this.floatingInput.nativeElement.value;
          this.evaluate();

          this.state.editing = false;
          this.resetFloatingInput();
          this.select.r++;
          this.select.rSpan = 1;
          this.select.cSpan = 1;
          this.dragBounds = this.clone(this.select);
        }
        //up when editing (checking to make sure selection doesnt go off the top)
        if (event.key === 'ArrowUp' && this.select.r > 0) {
          event.preventDefault();
          // if (event.shiftKey) {
          //   if (this.select.rSpan > 1) {
          //     this.select.rSpan--;
          //   }
          // } else
          if (this.select.r > 0) {
            this.cells[this.select.r][this.select.c].value =
              this.floatingInput.nativeElement.value;
            this.evaluate();

            this.resetFloatingInput();
            this.select.r--;
            this.select.rSpan = 1;
            this.select.cSpan = 1;
          }
          this.dragBounds = this.clone(this.select);
          // this.unfocusCell();
        }
        // escape when editing
        if (event.key == 'Escape') {
          this.state.editing = false;
          this.resetFloatingInput();
        }
      }
    }
  }

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.initiateSheet();
  }

  ngOnChanges() {
    this.initiateSheet();
  }

  initiateSheet() {
    if (this.options && this.sheet) {
      const cellOptions = {};
      Object.keys(this.options).forEach(key => {
        if (key !== 'sheet') {
          cellOptions[key] = this.options[key];
        }
      });
      this.cells = this.sheet.map((row, rowIndex) => {
        return row.map(
          (cell, columnIndex) =>
            new CellClass({
              ...cellOptions,
              ...cell,
              row: rowIndex,
              column: columnIndex
            })
        );
      });
      if (this.options.border) {
        this.border = this.options.border;
      }
      // this.columnWidths = this.cells[0].map(() => 65);
      this.gridTemplateColumns = 'repeat(auto-fit, minmax(65, 1fr))';
      if (this.options.columns) {
        this.gridTemplateColumns = this.options.columns.map(column => column + 'px ').join('')
      }
    }
  }

  resetFloatingInput() {
    this.state.editing = false;
    this.floatingInput.nativeElement.blur();
    this.floatingInputProperties.top = -999;
    this.floatingInputProperties.left = -999;
    this.floatingInputProperties.height = 0;
    this.floatingInputProperties.width = 0;
    this.floatingInputProperties.value = '';
  }

  isSpecialKey(key) {
    const specialKeys = [
      'Escape',
      'ArrowRight',
      'ArrowDown',
      'ArrowLeft',
      'ArrowUp',
      'Enter',
      'Delete',
      'Shift'
    ];
    return specialKeys.includes(key);
  }

  // unfocusCell() {
  //   this.floatingInput.nativeElement.blur();
  //   this.resetFloatingInput;
  //   this.state.editing = false;
  // }

  //#endregion

  escape() {
    // this.copyingSelection = false;
  }

  commitToLog() {
    this.log.push(this.clone(this.cells));
  }

  objectArrayView() {
    this.showObjectArray = true;
  }

  // mouse down on sheet
  mouseDown(e) {
    // set variables from event
    let cell;
    if (e.srcElement.parentElement.localName === 'cell') {
      cell = e.srcElement.parentElement.parentElement;
    } else {
      cell = e.srcElement;
    }
    const floatingInput = cell.id === 'floating-input';
    const selectHandle = cell.className.includes('select-handle');
    if (!floatingInput && !selectHandle) {
      const id = cell.id;
      const c = Number(id.replace('cell-', '').split('-')[0]);
      const r = Number(id.replace('cell-', '').split('-')[1]);

      const rSpan = Number(cell.style.gridRowEnd.replace('span ', ''));

      if (this.state.editing) {
        this.cells[this.select.r][this.select.c].value =
          this.floatingInputProperties.value;
        this.evaluate();
        this.resetFloatingInput();
      }

      // reset floating input
      this.cell = cell;
      this.floatingInputProperties.top = -999;
      this.floatingInputProperties.left = -999;
      this.currentRSpan = rSpan;

      this.lowerRBand = rSpan - 1 + r;
      if (
        cell.classList.contains('header-cell') ||
        cell.classList.contains('locked') ||
        cell.classList.contains('delete') ||
        (cell.classList.contains('summary-cell') &&
          !cell.classList.contains('editable'))
      ) {
        // instigate selecting
        this.select = {
          c: c,
          r: r,
          cSpan: 1,
          rSpan: this.currentRSpan,
          cStart: c,
          rStart: r
        };
        this.dragBounds = this.clone(this.select);
        this.selecting = true;
      } else {
        //editable cell
        if (e.button === 0 && !cell.classList.contains('editable')) {
          if (e.detail === 2) {
            if (cell.className.includes('object-array')) {
              if (this.cells[r][c].value.length > 0) {
                this.objectArray = this.cells[r][c].value;
              }
              // this.objectArray = sheet
              // this.objectArrayView();
              this.showObjectArray = true;
            } else {
              this.floatingInputProperties = {
                top: cell.offsetTop,
                left: cell.offsetLeft,
                height: cell.offsetHeight,
                width: cell.offsetWidth,
                value: this.clone(this.cells[r][c].value)
              };
              this.state.editing = true;
            }
          } else {
            this.select = {
              c: c,
              r: r,
              cSpan: 1,
              rSpan: this.currentRSpan,
              cStart: c,
              rStart: r
            };
            this.dragBounds = this.clone(this.select);
            this.selecting = true;
          }
        }
      }
    }
  }

  mouseOver(e) {
    if (!this.state.editing) {
      // set variables from event
      let cell;
      if (e.srcElement.parentElement.localName === 'cell') {
        cell = e.srcElement.parentElement.parentElement;
      } else {
        cell = e.srcElement.parentElement;
      }

      const id = cell.id;
      const skip = cell.className.includes('skip');
      if (!skip) {
        const c = Number(id.replace('cell-', '').split('-')[0]);
        const r = Number(id.replace('cell-', '').split('-')[1]);

        if (this.selecting) {
          // moving left
          if (this.select.cStart >= c) {
            this.select.c = c;
            this.select.cSpan = this.select.cStart - c + 1;
            // moving right
          } else {
            this.select.cSpan = c - this.select.cStart + 1;
          }
          // moving up
          if (this.select.rStart >= r) {
            this.select.r = r;
            this.select.rSpan = this.select.rStart - r + 1;
            // moving down
          } else {
            this.select.rSpan = r - this.select.rStart + 1;
          }
          // check if over merge
          const tempArr = [];
          for (
            let h = this.select.c;
            h < this.select.c + this.select.cSpan;
            h++
          ) {
            tempArr.push([]);
            for (
              let i = this.select.r;
              i < this.select.r + this.select.rSpan;
              i++
            ) {
              if (this.cells[i][h].rSpan >= 1) {
                tempArr[tempArr.length - 1].push({
                  r: i,
                  c: h,
                  ...this.cells[i][h]
                });
              }
              if (this.cells[i][h].rSpan === 0) {
                let i2 = i;
                while (this.cells[i2][h].rSpan === 0) {
                  i2--;
                }
                tempArr[tempArr.length - 1].push({
                  r: i2,
                  c: h,
                  ...this.cells[i2][h]
                });
              }
            }
          }
          let low = Math.pow(10, 6);
          let high = 0;
          const rSpanArray = [];
          tempArr.forEach(i => {
            const tempTempArr = [];
            i.forEach(h => {
              let inArr = false;
              if (h.r < low) {
                low = h.r;
              }
              tempTempArr.forEach(h2 => {
                if (h.c === h2.c && h.r === h2.r) {
                  inArr = true;
                }
              });
              if (!inArr) {
                tempTempArr.push(h);
              }
            });
            i = tempTempArr;
            rSpanArray.push(i.map(a => a.rSpan).reduce((a, b) => a + b));
          });
          rSpanArray.forEach(a => {
            if (a > high) {
              high = a;
            }
          });
          this.select.r = low;
          this.select.rSpan = high;
          this.dragBounds = this.clone(this.select);
        }
        if (this.copying) {
          if (this.dragBounds.rStart >= r) {
            this.dragBounds.r = r;
            this.dragBounds.rSpan = this.dragBounds.rStart - r + 1;
          } else {
            this.dragBounds.rSpan = r - this.dragBounds.rStart + 1;
          }
        }
      }
    }
  }

  mouseUp(e) {
    if (!this.state.editing) {
      if (this.selecting) {
        this.dragBounds = this.clone(this.select);
        this.selecting = false;
      }
      if (this.copying) {
        this.copy(this.select);
        this.dragPaste(this.dragBounds);
        this.select = this.clone(this.dragBounds);
        this.copying = false;
      }
    }
  }

  handleDown(c, r) {
    this.copying = true;
  }

  sheetOut() {
    this.selecting = false;
    this.copying = false;

    this.dragBounds = this.clone(this.select);
  }

  // buttons
  add() {
    const cell = { value: '', formula: '', formatting: [] };
    const row = [];
    this.cells[0].forEach(element => {
      row.push(cell);
    });
    for (let i = 0; i < this.addRows; i++) {
      this.cells.push(row);
    }
  }

  inputKey(e, cell) {
    if (e.target.value[0] === '=') {
      cell.formula = e.target.value;
    } else {
      cell.formula = '';
    }
  }

  evaluate() {
    this.sheetChange.emit(this.cells);
  }

  onContextMenu(event: MouseEvent, item) {
    if (this.contexts && this.contexts.length > 0) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { item: item };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  onContextMenuAction(item, key) {
    // if (key === 'delete') {
    // this.deleteRow.emit(item.r);
    this.contextFire.emit({ key: key, c: item.c, r: item.r });
    // }
  }

  onContextMenuAction1(item) {
    this.copyBounds = this.clone(this.select);
    this.copyingSelection = true;
    this.copy(this.copyBounds);
    this.copyToClipboard(this.copyArray);
  }

  onContextMenuAction2(item) {
    if (this.copyingSelection) {
      this.paste();
    } else {
      this.pasteFromClipboard();
    }
    this.copyingSelection = false;
  }

  onContextMenuAction3(item) {
    const insert = [];
    for (let i = 0; i < this.select.rSpan; i++) {
      insert.push([
        { value: '', formula: '', formatting: [] },
        { value: '', formula: '', formatting: [] },
        { value: '', formula: '', formatting: [] }
      ]);
    }
    this.cells.splice(this.select.r, 0, ...insert);
  }

  // scroll
  scrollEnter() {
    this.scrollWindow = true;
    this.scroll(2);
  }

  scroll(speed) {
    if (this.scrollWindow) {
      const pos = window.pageYOffset;
      window.scrollTo(0, pos + speed);
      speed *= 1.002;
      setTimeout(() => {
        this.scroll(speed);
      }, 1);
    }
  }

  scrollLeave() {
    this.scrollWindow = false;
  }

  // copy and paste
  copy(bounds) {
    this.copyArray = [];
    for (let i = bounds.r; i < bounds.r + bounds.rSpan; i++) {
      const row = [];
      for (let h = bounds.c; h < bounds.c + bounds.cSpan; h++) {
        row.push(this.cells[i][h]);
      }
      this.copyArray.push(row);
    }
  }

  dragPaste(bounds) {
    let row = 0,
      column = 0;
    for (let i = bounds.r; i < bounds.r + bounds.rSpan; i++) {
      if (!(i >= this.select.r && i <= this.select.r + this.select.rSpan - 1)) {
        for (let h = bounds.c; h < bounds.c + bounds.cSpan; h++) {
          if (
            !(i >= this.select.r && i <= this.select.r + this.select.rSpan - 1)
          ) {
            this.cells[i][h] = JSON.parse(
              JSON.stringify(this.copyArray[row][column])
            );
            column = this.cap(column + 1, this.copyArray[row].length);
          }
        }
        row = this.cap(row + 1, this.copyArray.length);
      }
    }
    this.sheetChange.emit(this.cells);
  }

  paste() {
    if (this.select.rSpan > 1 || this.select.cSpan > 1) {
      let r, c;
      r = 0;
      for (let i = this.select.r; i < this.select.rSpan + this.select.r; i++) {
        c = 0;
        for (
          let h = this.select.c;
          h < this.select.cSpan + this.select.c;
          h++
        ) {
          this.cells[i][h].value = this.copyArray[r][c].value;
          c = this.cap(c + 1, this.copyArray[0].length);
        }
        r = this.cap(r + 1, this.copyArray.length);
      }
    } else {
      let rSpan, cSpan;
      rSpan = 0;
      for (let i = 0; i < this.copyArray.length; i++) {
        cSpan = 0;
        if (i + this.select.r < this.cells.length) {
          for (let h = 0; h < this.copyArray[i].length; h++) {
            if (h + this.select.c < this.cells[0].length) {
              this.cells[i + this.select.r][h + this.select.c].value =
                this.copyArray[i][h].value;
              cSpan++;
            }
          }
          rSpan++;
        }
      }
      this.select.rSpan = rSpan;
      this.select.cSpan = cSpan;
      this.dragBounds = this.clone(this.select);
    }
    this.sheetChange.emit(this.cells);
  }

  pasteFromClipboard() {
    navigator.clipboard.readText().then(text => {
      this.copyArray = [];
      const rows = text.split(/\r?\n/);
      rows.forEach(row => {
        this.copyArray.push(
          row.split('\t').map(c => {
            return { value: c };
          })
        );
      });
      this.paste();
    });
    this.sheetChange.emit(this.cells);
  }

  sheetMouseEnter() {
    this.sheetActive = true;
  }

  sheetMouseLeave() {
    this.sheetActive = false;
    this.copyingSelection = false;
    this.select = {
      c: -1,
      r: -1,
      cSpan: 1,
      rSpan: 1,
      cStart: 0,
      rStart: 0
    };
    this.dragBounds = {
      c: 0 - 1,
      r: -1,
      cSpan: 1,
      rSpan: 1,
      cStart: 0,
      rStart: 0
    };
    this.copyBounds = { c: 0, r: 0, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };
    this.resetFloatingInput();
  }

  deleteBrand(r) {
    this.delete.emit(r);
  }

  updateCheck() {
    this.sheetChange.emit(this.cells);
  }

  // reset splits
  reset(c) {
    this.resetSplit.emit(c);
  }

  clone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  cap(value, cap) {
    return value - Math.floor(value / cap) * cap;
  }

  copyToClipboard(array) {
    let str = '';
    array.forEach((row, i, iArray) => {
      row.forEach((column, h, hArray) => {
        if (column.value.length > 0) {
          if (column.value.includes(',')) {
            str = str + '"' + column.value + '"';
          } else {
            str = str + column.value;
          }
        } else {
          str = str + column.value;
        }
        if (hArray.length - 1 !== h) {
          str += '\t';
        }
      });
      if (iArray.length - 1 !== i) {
        str += '\n';
      }
    });
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  // toggle category expanded
  toggleExpand(cell, e) {
    this.cells
      .filter(r => {
        return r[0].category === cell.category;
      })
      .forEach(r => {
        r.forEach(c => {
          c.expanded = !c.expanded;
        });
      });
    this.sheetChange.emit(this.cells);
  }

  // expand category rows
  expandAll(state) {
    this.cells.forEach(r => {
      r.forEach(c => {
        c.expanded = state;
      });
    });
    this.sheetChange.emit(this.cells);
  }

  cellFunction(event) {
    this.functionOutput.emit(event);
  }
}
