import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router){}
  canActivate() 
  {
    if (localStorage.getItem('User')) {
      // logged in so return true
      return true;
    }


    // Check if
    // not logged in so redirect to login page
    this.router.navigate(['auth' , 'login']);
    return true;
  }
}



