import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {NavigationHelperService} from '@shared/ui-components/navigation/breadcrumbs/navigation-helper.service';

export interface DeactivationGuarded {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<unknown> {

  constructor(
    private navigationHelperService: NavigationHelperService
  ) {}

  // canDeactivate(component: DeactivationGuarded): boolean | Observable<boolean> | Promise<boolean> {
  //   if (!this.navigationHelperService.getrouterWithUnsavedChanges() && component.canDeactivate) {
  //     return component.canDeactivate();
  //   }

  //   return component.canDeactivate ? component.canDeactivate() : true;
  // }

  canDeactivate(component: DeactivationGuarded): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.navigationHelperService.getrouterWithUnsavedChanges()) {
      return true;
    }

    return component.canDeactivate ? component.canDeactivate() : true;
  }
  
}
