import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: any, format: string) {
    if (format == '0.00') {
      return this.decimalPipe.transform(value, '1.2-2');
    } else if (format == '0%') {
      return Math.round(Number(value)) + '%';
    } else if (format == '0.00%') {
      const number = Math.round(Number(value) * 10000) / 100;
      return number.toFixed(2) + '%';
    } else if (format == '0,000') {
      return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 0
      }).format(Math.round(Number(value)));
    } else {
      return value;
    }
  }
  // round(value, n) {
  //   console.log(value, n);
  //   return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
  // }
}
