import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { User } from '@core/interceptors/models/user';
import { AuthenticationService } from './modules/auth/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeAnimation } from './app-routing-animations';
import { GlobalsService } from '@core/core-services/globals.service';
import { UserService } from '@core/core-services/user.service';
import { AppsState } from '@core/@store/app/app.store';
import { Select, Store } from '@ngxs/store';
import { AppActions } from '@core/@store/app/app.actions';
import { StateClear } from 'ngxs-reset-plugin';
import { UserActions } from '@core/@store/user/user.actions';
import { UserState } from '@core/@store/user/user.store';
import { LoadingService } from '@core/services/loading.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { TermsDialogComponent } from './modules/auth/components/terms-dialog/terms-dialog.component';
import { AppTitleService } from '@core/core-services/title/title.service';
import { ContactDialogComponent } from './modules/auth/components/contact-dialog/contact-dialog.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '@shared/components/custom-snack-bar/custom-snack-bar.component';
import { ProgressState } from '@core/@store/progress-state/progress-state.store';
import { ProgressStateActions } from '@core/@store/progress-state/progress-state.actions';
import SetProgressStateDisplayState = ProgressStateActions.SetProgressStateDisplayState;
import { PrivacyPolicyComponent } from './modules/auth/components/privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(UserState.Role) role$: Observable<any>;
  @Select(UserState.getCurrentUser) user$: Observable<any>;
  @Select(UserState.getUserClientTree) userClientTree$: Observable<any>;
  @Select(UserState.getUserPicture) userPicture$: Observable<any> | undefined;

  title = 'app';
  isloggedIn = false;
  opened = false;

  user: User;
  clients: any;
  markets: any;
  apps: any = [];
  adminApps: any = [];

  client: any = null;
  market: any = null;
  appGroups: [any];
  clientTree: any = null;
  landingClient: string;
  landingMarket: string;

  isIframed: boolean;
  contextCandidate: any;

  progressState: any;

  subs: any = [];

  userPicture = 'assets/icons/icon-person.svg';

  @Select(AppsState.getCurrentAppInformation) currentApp$;
  @Select(ProgressState.getCurrentPanelSettings) progressState$;

  public selectedClient: FormControl = new FormControl();
  public clientName: FormControl = new FormControl();
  public filteredClients: ReplaySubject<[]> = new ReplaySubject<[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  setClient = new BehaviorSubject<boolean>(false);

  constructor(
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    private router: Router,
    private userSvc: UserService,
    private userService: UserService,
    public gs: GlobalsService,
    private store: Store,
    public loadingService: LoadingService,
    private dialog: MatDialog,
    private customSnackBar: MatSnackBar,
    public appTitleService: AppTitleService
  ) {
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.subs.forEach((sub: any) => sub.unsubscribe());
  }

  public getRouterOutletState(outlet) {
    // return outlet?.activatedRouteData;
    return false;
  }

  ngOnInit() {


    const self = this;
    const config = new MatSnackBarConfig();
    config.panelClass = ['info-snackbar'];

    this.isIframed = window.location !== window.parent.location;
    this.user$.subscribe((user) => {
      this.user = user;
      this.getUserDetails();
    });
    this.userClientTree$.subscribe((clientTree) => {
      if (clientTree) {
        this.clientTree = clientTree;
        this.landingClient = clientTree.landingClient;
        this.landingMarket = clientTree.landingMarket;
        if (clientTree && clientTree.clients) {
          this.client = clientTree.clients.filter(
            (c) => c._id === clientTree.landingClient
          )[0];
          this.setClient.next(true);
          this.filteredClients.next(this.clientTree.clients.slice());
        }
      }
    });
    this.setClient.pipe(take(2)).subscribe((res) => {
      if (res) {
        this.selectedClient.setValue(this.client);
      }
    });
    window.addEventListener('dragover', (e) => {
      if (!self.hasClass(e.target, 'box-file')) {
        e.preventDefault();
      }
    }, false);
    window.addEventListener('drop', (e) => {
      if (!self.hasClass(e.target, 'box-file')) {
        e.preventDefault();
      }
    }, false);

    this.clientName.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterClient();
      });
    this.selectedClient.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res) {
          this.landingClient = res._id;
          this.updateDefaultClient();
          this.router.navigate(['/vaa']);
        }
      });

    this.store.dispatch(new SetProgressStateDisplayState({ isOpen: false, job: {
        status: '',
        stage: ''
      } }));
    this.progressState$.subscribe(res => {
      this.progressState = res;
      if (res && res.isOpen) {
        this.customSnackBar.openFromComponent(CustomSnackBarComponent, {
          data: {
            message: res.job ? res.job.status : 'null',
            stage: res.job ? res.job.stage : 'null'
          },
          panelClass: 'info-snackbar'
        });
      } else {
        this.customSnackBar.dismiss();
        // this.customSnackBar.ngOnDestroy();
      }
    });

    this.subs.push(
      this.userPicture$?.subscribe((picture) => {
        if (picture) {
          this.userPicture = picture;
        }
      })
    );
  }

  filterClient() {
    if (!this.clientTree.clients) {
      return;
    }
    let search = this.clientName.value;
    if (!search) {
      this.filteredClients.next(this.clientTree.clients.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredClients.next(
      this.clientTree.clients.filter(
        (client) => client.clientName.toLowerCase().indexOf(search) > -1
      )
    );
  }

  getUserDetails(fromInit?: boolean) {
    this.userSvc.getMe().subscribe((user) => {
      if (user) {
        this.isloggedIn = true;
        this.store.dispatch(
          new UserActions.AddUserClientTree(user.response.access)
        );
        this.store.dispatch(new UserActions.GetMyAccessLevels());
        // this.setupApps();
      } else {
        this.logOut();
      }
    });
  }

  clientMarkets() {
    return this.clientTree.clients.filter(
      (c) => c._id === this.landingClient
    )[0].markets;
  }

  setupClient() {
    this.gs.setGlobalClient(this.clients);
    this.userSvc.getDefaultClient().subscribe((clients) => {
      this.clients = clients;
      if (this.contextCandidate && this.clients.selectedClient.id) {
        this.clients.selectedClient = this.clients.clientList
          .filter((c) => c.code === this.contextCandidate.clientSlug)
          .shift();
        this.updateDefaultClient();
        return 0;
      }
      if (this.clients.selectedClient.id) {
        this.gs.setGlobalClient(this.clients);
        this.setupMarket();
      }
    });
  }

  setupMarket() {
    this.userSvc
      .getDefaultMarket(this.clients.selectedClient.id)
      .subscribe((markets) => {
        this.markets = markets;
        this.gs.setGlobalMarket(this.markets);
        this.router.navigate(['/']);
        this.setupNavBar();
      });
  }

  setupNavBar() {
    this.gs.apps$.subscribe((apps) => {
      this.apps = apps ?? [];
      // TODO: nagivate to root is not a solution as it refreshes view
      //  and it's a hassle; we need to tigger the same on changing CLIENT or COUNTRY
      // this.router.navigate(['/']);
    });
  }

  updateDefaultClient() {
    this.userSvc.setDefaultClient(this.landingClient).subscribe((response) => {
      this.store.dispatch(new UserActions.SetLandingClient(this.landingClient));
      this.client = this.clientTree.clients.filter(
        (c) => c._id === this.landingClient
      )[0];
      // is this a client change
      if (
        this.clientMarkets()
          .map((x) => x._id)
          .indexOf(this.landingMarket) === -1
      ) {
        this.landingMarket = this.clientMarkets()[0]._id;
      }
      this.updateDefaultMarket();
    });
  }

  updateDefaultMarket() {
    this.userSvc.setDefaultMarket(this.landingMarket).subscribe((response) => {
      this.store.dispatch(new UserActions.SetLandingMarket(this.landingMarket));
      this.setupApps();
      this.router.navigate(['/vaa']);
    });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1._id === c2._id : c1 === c2;
  }

  logOut() {
    this.user = null;
    this.userSvc.clearUser();
    this.clients = null;
    this.markets = null;
    this.apps = [];
    this.landingClient = '';
    this.landingMarket = '';
    this.isloggedIn = false;

    this.store.dispatch(new StateClear()).subscribe(() => {
      // this.gs.setGlobalApps(this.apps);
      this.authenticationService.updateLoginStatus(false);
      this.router.navigate(['auth', 'logout']);
    });
  }

  openTermsDialog() {
    const dialogRef = this.dialog.open(TermsDialogComponent, {
      width: '65%',
      height: '500px'
    });
  }

  openPrivacypolicy() {
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
      width: '65%',
      height: '500px'
    });
  }

  setupApps() {
    if ((this.landingClient && this.landingMarket) || true) {
      this.userService.getApps().subscribe((appGroups) => {
        this.appGroups = appGroups.apps.map((x) => {
          const grp = x;
          grp['grp'] = x['group']['name'];
          return grp;
        });
        this.store
          .dispatch(new AppActions.SetApps(appGroups.apps))
          .subscribe(() => {
            this.gs.setGlobalApps(this.appGroups);
            // this.router.navigate(['/']);
          });
      });
    }
  }

  openContact() {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '420px',
      height: '480px',
      data: {
        title: 'Contact Us'
      }
    });
  }

  hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
  }

  openSnackbar() {
    this.store.dispatch(new SetProgressStateDisplayState({ isOpen: true })).subscribe((res) => {

    });


  }
}
