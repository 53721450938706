<div class="spreadsheet" [style.grid-template-columns]="gridTemplateColumns|safe:'style'"
    [style.grid-template-rows]="'0px minmax(30px, 1fr) repeat(auto-fit, 30px)'|safe:'style'"
    (mouseenter)="sheetMouseEnter()" (mousedown)="mouseDown($event)" (mouseup)="mouseUp($event)"
    (mouseover)="mouseOver($event)" (mouseleave)="sheetMouseLeave()"
    [ngStyle]="{'border-right':'1px solid '+border,'border-bottom':'1px solid '+border}">
    <ng-container *ngIf="cells">

        <ng-container *ngFor="let row of cells let r = index; let even = even">

            <ng-container *ngFor="let column of row; let c = index">
                <div class="cell-wrapper" [id]="'cell-'+c+'-'+r" (contextmenu)="onContextMenu($event,{c:c,r:r})"
                    [style.grid-area]="(r+2)+' / '+(c+1)+' / span 1 / span 1' | safe:'style'"
                    [ngClass]="{'sticky-horizontal':column.sticky ==='horizontal','sticky-vertical':column.sticky ==='vertical'}">
                    <cell [cellInput]="column" (functionOutput)="cellFunction($event)"></cell>
                </div>
                <!-- <div #cell class="cell" *ngIf="column.rSpan!=0" [id]="'cell-'+c+'-'+r" [ngClass]="{'number-value': 
                column.type==='percent'|| column.noneInput,
                'check-input': column.type==='checkbox',
                'header-cell': column.type==='header',
                'delete': column.type==='delete',
                'locked':column.locked,'even':even,
                'th':r===0,
                'number-cell':column.type==='number',
                'center':column.type==='checkbox',
                'summary-cell':column.summary===true,
                'edited-cell':column.editedSpend===true,
                'percent-cell':column.percent===true,
                'red':column.red,
                'editable':column.editable,
                'combined':column.combined,
                'sticky1':column.sticky1,
                'sticky2':column.sticky2,
                'sticky3':column.sticky3,
                'no-sales':column.noSales,
                'object-array':column.objectArray?.length>0,
                'row-1':column.r==0
            }">
                    <mat-icon class="reset" *ngIf="column.red" (click)="reset(r)">
                        refresh
                    </mat-icon>
                    <ng-container *ngIf="column.type==='header' && !column.expandAll">{{column.value}}</ng-container>
                    <ng-container *ngIf="column.locked && !column.noneInput">{{column.value | number}}</ng-container>
                    <ng-container *ngIf="column.type==='delete'">
                        <mat-icon class="right chart-option" aria-hidden="false" (click)="deleteBrand(r-1)">
                            delete_outline
                        </mat-icon>
                    </ng-container>
                    <ng-container *ngIf="column.type==='string' && !column.locked">{{column.value}}</ng-container>
                    <ng-container *ngIf="column.type==='number' && !column.noneInput && !column.locked && column.comma">
                        {{column.value | number}}</ng-container>
                    <ng-container
                        *ngIf="column.type==='number' && !column.noneInput && !column.locked && !column.comma">
                        {{column.value}}</ng-container>
                    <ng-container *ngIf="column.type==='percent'">{{column.value*100 | number:'1.2-2'}}%</ng-container>
                    <ng-container *ngIf="column.noneInput">{{column.value | number:'1.0-0'}}</ng-container>
                    <mat-icon (click)="toggleExpand(column,$event)"
                        *ngIf="column.type==='checkbox'&&column.summary&&column.expanded">
                        remove_circle
                    </mat-icon>
                    <mat-icon (click)="toggleExpand(column,$event)"
                        *ngIf="column.type==='checkbox'&&column.summary&&!column.expanded">
                        add_circle
                    </mat-icon>
                    <div *ngIf="column.type==='green'&&column.summary" class="green-light"></div>
                    <div *ngIf="column.type==='amber'&&column.summary" class="amber-light"></div>
                    <ng-container *ngIf="column.type==='header' && column.expandAll">
                        <div class="side-by-side">
                            <mat-icon (click)="expandAll(false)">
                                remove_circle
                            </mat-icon>
                            <mat-icon (click)="expandAll(true)">
                                add_circle
                            </mat-icon>
                        </div>
                    </ng-container>
                </div> -->
            </ng-container>
        </ng-container>
        <div class=" select-bounds" *ngIf="sheetActive && select.r>=0 && select.c>=0 "
            [style.grid-area]="(select.r+2)+' / '+(select.c+1)+' / span '+select.rSpan+' / span '+select.cSpan | safe:'style'">
        </div>
        <div *ngIf="select.rSpan>1 && sheetActive && select.r>=0 && select.c>=0 " class="select-mask"
            [style.grid-area]="(select.r+3)+' / '+(select.c+1)+' / span '+(select.rSpan-1)+' / span '+select.cSpan | safe:'style'">
        </div>
        <div *ngIf="select.cSpan>1 &&sheetActive && select.r>=0 && select.c>=0 " class="select-mask"
            [style.grid-area]="(select.r+2)+' / '+(select.c+2)+' / span 1 / span '+(select.cSpan-1) | safe:'style'">
        </div>
        <div class="drag-bounds skip" *ngIf="sheetActive && select.r>=0 && select.c>=0 "
            [style.grid-area]="(dragBounds.r+2)+' / '+(dragBounds.c+1)+' / span '+dragBounds.rSpan+' / span '+dragBounds.cSpan | safe:'style'">
        </div>
        <div *ngIf="copyingSelection" class="copy-bounds marching-ants"
            [style.grid-area]="(copyBounds.r+2)+' / '+(copyBounds.c+1)+' / span '+copyBounds.rSpan+' / span '+copyBounds.cSpan | safe:'style'">
        </div>
    </ng-container>
    <input #floatingInput id="floating-input" class="skip" [(ngModel)]="floatingInputProperties.value"
        [ngStyle]="{'text-align':floatingInputProperties.alignment,'top':floatingInputProperties.top+'px','left':floatingInputProperties.left+'px','width':floatingInputProperties.width-2+'px','height':floatingInputProperties.height-2+'px'}"
        autocomplete="off">
</div>
<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button *ngFor="let context of contexts" mat-menu-item
            (click)="onContextMenuAction(item,context.key)">{{context.name}}</button>
    </ng-template>
</mat-menu>
<div id="drag-handler" (mouseenter)="scrollEnter()" (mouseleave)="scrollLeave()"></div>
<div class="object-array-view" *ngIf="showObjectArray">
</div>