import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CompetitiveStudyModel } from '@core/@store/competetive-analysis/competitive-study.model';
import { CompetitiveStudyActions } from '@core/@store/competetive-analysis/competitive-study.actions';

@State<CompetitiveStudyModel>({
  name: 'competitiveStudy',
  defaults: {
    competitiveModel: {
      id: '',
      MosaicJsonPath: '',
      MosaicPath: '',
      GalaxyJsonPath: '',
      hasGalaxy: false
    }
  }
})
@Injectable()
export class CompetitiveStudyState {
  constructor() {
  }

  @Selector()
  static getCurrentStudy(state: CompetitiveStudyModel) {
    return state && state.competitiveModel;
  }


  @Action(CompetitiveStudyActions.SetCurrentStudy)
  addUserData(
    { patchState }: StateContext<CompetitiveStudyModel>,
    { payload }: CompetitiveStudyActions.SetCurrentStudy
  ) {
    patchState({
      competitiveModel: {
        id: payload.id,
        MosaicJsonPath: payload.MosaicJsonPath,
        MosaicPath: payload.MosaicPath,
        GalaxyJsonPath: payload.GalaxyJsonPath,
        hasGalaxy: payload.hasGalaxy
      }
    });
  }
}
