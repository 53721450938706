export namespace RuleActions {
    export class CreateRule {
        static readonly type = '[Admin] Create Rule';

        constructor(public rule: any) {
        }
    }

    export class GetRules {
        static readonly type = '[Admin] Get Rules';

        constructor() {
        }
    }

    export class ToggleRuleState {
        static readonly type = '[Admin] Toggle Rule State';

        constructor(public rule_id: string, public state: boolean) {
        }
    }
}