import { Component, OnInit } from '@angular/core';
import { OptimumAdminService } from '@core/services/optimum-admin.service';
import { Params, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css'],
})
export class LinksComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private optimumAdminService: OptimumAdminService
  ) {}

  frameLink: string;

  ngOnInit(): void {
    this.getRouteData();
  }

  getRouteData(): void {
    this.route.params.subscribe((params: Params) => {
      this.optimumAdminService
        .getCustomFeature(params['featureId'])
        .subscribe((res) => {
          this.frameLink = res.response.link;
        });
    });
  }
}
