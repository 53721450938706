import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-no-access',
    templateUrl: './no-access.component.html',
    styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    goHome(): void {
        this.router.navigate(['/']);
    }

}
