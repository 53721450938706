import { NgModule } from '@angular/core';
import { DarkBoxComponent } from './dark-box/dark-box.component';
import { ContainerComponent } from './container/container.component';
import { BreadcrumbsComponent } from './navigation/breadcrumbs/breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataLabelComponent } from './misc/data-label/data-label.component';
import { TagsComponent } from './misc/tags/tags.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ButtonComponent } from './buttons/button/button.component';
import { MaterialModule } from '../material.module';
import { TextLinkComponent } from './buttons/text-link/text-link.component';
import { TextTruncatedComponent } from '@shared/ui-components/text-truncated/text-truncated.component';
import { SpinnerComponent } from '@shared/ui-components/spinner/spinner.component';

@NgModule({
  imports: [CommonModule, RouterModule, NgxTippyModule, MaterialModule],
  declarations: [
    SpinnerComponent,
    DarkBoxComponent,
    ContainerComponent,
    BreadcrumbsComponent,
    DataLabelComponent,
    TagsComponent,
    ButtonComponent,
    TextLinkComponent,
    TextTruncatedComponent,
  ],
  exports: [
    SpinnerComponent,
    DarkBoxComponent,
    ContainerComponent,
    BreadcrumbsComponent,
    DataLabelComponent,
    TagsComponent,
    ButtonComponent,
    TextLinkComponent,
    TextTruncatedComponent,
  ],
})
export class UiComponentsModule {}
