import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCommas'
})
export class RemoveCommasPipe implements PipeTransform {

    transform(value: string): string {
        if (value !== undefined && value !== null) {
            return value.replace(/,/g, "");
        } else {
            return "";
        }
    }

}
