<div *ngIf="!loading">
    <div class="appGroup" *ngIf="appGroups">
        <mat-tab-group dynamicHeight class="appGroup__tabs" [(selectedIndex)]="selectedTab">
            <mat-tab *ngFor="let item of appGroups" [label]="item.key">
                <div class="appGroup__group">
                    <div class="appGroup__single" *ngFor="let feature of item.value">
                        <a class="appGroup__link" *ngIf="!feature.isExternal" [routerLink]="feature.route">
                            <div>
                                <div class="appGroup__icon">
                                    <img class="appGroup__img" [src]="feature.logo.iconData | safe : 'svg'" />
                                </div>
                                <p class="appGroup__title">{{feature.featureName}}</p>
                                <div class="appGroup__description"></div>
                            </div>
                            <div class="appGroup__arrow">
                                <div class="appGroup__arrowImgContainer">
                                    <span class="eva eva-arrow-forward-outline text-h5 align-middle"></span>
                                    <!-- <img class="appGroup__arrowImg" src="../../assets/icons/home-arrow.svg" /> -->
                                </div>
                            </div>
                        </a>

                        <a *ngIf="feature.isExternal && feature.categroy =='APP'"
                            [routerLink]="[ '/app', feature._id ]">
                            <div>
                                <div class="appGroup__icon">
                                    <img class="appGroup__img" [src]="feature.logo.iconData | safe : 'svg'" />
                                </div>
                                <p>{{feature.featureName}}</p>
                                <div class="appGroup__description">
                                </div>
                            </div>
                            <div class="appGroup__arrow">
                                <div class="appGroup__arrowImgContainer">
                                    <span class="eva eva-arrow-forward-outline text-h5 align-middle"></span>
                                    <!-- <img class="appGroup__arrowImg" src="../../assets/icons/home-arrow.svg" /> -->
                                </div>
                            </div>
                        </a>

                        <a class="appGroup__link" *ngIf="feature.isExternal && feature.categroy =='LINK'"
                            [href]="feature.link | safe: 'url'" target="_blank">
                            <div>
                                <div class="appGroup__icon">
                                    <img class="appGroup__img" [src]="feature.logo.iconData | safe : 'svg'" />
                                </div>
                                <p>{{feature.featureName}}</p>
                                <div class="appGroup__description">
                                </div>
                            </div>
                            <div class="appGroup__arrow">
                                <div class="appGroup__arrowImgContainer">
                                    <span class="eva eva-arrow-forward-outline text-h5 align-middle"></span>
                                    <!-- <img class="appGroup__arrowImg" src="../../assets/icons/home-arrow.svg" /> -->
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div *ngIf="!appGroups || !appGroups.length" class="flex justify-center text-center w-full no-clients">
        <div>
            <div>
                <div class="mb-8">
                    <span *ngIf="!client || !market" class="small-font"> Seems like you don't have access to any client.
                    </span> <br />
                    <span *ngIf="(client && market) && (!appGroups || !appGroups.length)">
                        No apps have been enabled for this client.</span>
                    <br />
                    <span *ngIf="!appGroups || !appGroups.length || !client || !market"> Please try
                        logging in again or contact <a
                            href="mailto:support@publicismmedia.ai">support@publicismedia.ai</a>
                    </span>
                </div>
                <app-button (click)="login()">Sign in</app-button>
            </div>
        </div>
    </div>

</div>
<div *ngIf="loading">
    <app-spinner></app-spinner>
</div>