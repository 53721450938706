import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@shared/helpers/breadcrumb.service';
import { LoadingService } from '@core/services/loading.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  subscriptions = [];
  breadcrumbs: { label: string, url: string }[] = [];

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs) => {
        this.breadcrumbs = breadcrumbs;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  navigateTo(url: string): void {
    this.breadcrumbService.setClickedBreadcrumbRoute(url);
    // this.router.navigateByUrl(url);
  }
}
