
<div>
    <span class="eva eva-close-outline" (click)="dialogRef.close()"></span>
    <div class="content">
      <div class="text-center"><h2>Visual Asset Analyser Privacy Policy</h2></div>
  
      <div>
        <h3>Introduction</h3>
  
        <p>
          Any data collected through this Website,including personal data (as
          defined under applicable data protection laws, referred to as “Personal
          Data”), will only be used for the purposes set out in this Privacy
          Notice. Not with standing, we may use information tha tdoes not identify
          you (including information that has been aggregated or de-identified)
          for any purpose except as prohibited by applicable law. Please read this
          Privacy Notice thoroughly.
          <b
            >If you disagree with the way personal information will be used,
            please do not use this Website.</b
          >
          We may include links to other websites,including social media platforms.
          This PrivacyNotice only covers this Website and does notapply to the
          data practices of other websites.We encourage you to read the privacy
          notices available on other websites you visit.
        </p>
      </div>
  
      <div>
        <h3>How long do we keep your information?</h3>
  
        <div>
          We keep Personal Data for as long as is necessary for the purposes
          described in this Privacy Notice, complying with legal and regulatory
          obligations, protecting our or other’s interests, and as otherwise
          permitted or required by law. When Personal Data is no longer necessary
          for or compatible with these purposes, it is removed from our systems in
          accordance with our internal retention policies. The criteria used to
          determine our retention periods include:
          <div>
              <ol>
                  <li>● &nbsp;
                    The length of time we have an ongoing relationship with you and
                    provide the Website or our services to you;
                  </li>
                  <li>● &nbsp;
                    Whether our processing of the PersonalData is consistent with what
                    an average consumer would expect when the Personal Data was
                    collected;
                  </li>
                  <li>● &nbsp;
                    Whether the Personal Data is processed for any other disclosed
                    purpose(s)compatible with what is reasonably expected by the average
                    consumer;
                  </li>
                  <li>● &nbsp;
                    Whether the Personal Data is relevant and useful to the provision of
                    our services and our further processing is permitted by law;
                  </li>
                  <li>● &nbsp;
                    Whether the Personal Data has been de-identified, anonymized, and/or
                    aggregated; and
                  </li> 
                  <li>● &nbsp;
                      Whether there is a legal obligation towhich we are subject.</li>
                </ol>
          </div>
        
        </div>
      </div>
  
      <div>
        <h3>Notification of changes</h3>
  
        <p>
          Any changes to this Privacy Notice will bepromptly communicated on this
          page and youshould check back to see whether there areany changes.
          Continued use of the Websiteafter a change in the Privacy Notice
          indicatesyour acknowledgment and acceptance of the use of Personal Data
          in accordance with the amended Privacy Notice.If you wish to save this
          text, please click here to download a PDF.
        </p>
      </div>
  
      <div>
        <h3>Data Security</h3>
  
        <p>
          We use a variety of methods, such as firewalls, intrusion detection
          software and manual security procedures, designed to secure your data
          against loss or damage and to help protect the accuracy and security of
          information and to prevent unauthorized access or improper use.
          Nevertheless, transmission via the internet is not completely secure and
          we cannot guarantee the security of information about you. If you think
          that the Website or any Personal Data is not secure or that there has
          been unauthorized access to the Website or your Personal Data, please
          contact privacyofficer@publicisgroupe.com immediately.
        </p>
      </div>
  
      <div>
        <h3>Your rights and choices regarding your Personal Data</h3>
  
        <p>
          Please use this
          <a
            href="https://publicisresources-privacy.my.onetrust.com/webform/a57c3297-68d3-4bc0-8843-262957a92400/4ed20fee-d2f3-451b-98d5-8f21c0fb312e"
            >link</a
          >
          you want to make use of any of your below mentioned legal rights.
        </p>
  
        <h3>1. Where you are resident of the EU</h3>
        <p>
          Subject to certain exceptions and the jurisdiction in which you live, if
          you are a resident of the EU, the GDPR provides you with specific rights
          regarding your Personal Data. This subsection describes your rights and
          explains how to exercise those rights regarding Personal Data that we
          hold about you. These rights include:
        </p>
        <h3>1.1 The right of access:</h3>
        <p>
          You can
          <b
            >access all Personal Data we hold about you, know the origin of this
            Personal Data and obtain a copy in an understandable format.</b
          >
        </p>
        <p>
          You may also use your right to access your Personal Data to control the
          exactness of the data and have them rectified or deleted. You have no
          justification to provide to exercise your right to access.
        </p>
        <h3>1.2 The right to rectification:</h3>
        <p>
          In order to avoid that inaccurate or incomplete Personal Data relating
          to you is process or shared, you can ask us to rectify them;
        </p>
        <h3>1.3 The right to erasure:</h3>
        <p>
          You may request the erasure or deletion of the Personal Data we hold on
          to you. This is not an absolute right since we may have to keep your
          Personal Data for legal or legitimate reasons. You may, for example,
          exercise your right to deletion in the following cases:
        </p>
        <ul style="list-style-type: disc">
          <li>
            (a)&emsp;if you have withdrawn your consent to the processing (see
            below);
          </li>
          <li>
            (b)&emsp;if you legitimately objected to the processing of your data
            (see below);
          </li>
          <li>
            (c)&emsp;when data is not or is no longer necessary for the purposes
            for which it was initially collected or processed;
          </li>
          <li>
            (d)&emsp;the data is processed unlawfully (e.g.,publishing hacked
            data);
          </li>
        </ul>
        <br>
        <h3>1.4 The right to object to the processing of your Personal Data:</h3>
        <p>
          We process your Personal Data based on our legitimate interest (to
          determine to which processing this applies, please refer to relevant
          chart in this Privacy Notice), you may at any time object to the
          processing of your Personal Data for reasons relating to your personal
          situation. We may nevertheless, on a case-by-case basis, reject such a
          request by pointing out the legitimate and imperious reasons justifying
          the processing of this data which prevail on your interests, rights and
          freedoms, or when this data is necessary to establish, exercise or
          defend a right before a Court.
        </p>
        <h3>1.5 The right to restrict processing:</h3>
        <p>
          The right to limit the processing
          <b>completes your other rights.</b>This right means that
          <b
            >the data processing relating to you and that we are performing is
            limited, so that we may keep this data, but we cannot use it or
            process it in any other manner.</b
          >This right applies in specific circumstances, i.e.:
        </p>
        <ul style="list-style-type: disc">
          <li>
            (a)&emsp;if you challenge the exactness of your Personal Data. The
            processing is then limited for a period of time so that the agency may
            verify the exactness of the Personal Data;
          </li>
          <li>
            (b)&emsp;if the processing is unlawful and you object to the erasure
            of your Personal Data and request instead that its use be limited;
          </li>
          <li>
            (c)&emsp;if we do not need the Personal Data for the purposes
            mentioned above in anymore, but you still need it to establish,
            exercise or defend rights before a Court; and
          </li>
          <li>
            (d)&emsp;in the cases where you objected to the processing which is
            based on the legitimate interests of the agency, you may ask to limit
            the processing for the time necessary for us to verify if we can
            accept your objection request (i.e., the time necessary to verify
            whether the legitimate reasons of the agency prevail over yours).
          </li>
        </ul>
        <br>
        <h3>
          1.6 Right to object to data processing for direct marketing purpose
        </h3>
        <p>
          You may unsubscribe or object, at any time and without any
          justification, to the reception of direct marketing communications.
          Simply either (i) click on the link in the footer of the communications
          you receive from us; or (ii)use the hyperlink above; or (iii) send us an
          email at the email address set out in the “Further Information” section
          below with the word unsubscribe in the subject field of the email.
        </p>
        <h3>1.7 The right to data portability:</h3>
        <li>
          (a)&emsp;You may request to
          <b>retrieve the Personal Data you provided us with,</b> in a structured,
          commonly used, and machine-readable format, for personal use or to share
          them with a third party of your choice.
        </li>
        <li>
          (b)&emsp;This right <b>only</b> applies to Personal Data you provided us
          with, directly or indirectly, and which was processed through automated
          means, if this processing is based on your consent or the performance of
          a contract. Please check the list of our Personal Data processing
          activities’ legal grounds to know whether our processing is based on the
          performance of a contract or on consent.
        </li>
        <br>
        <h3>
          1.8 The right to withdraw your consent tothe processing of your Personal
          Data at any time
        </h3>
        <ul style="list-style-type: disc">
          <li>
            (a)&emsp;You may read the relevant chart in this Privacy Notice in
            order to identify the purposes for which the processing of your
            Personal Data is based on your consent.
          </li>
          <li>
            (b)&emsp;If you are unsatisfied with the way we process your Personal
            Data or if your request has been rejected, you may also lodge a formal
            complaint with your local competent data protection authority.
          </li>
          <li>
            (c)&emsp;Personal Data will be stored in accordance with our
            applicable data retention requirements and corporate policies. The
            retention period for Personal Data varies depending on the type of
            Personal Data and the purposes of processing it.
          </li>
          <li>
            (d)&emsp;We will respond without undue delay and in any event within
            one month from the data we receive your request. This timeframe can be
            extended by two months to take the complexity of the request, or the
            number of requests received into account. In this case, we will inform
            you within one month from receiving your request, specifying the
            reasons for extending the response timeframe.
          </li>
        </ul>
        <br>
        <h3>2. Where you are a US resident</h3>
        <p>
          Residents of certain US states, such as California, Virginia, Colorado,
          Connecticut, and Utah, have specific rights regarding their Personal
          Data. This section describes how to exercise those rights and our
          process for handling those requests. (To the extent permitted by
          applicable law, we may charge a reasonable fee to comply with your
          request.)
        </p>
        <h3>2.1 Right to request access to your Personal Data</h3>
        <p>
          You may request access to your Personal Data that we collect, use,
          disclose, or sell. In particular, you may request:
        </p>
        <ul>
          <li>● &nbsp;the specific pieces of Personal Data that we process about you</li>
          <li>● &nbsp;the categories of Personal Data we have collected about you;</li>
          <li>● &nbsp;
            the categories of sources from which the Personal Data was collected;
          </li>
          <li>● &nbsp;
            the categories of Personal Data about you we disclosed for a business
            purpose or sold or shared;
          </li>
          <li>● &nbsp;
            the categories of third parties to whom the Personal Data was
            disclosed for a business purpose or sold or shared; and
          </li>
          <li>● &nbsp;
            the business or commercial purpose for processing the Personal Data.
          </li>
        </ul>
        <p>
          When exercising the right to access Personal Data, you have the right to
          obtain materials in a portable and, to the extent technically feasible,
          readily usable format that allows you to transmit the data to another
          entity without hindrance.
        </p>
        <p>
          When we receive and verify your request to access your Personal Data, we
          will make best efforts to fulfill your request unless an exception
          applies. We will not disclose your government identification numbers,
          financial account numbers, health insurance or medical identification
          numbers, account passwords, security questions and answer, or unique
          biometric data; however, to the extent we have this information, we will
          inform you that we have collected this information. You are permitted 2
          access reports every 12 months.
        </p>
  
        <h3>2.2 Right to request deletion of your Personal Data</h3>
        <p>
          You may also request that we delete any Personal Data that we obtained
          about you. However, we may retain Personal Data for certain important
          purposes, as set out by applicable law.
        </p>
        <p>
          When we receive and verify your request to delete your Personal Data, we
          will proceed to delete the data unless an exception applies. We will
          retain a record of your deletion request in order to keep your Personal
          Data deleted, unless and until you provide consent for the processing of
          your Personal Data post your deletion request.
        </p>
        <h3>2.3 Right to correct your Personal Data</h3>
        <p>
          If you believe we hold inaccurate personal information about you, you
          may request that we correct that information. We may ask for
          documentation showing that the information you submit is accurate and
          the Personal Data we hold is inaccurate. Once we receive your request,
          we will verify your identity and taking into account the type of
          personal information and the purposes of our holding that personal
          information we will make best efforts to complete your request. We may
          deny your request if we determine that the contested Personal Data is
          more likely than not accurate. Additionally, we may choose to delete
          your Personal Data in response to your request to correct your Personal
          Data if the deletion of your Personal Data would not negatively impact
          you.
        </p>
        <h3>2.4 Right to limit use of Sensitive Personal Data</h3>
        <p>
          We do not collect Sensitive Personal Data as that term is defined in US
          state privacy laws like the California Consumer Privacy Act and the
          Virginia Consumer Privacy Act.
        </p>
        <h3>2.5 Right to nondiscrimination</h3>
        <p>
          We will not deny, charge different prices for, or provide a different
          level of quality of goods or services if you choose to exercise your
          privacy rights.
        </p>
        <h3>2.6 Right to Opt-out of the sale of your Personal Data</h3>
        <p>
          We do not sell any Personal Data as the term ‘sell’ is used in US state
          privacy laws like the California Consumer Privacy Act and the Virginia
          Consumer Data Protection Act.
        </p>
        <h3>
          2.7 Right to Opt-Out of Share/Cross Contextual Behavioral Advertising
          and Targeted Advertising
        </h3>
        <p>
          We do not share your Personal Data for cross contextual behavioral
          advertising or otherwise engage in targeted advertising.
        </p>
        <h3>2.8 Right to opt-out of Automated Decision Making and Profiling</h3>
        <p>
          When processing your Personal Data, we do not use processes that involve
          automated decision making or profiling.
        </p>
        <h3>2.9 Shine the Light</h3>
        <p>
          Customers who are residents of California may request (i) a list of the
          categories of Personal Data disclosed by us to third parties during the
          immediately preceding calendar year for those third parties’ own direct
          marketing purposes; and (ii) a list of the categories of third parties
          to whom we disclosed such information. To exercise a request, please
          write us at the email or postal address set out in the “Further
          Information” section below and specify that you are making a “California
          Shine the Light Request.
        </p>
        <h3>2.10 How to exercise your US state privacy rights</h3>
        <p>
          US residents may exercise their applicable privacy rights by sending an
          email toprivacyofficer@publicisgroupe.com, submitting your request
          <a
            href="https://publicisresources-privacy.my.onetrust.com/webform/a57c3297-68d3-4bc0-8843-262957a92400/4ed20fee-d2f3-451b-98d5-8f21c0fb312e"
            >here</a
          >
          or by contacting us at 1-833-983-0087.
        </p>
        <p>
          For security purposes (and as required under US state laws), we will
          verify your identity—in part by requesting certain information from
          you—when you make a request to access, correct, or delete, your Personal
          Data. The verification process will utilize an email verification
          process, a telephone number verification process, and, if applicable, a
          request for any assigned ID number previously communicated to you.
        </p>
        <p>
          If we are unable to complete your request fully for any reason, we will
          provide you additional information about the reasons why we could not
          comply with your request.
        </p>
        <p>
          You may also designate an agent to make requests to exercise your rights
          under certain US state laws. We will take steps both to verify your
          identity and to verify that your agent has been authorized to make a
          request on your behalf by requesting your agent to provide us with a
          signed written authorization or a copy of a power of attorney executed
          by you.
        </p>
        <h3>2.11 Appeal</h3>
        <p>
          It is your right to appeal a decision made concerning the exercise of
          your data privacy rights. You may appeal this decision within 60days of
          receiving your response from us. To submit your appeal,
          contactprivacyofficer@publicisgroupe.com or visit our
          <a
            href="https://publicisresources-privacy.my.onetrust.com/webform/a57c3297-68d3-4bc0-8843-262957a92400/4ed20fee-d2f3-451b-98d5-8f21c0fb312e"
            >portal</a
          >
        </p>
        <h3>2.12 Where you are a Nevada Resident</h3>
        <p>
          Nevada law (NRS 603A.340) requires each business to establish a
          designated request address where Nevada consumers may submit requests
          directing the business not to sell certain kinds of Personal Data that
          the business has collected or will collect about the consumer. A sale
          under Nevada law is the exchange of Personal Data for monetary
          consideration by the business to a third party for the third party to
          license or sell the Personal Data to other third parties. If you are a
          Nevada consumer and wish to submit a request relating to our compliance
          with Nevada law, please contact us at privacyofficer@publicisgroupe.com.
        </p>
  
        <h3>3. Where you are a resident of Canada</h3>
        <h3>3.1 Right to request access to your Personal Data</h3>
        <p>
          You have a right to access your Personal Data in our control. We will
          also provide you with a description of what uses we have made of your
          Personal Data and which third parties we have shared it with. In some
          cases, we may not be able to provide you with access to your Personal
          Data, such as where:
        </p>
        <ul>
          <li>● &nbsp;
            Personal data about another person might be revealed and the other
            person’s personal information cannot be separated from yours;
          </li>
          <li>● &nbsp;
            Commercially confidential information might be revealed and the
            confidential information cannot be separated from yours;
          </li>
          <li>● &nbsp;
            The requested data is subject to solicitor and client privilege;
          </li>
          <li>● &nbsp;
            Someone's life or security might be threatened as a result of giving
            you access and the personal data about the other person cannot be
            separated from yours;
          </li>
          <li>● &nbsp;
            The information was collected for purposes related to an investigation
            of a breach of an agreement or contravention of the law.
          </li>
        </ul>
        <p>
          We will respond to your request within 30days, unless we require more
          time to do so, in which case we will advise you of the date by which we
          will respond to your request within30 days of receiving it.
        </p>
  
        <h3>3.2 Right to request correction to your Personal Data</h3>
        <p>
          If you note any inaccuracies or wish to update any of your Personal
          Data, we will make those changes upon your request.
        </p>
        <h3>3.3 Right to withdraw your consent</h3>
        <p>
          At any time, you may withdraw your consent for us to process your
          Personal Data for the purposes described in this Privacy Notice.
          However, this may affect our ability to offer you some of the services.
          For example, we may not be able to respond to your queries.
        </p>
  
        <h3>4. Your general rights regarding data collection</h3>
        <h3>4.1 Do Not Track:</h3>
        <p>
          Your browser settings may allow you to automatically transmit a “Do Not
          Track” signal to online services you visit. Note, however, there is no
          industry consensus as to what site and app operators should do with
          regard to these signals. Accordingly, unless and until the law is
          interpreted to require us to do so, we do not monitor or take action
          with respect to “Do Not Track” signals. For more information on “Do Not
          Track,” visit
          <a href="https://www.allaboutdnt.com/">https://www.allaboutdnt.com</a>
        </p>
        <h3>4.2 Analytics:</h3>
        <p>
          We may use our own technology or third-party technology to track and
          analyze usage information to provide enhanced interactions and more
          relevant communications, and to track the performance of our
          advertisements.
        </p>
        <p>
          For example, we use Google Analytics(“Google Analytics”), a web
          analytics service provided by Google, Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA. You can learn about Google’s privacy
          practices by going to
          <a href="http://www.google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/ </a>
        </p>
        <p>
          Google Analytics uses cookies to help us analyze how our websites are
          used, including the number of visitors, the websites from which visitors
          have navigated to our websites, and the pages on our websites to which
          visitors navigate. This information is used by us to improve our
          websites. We use Google Analytics with restrictions on how Google can
          process our data enabled. For information on Google’s Restricted Data
          Processing go to
          <a href="https://privacy.google.com/businesses/rdp/">https://privacy.google.com/businesses/rdp/</a>
        </p>
        <h3>4.3 E-mails:</h3>
        <p>
          You can opt-out of receiving promotional emails from us at any time by
          following the instructions as provided in emails to click on the
          unsubscribe link, or emailing us at the email address set out in the
          “Further Information” section below with the word unsubscribe in the
          subject field of the email. Please note that you cannot opt-out of
          non-promotional emails, such as those about transactions, servicing, or
          our ongoing business relations.
        </p>
      </div>
    </div>
  </div>