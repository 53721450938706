import { Injectable } from '@angular/core';
import { AppMeta } from '@core/core-services/title/app-meta.interface';
import { GlobalsService } from '@core/core-services/globals.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { UserState } from '@core/@store/user/user.store';

@Injectable({
  providedIn: 'root',
})
export class AppTitleService {
  DEFAULT_LOGO = '/assets/logos/vaa.png';
  data: AppMeta = { title: '', imgUrl: this.DEFAULT_LOGO };

  @Select(UserState.SelectedClient) selectedClient$: Observable<any>;

  constructor(
    private globalsService: GlobalsService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private titleService: Title
  ) {
    this.selectedClient$.subscribe(res=>{
      this.DEFAULT_LOGO = (res && res.clientLogo) || this.DEFAULT_LOGO;
    });

    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ),
      this.globalsService.apps,
    ]).subscribe((result) => {
      const currentRouteUrl = result[0]['url'];
      const apps = result[1];

      // TODO: line 34 can be cleared if API returns all prefixed with '/'
      const currentApp = apps?.find(
        (app) =>
          app.route === currentRouteUrl ||
          app.route === currentRouteUrl.replace('/', '')
      );

      if (currentApp) {
        this.data = {
          title: currentApp.featureName,
          imgUrl: this.domSanitizer.bypassSecurityTrustUrl(
            `data:image/svg+xml;base64,${btoa(currentApp.logo.iconData)}`
          ),
        };
      } else {
        this.data = { title: '', imgUrl: this.DEFAULT_LOGO };
      }
    });
  }

  public setTitleApp(appMeta: AppMeta) {
    this.titleService.setTitle(appMeta.title);
    this.data = appMeta;
  }
}
