import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  ViewChild,
  Input,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
})
export class SheetComponent implements OnInit {
  checkAll = true;
  test: string;
  @Input() sheet;
  @Input() contexts;
  @Input() id;
  @Output() sheetChange = new EventEmitter();
  @Output() deleteRow = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() resetSplit = new EventEmitter();
  // sheet;
  addRows = 10;
  selectBounds = { c: -1, r: -1, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };
  dragBounds = { c: 0 - 1, r: -1, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };
  copyBounds = { c: 0, r: 0, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };
  selecting = false;
  copying = false;
  ctrlKey = false;
  copyArray;
  copyingSelection = false;
  shiftKey = false;
  holdShiftKey = false;
  log = [];
  logPosition = 0;
  // editingCell = false;
  currentCell;
  justClicked = false;
  doubleClicked = false;
  cellFocus = false;
  //#region Handle keys
  activeCell;
  sheetActive = false;
  state = {
    editing: false,
  };
  scrollWindow: boolean;

  currentRSpan = 1;
  lowerRBand = 0;

  isSpecialKey(key) {
    const specialKeys = [
      'Escape',
      'ArrowRight',
      'ArrowDown',
      'ArrowLeft',
      'ArrowUp',
      'Enter',
      'Delete',
    ];
    return specialKeys.includes(key);
  }
  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event) {
    if (this.sheetActive) {
      if (!this.state.editing && event.button === 0) {
        if (this.selecting) {
          this.dragBounds = clone(this.selectBounds);
          this.selecting = false;
        }
        if (this.copying) {
          this.copy(this.selectBounds);
          this.dragPaste(this.dragBounds);
          this.selectBounds = clone(this.dragBounds);
          this.copying = false;
        }
      }
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (this.sheetActive) {
      //handle typing when not editing a cell
      if (
        this.activeCell &&
        !this.state.editing &&
        !event.ctrlKey &&
        !event.metaKey &&
        !this.isSpecialKey(event.key)
      ) {
        // event.preventDefault();

        // this.activeCell.select();
        // this.activeCell.focus();
        // this.unfocusCell();
        this.activeCell = this.renderer.selectRootElement(
          '#' +
            this.id +
            ' #cell-' +
            this.selectBounds.c +
            '-' +
            this.selectBounds.r +
            ' > input'
        );
        this.activeCell.value = '';

        this.activeCell.focus();

        this.state.editing = true;
      }
      if (!this.state.editing) {
        if (event.key === 'Escape') {
          this.copyingSelection = false;
        }

        if (event.key === 'Delete') {
          for (
            let i = this.selectBounds.r;
            i < this.selectBounds.r + this.selectBounds.rSpan;
            i++
          ) {
            for (
              let h = this.selectBounds.c;
              h < this.selectBounds.c + this.selectBounds.cSpan;
              h++
            ) {
              this.sheet[i][h].value = '';
            }
          }
          this.evaluate();
        }
        // select all
        if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
          this.selectBounds.c = 0;
          this.selectBounds.r = 0;
          this.selectBounds.cSpan = this.sheet[0].length;
          this.selectBounds.rSpan = this.sheet.length;
          this.dragBounds = clone(this.selectBounds);
          // this.selectBounds.r = 0;
          return false;
        }
        if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
          this.copyBounds = clone(this.selectBounds);
          this.copyingSelection = true;
          this.copy(this.copyBounds);
          copyToClipboard(this.copyArray);
        } else if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
          if (this.copyingSelection) {
            this.paste();
          } else {
            this.pasteFromClipboard();
          }
          this.copyingSelection = false;
        }
        // down when not editing
        if (
          (event.key === 'ArrowDown' || event.key === 'Enter') &&
          this.selectBounds.r + this.selectBounds.rSpan < this.sheet.length
        ) {
          event.preventDefault();
          if (event.shiftKey) {
            this.selectBounds.rSpan++;
          } else {
            const currentR = this.selectBounds.r;
            this.selectBounds.r =
              currentR + this.sheet[currentR][this.selectBounds.c].rSpan;
            this.selectBounds.rSpan =
              this.sheet[
                currentR + this.sheet[currentR][this.selectBounds.c].rSpan
              ][this.selectBounds.c].rSpan;
            this.selectBounds.cSpan = 1;
            this.unfocusCell();

            this.activeCell = this.renderer.selectRootElement(
              '#' +
                this.id +
                ' #cell-' +
                this.selectBounds.c +
                '-' +
                this.selectBounds.r +
                ' > input'
            );
          }
          this.dragBounds = clone(this.selectBounds);
        }
        if (event.key === 'ArrowUp') {
          let currentR;
          event.preventDefault();
          if (event.shiftKey) {
            if (this.selectBounds.rSpan > 1) {
              this.selectBounds.rSpan--;
            }
          } else if (this.selectBounds.r > 0) {
            currentR = this.selectBounds;

            this.selectBounds.r--;
            this.selectBounds.rSpan = 1;
            this.selectBounds.cSpan = 1;
          }
          this.dragBounds = clone(this.selectBounds);
          this.unfocusCell();

          this.test =
            '#' +
            this.id +
            ' #cell-' +
            this.selectBounds.c +
            '-' +
            this.selectBounds.r +
            ' > input';
          this.activeCell = this.renderer.selectRootElement(
            '#' +
              this.id +
              ' #cell-' +
              this.selectBounds.c +
              '-' +
              this.selectBounds.r +
              ' > input'
          );
        }
        if (
          event.key === 'ArrowRight' &&
          this.selectBounds.c + this.selectBounds.cSpan < this.sheet[0].length
        ) {
          event.preventDefault();
          if (event.shiftKey) {
            this.selectBounds.cSpan++;
          } else {
            this.selectBounds.c++;

            this.selectBounds.rSpan = 1;
            this.selectBounds.cSpan = 1;
          }
          this.dragBounds = clone(this.selectBounds);
          this.unfocusCell();
          this.activeCell = this.renderer.selectRootElement(
            '#' +
              this.id +
              ' #cell-' +
              this.selectBounds.c +
              '-' +
              this.selectBounds.r +
              ' > input'
          );
        }
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          if (event.shiftKey) {
            if (this.selectBounds.cSpan > 1) {
              this.selectBounds.cSpan--;
            }
          } else if (this.selectBounds.c - 1 >= 0) {
            this.selectBounds.c--;
            this.selectBounds.rSpan = 1;
            this.selectBounds.cSpan = 1;
          }
          this.dragBounds = clone(this.selectBounds);
          this.unfocusCell();
          this.activeCell = this.renderer.selectRootElement(
            '#' +
              this.id +
              ' #cell-' +
              this.selectBounds.c +
              '-' +
              this.selectBounds.r +
              ' > input'
          );
        }
      } else {
        // down when editing
        if (
          (event.key === 'ArrowDown' || event.key === 'Enter') &&
          this.selectBounds.r + this.selectBounds.rSpan < this.sheet.length
        ) {
          event.preventDefault();

          this.selectBounds.r++;
          this.selectBounds.rSpan = 1;
          this.selectBounds.cSpan = 1;
          // this.activeCell = this.renderer.selectRootElement('#'+this.id+' #cell-' + this.selectBounds.c + '-' + this.selectBounds.r + ' > input');
          // this.state.editing = false;
          this.dragBounds = clone(this.selectBounds);
          // this.unfocusCell();
          this.unfocusCell();
          this.activeCell = this.renderer.selectRootElement(
            '#' +
              this.id +
              ' #cell-' +
              this.selectBounds.c +
              '-' +
              this.selectBounds.r +
              ' > input'
          );
        }
        if (event.key === 'ArrowUp' && this.selectBounds.r > 0) {
          event.preventDefault();
          if (event.shiftKey) {
            if (this.selectBounds.rSpan > 1) {
              this.selectBounds.rSpan--;
            }
          } else if (this.selectBounds.r > 0) {
            this.selectBounds.r--;
            this.selectBounds.rSpan = 1;
            this.selectBounds.cSpan = 1;
          }
          this.dragBounds = clone(this.selectBounds);
          this.unfocusCell();
          this.activeCell = this.renderer.selectRootElement(
            '#' +
              this.id +
              ' #cell-' +
              this.selectBounds.c +
              '-' +
              this.selectBounds.r +
              ' > input'
          );
        }
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardUpEvent(event: KeyboardEvent) {}

  unfocusCell() {
    if (this.activeCell) {
      this.activeCell.blur();
    }

    this.state.editing = false;
    if (this.currentCell) {
      this.currentCell.classList.remove('editable');
    }
  }

  //#endregion

  constructor(private renderer: Renderer2) {
    // this.sheet = this.spreadsheetService.getSheet();
  }

  escape() {
    this.copyingSelection = false;
  }

  ngOnInit() {
    // this.commitToLog();
  }
  commitToLog() {
    this.log.push(clone(this.sheet));
    this.logPosition++;
  }
  // drag behaviour

  cellDown(c, r, cell, e, rSpan) {
    this.currentRSpan = rSpan;
    this.lowerRBand = rSpan - 1 + r;

    if (
      cell.classList.contains('header-cell') ||
      cell.classList.contains('locked') ||
      cell.classList.contains('delete') ||
      (cell.classList.contains('summary-cell') &&
        !cell.classList.contains('editable'))
    ) {
      this.selectBounds = {
        c: c,
        r: r,
        cSpan: 1,
        rSpan: this.currentRSpan,
        cStart: c,
        rStart: r,
      };
      this.dragBounds = clone(this.selectBounds);
      this.selecting = true;
    } else {
      const input = cell.getElementsByTagName('input')[0];
      if (e.button === 0 && input && !input.classList.contains('editable')) {
        if (this.activeCell) {
          this.unfocusCell();
        }
        this.activeCell = input;
        if (e.detail === 2) {
          this.state.editing = true;
          input.classList.add('editable');
          // input.focus();
          this.currentCell = input;
          this.selecting = false;
        } else {
          this.selectBounds = {
            c: c,
            r: r,
            cSpan: 1,
            rSpan: this.currentRSpan,
            cStart: c,
            rStart: r,
          };
          this.dragBounds = clone(this.selectBounds);
          this.selecting = true;
        }
      }
    }
  }

  // cellOver(c, r, rSpan) {
  //   let highBound = r;
  //   let lowBound = r;

  //   if (!this.state.editing) {
  //     if (this.selecting) {
  //       // moving left
  //       if (this.selectBounds.cStart >= c) {
  //         this.selectBounds.c = c;
  //         this.selectBounds.cSpan = this.selectBounds.cStart - c + 1;
  //         // movign right
  //       } else {
  //         this.selectBounds.cSpan = c - this.selectBounds.cStart + 1;
  //       }
  //       let check = true;
  //       while (check) {
  //         let hasZero = false;
  //         let allOne = true;
  //         this.sheet[highBound].forEach((cell, i) => {
  //           if (i >= this.selectBounds.c && i < this.selectBounds.c + this.selectBounds.cSpan) {
  //             if (cell.rSpan === 0) { hasZero = true }
  //             if (cell.rSpan !== 1) { allOne = false }
  //           }
  //         });

  //         if (!hasZero || allOne) {
  //           check = false;
  //         } else {
  //           highBound--;
  //         }
  //       }
  //       check = true;
  //       while (check) {
  //         let hasZero = false;
  //         let allOne = true;
  //         let greaterThan1 = false;
  //         if (lowBound === this.sheet.length - 1) {
  //           check = false;
  //         } else {
  //           this.sheet[lowBound].forEach((cell, i) => {
  //             if (i >= this.selectBounds.c && i < this.selectBounds.c + this.selectBounds.cSpan) {

  //               if (cell.rSpan === 0) { hasZero = true }
  //               if (cell.rSpan !== 1) { allOne = false }
  //               if (cell.rSpan > 1) { greaterThan1 = true }
  //             }
  //           });
  //
  //           if (allOne) {
  //             check = false;
  //           } else if (greaterThan1 || hasZero) {
  //             lowBound++;
  //           } else {
  //             check = false;
  //             lowBound--;
  //           }
  //         }

  //       }

  //       // moving up
  //       if (this.selectBounds.rStart >= r) {
  //         this.selectBounds.r = highBound;
  //         if (this.currentRSpan > this.selectBounds.rStart - r + 1 + (rSpan - 1)) {
  //           this.selectBounds.rSpan = this.lowerRBand - highBound + 1;
  //         } else {
  //           this.selectBounds.rSpan = this.lowerRBand - highBound + 1;
  //         }
  //         // moving down
  //       } else {
  //         if (this.currentRSpan > r - this.selectBounds.rStart + 1 + (rSpan - 2)) {
  //           this.selectBounds.rSpan = this.currentRSpan + 1;
  //         } else {
  //           // this.selectBounds.rSpan = lowBound - this.selectBounds.r;
  //           this.selectBounds.rSpan = r - this.selectBounds.rStart + 1 + (rSpan - 1);
  //         }
  //       }
  //       this.dragBounds = clone(this.selectBounds);
  //     }
  //     if (this.copying) {
  //       if (this.dragBounds.rStart >= r) {
  //         this.dragBounds.r = r;
  //         if (this.currentRSpan > this.dragBounds.rStart - r + 1 + (rSpan - 1)) {
  //           this.dragBounds.rSpan = this.currentRSpan + 1;
  //         } else {
  //           this.dragBounds.rSpan = this.dragBounds.rStart - r + 1 + (rSpan - 1);
  //         }

  //       } else {
  //         if (this.currentRSpan > r - this.dragBounds.rStart + 1 + (rSpan - 1)) {
  //           this.dragBounds.rSpan = this.currentRSpan + 1;
  //         } else {
  //           this.dragBounds.rSpan = r - this.dragBounds.rStart + 1 + (rSpan - 1);
  //         }

  //       }
  //     }
  //   }

  // }

  cellOver(c, r) {
    if (!this.state.editing) {
      if (this.selecting) {
        // moving left
        if (this.selectBounds.cStart >= c) {
          this.selectBounds.c = c;
          this.selectBounds.cSpan = this.selectBounds.cStart - c + 1;
          // moving right
        } else {
          this.selectBounds.cSpan = c - this.selectBounds.cStart + 1;
        }
        // moving up
        if (this.selectBounds.rStart >= r) {
          this.selectBounds.r = r;
          this.selectBounds.rSpan = this.selectBounds.rStart - r + 1;
          // moving down
        } else {
          this.selectBounds.rSpan = r - this.selectBounds.rStart + 1;
        }
        // check if over merge
        let tempArr = [];
        for (
          let h = this.selectBounds.c;
          h < this.selectBounds.c + this.selectBounds.cSpan;
          h++
        ) {
          tempArr.push([]);
          for (
            let i = this.selectBounds.r;
            i < this.selectBounds.r + this.selectBounds.rSpan;
            i++
          ) {
            if (this.sheet[i][h].rSpan >= 1) {
              tempArr[tempArr.length - 1].push({
                r: i,
                c: h,
                ...this.sheet[i][h],
              });
            }
            if (this.sheet[i][h].rSpan === 0) {
              let i2 = i;
              while (this.sheet[i2][h].rSpan === 0) {
                i2--;
              }
              tempArr[tempArr.length - 1].push({
                r: i2,
                c: h,
                ...this.sheet[i2][h],
              });
            }
          }
        }
        let low = Math.pow(10, 6);
        let high = 0;
        const rSpanArray = [];
        tempArr.forEach((i) => {
          const tempTempArr = [];
          i.forEach((h) => {
            let inArr = false;
            if (h.r < low) {
              low = h.r;
            }
            tempTempArr.forEach((h2) => {
              if (h.c === h2.c && h.r === h2.r) {
                inArr = true;
              }
            });
            if (!inArr) {
              tempTempArr.push(h);
            }
          });
          i = tempTempArr;
          rSpanArray.push(i.map((a) => a.rSpan).reduce((a, b) => a + b));
        });
        rSpanArray.forEach((a) => {
          if (a > high) {
            high = a;
          }
        });
        this.selectBounds.r = low;
        this.selectBounds.rSpan = high;
        this.dragBounds = clone(this.selectBounds);
      }
      if (this.copying) {
        if (this.dragBounds.rStart >= r) {
          this.dragBounds.r = r;
          this.dragBounds.rSpan = this.dragBounds.rStart - r + 1;
        } else {
          this.dragBounds.rSpan = r - this.dragBounds.rStart + 1;
        }
      }
    }
  }

  cellUp(c, r) {
    if (!this.state.editing) {
      if (this.selecting) {
        this.dragBounds = clone(this.selectBounds);
        this.selecting = false;
      }
      if (this.copying) {
        this.copy(this.selectBounds);
        this.dragPaste(this.dragBounds);
        this.selectBounds = clone(this.dragBounds);
        this.copying = false;
      }
    }
  }

  handleDown(c, r) {
    this.copying = true;
  }

  sheetOut() {
    this.selecting = false;
    this.copying = false;

    this.dragBounds = clone(this.selectBounds);
  }

  singleClick(cell) {}

  doubleClick(cell) {}
  // buttons
  add() {
    const cell = { value: '', formula: '', formatting: [] };
    const row = [];
    this.sheet[0].forEach((element) => {
      row.push(cell);
    });
    for (let i = 0; i < this.addRows; i++) {
      this.sheet.push(row);
    }
  }

  inputKey(e, cell) {
    if (e.target.value[0] === '=') {
      cell.formula = e.target.value;
    } else {
      cell.formula = '';
    }
  }
  evaluate() {
    this.sheetChange.emit(this.sheet);

    // if (cell.formula.length > 1) {

    // }
  }

  // context menu
  @ViewChild(MatMenuTrigger, { static: true })
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  onContextMenu(event: MouseEvent, item) {
    if (this.contexts) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { item: item };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }
  onContextMenuAction(item, key) {
    if (key === 'delete') {
      this.deleteRow.emit(item.r);
    }
  }
  onContextMenuAction1(item) {
    this.copyBounds = clone(this.selectBounds);
    this.copyingSelection = true;
    this.copy(this.copyBounds);
    copyToClipboard(this.copyArray);
  }
  onContextMenuAction2(item) {
    if (this.copyingSelection) {
      this.paste();
    } else {
      this.pasteFromClipboard();
    }
    this.copyingSelection = false;
  }
  onContextMenuAction3(item) {
    let insert = [];
    for (let i = 0; i < this.selectBounds.rSpan; i++) {
      insert.push([
        { value: '', formula: '', formatting: [] },
        { value: '', formula: '', formatting: [] },
        // { value: row.index, formula: '', formatting: [] },
        { value: '', formula: '', formatting: [] },
      ]);
    }

    this.sheet.splice(this.selectBounds.r, 0, ...insert);
  }

  // scroll
  scrollEnter() {
    this.scrollWindow = true;
    this.scroll(2);
  }
  scroll(speed) {
    if (this.scrollWindow) {
      let pos = window.pageYOffset;
      window.scrollTo(0, pos + speed);
      speed *= 1.002;
      setTimeout(() => {
        this.scroll(speed);
      }, 1);
    }
  }
  scrollLeave() {
    this.scrollWindow = false;
  }
  // copy and paste

  copy(bounds) {
    this.copyArray = [];
    for (let i = bounds.r; i < bounds.r + bounds.rSpan; i++) {
      const row = [];
      for (let h = bounds.c; h < bounds.c + bounds.cSpan; h++) {
        row.push(this.sheet[i][h]);
      }
      this.copyArray.push(row);
    }
  }

  dragPaste(bounds) {
    let row = 0,
      column = 0;
    for (let i = bounds.r; i < bounds.r + bounds.rSpan; i++) {
      if (
        !(
          i >= this.selectBounds.r &&
          i <= this.selectBounds.r + this.selectBounds.rSpan - 1
        )
      ) {
        for (let h = bounds.c; h < bounds.c + bounds.cSpan; h++) {
          if (
            !(
              i >= this.selectBounds.r &&
              i <= this.selectBounds.r + this.selectBounds.rSpan - 1
            )
          ) {
            this.sheet[i][h] = this.copyArray[row][column];
            column = cap(column + 1, this.copyArray[row].length);
          }
        }
        row = cap(row + 1, this.copyArray.length);
      }
    }
    this.sheetChange.emit(this.sheet);
  }

  paste() {
    if (this.selectBounds.rSpan > 1 || this.selectBounds.cSpan > 1) {
      let r, c;
      r = 0;
      for (
        let i = this.selectBounds.r;
        i < this.selectBounds.rSpan + this.selectBounds.r;
        i++
      ) {
        c = 0;
        for (
          let h = this.selectBounds.c;
          h < this.selectBounds.cSpan + this.selectBounds.c;
          h++
        ) {
          this.sheet[i][h].value = this.copyArray[r][c].value;
          c = cap(c + 1, this.copyArray[0].length);
        }
        r = cap(r + 1, this.copyArray.length);
      }
    } else {
      let rSpan, cSpan;
      rSpan = 0;
      for (let i = 0; i < this.copyArray.length; i++) {
        cSpan = 0;
        if (i + this.selectBounds.r < this.sheet.length) {
          for (let h = 0; h < this.copyArray[i].length; h++) {
            if (h + this.selectBounds.c < this.sheet[0].length) {
              this.sheet[i + this.selectBounds.r][
                h + this.selectBounds.c
              ].value = this.copyArray[i][h].value;
              cSpan++;
            }
          }
          rSpan++;
        }
      }
      this.selectBounds.rSpan = rSpan;
      this.selectBounds.cSpan = cSpan;
      this.dragBounds = clone(this.selectBounds);
    }
    this.sheetChange.emit(this.sheet);
  }

  pasteFromClipboard() {
    navigator.clipboard
      .readText()
      .then((text) => {
        this.copyArray = [];
        const rows = text.split(/\r?\n/);
        rows.forEach((row) => {
          this.copyArray.push(
            row.split('\t').map((c) => {
              return { value: c };
            })
          );
        });
        this.paste();
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents', err);
      });
    this.sheetChange.emit(this.sheet);
  }
  sheetMouseEnter() {
    this.sheetActive = true;
  }
  sheetMouseLeave() {
    this.sheetActive = false;
    this.activeCell = null;
    this.copyingSelection = false;
    this.selectBounds = {
      c: -1,
      r: -1,
      cSpan: 1,
      rSpan: 1,
      cStart: 0,
      rStart: 0,
    };
    this.dragBounds = {
      c: 0 - 1,
      r: -1,
      cSpan: 1,
      rSpan: 1,
      cStart: 0,
      rStart: 0,
    };
    this.copyBounds = { c: 0, r: 0, cSpan: 1, rSpan: 1, cStart: 0, rStart: 0 };
  }
  deleteBrand(r) {
    this.delete.emit(r);
  }
  updateCheck() {
    this.sheetChange.emit(this.sheet);
  }

  updateCheckAll() {}
  toggleExpand(cell, e) {
    this.sheet
      .filter((r) => {
        return r[0].category === cell.category;
      })
      .forEach((r) => {
        r.forEach((c) => {
          c.expanded = !c.expanded;
        });
      });
    this.sheetChange.emit(this.sheet);
  }
  expandAll(state) {
    this.sheet.forEach((r) => {
      r.forEach((c) => {
        c.expanded = state;
      });
    });
    this.sheetChange.emit(this.sheet);
  }
  reset(c) {
    this.resetSplit.emit(c);
  }
}

function clone(object) {
  return JSON.parse(JSON.stringify(object));
}

function cap(value, cap) {
  return value - Math.floor(value / cap) * cap;
}

function copyToClipboard(array) {
  let str = '';
  array.forEach((row, i, iArray) => {
    row.forEach((column, h, hArray) => {
      str += column.value;
      if (hArray.length - 1 !== h) {
        str += '\t';
      }
    });
    if (iArray.length - 1 !== i) {
      str += '\n';
    }
  });
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
