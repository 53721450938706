<!--<button mat-stroked-button-->
<!--        (click)="goHome()">Home-->
<!--</button>-->

<div class="m-8 p-8 overflow-hidden border border-solid cus-border-color flex items-center justify-center">
    <div class="m-6 p-4 flex flex-col items-center justify-center">
        <div>
            <p class="m-0 text-center bg-gold cus-text-9xl">404</p>
            <p class="my-0 font-normal not-italic normal-nums text-center font-poppins text-h3 sub-text">Well this is suboptimal</p>
        </div>

        <div class="my-8">
            <p class="text-body-text text-center font-poppins">We are sorry that we can’t bring you the page you were looking for.</p>
        </div>

        <div class="flex border border-white text-center">
            <div class="mx-2">
                <button mat-stroked-button color="primary" class="px-3 py-2 bg-grey-dark bg-gold flex items-center border border-solid cus-border-white cursor-pointer "
                        (click)="goHome()">
                    <i class="eva eva-arrow-back-outline"></i>
                    <span class="uppercase font-poppins font-bold mx-2">GO BACK</span></button>
            </div>
            <div class="mx-2">
                <!-- <button class="px-3 py-2 bg-grey-dark text-accent-light flex items-center border border-solid cus-border-white">
                    <i class="eva eva-alert-triangle-outline"></i>
                    <span class="uppercase font-poppins font-bold mx-2">REPORT PROBLEM</span></button> -->
            </div>
        </div>
    </div>
</div>
