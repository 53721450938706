import { Component, OnInit, Input, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { ProgressStateActions } from '@core/@store/progress-state/progress-state.actions';
import SetProgressStateDisplayState = ProgressStateActions.SetProgressStateDisplayState;

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomSnackBarComponent implements OnInit, OnDestroy {
  menuStateSubscription: Subscription;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private store: Store,
              public snackBar: MatSnackBar) {
  }


  ngOnInit() {
    const styleTarget = (document.querySelector('.info-snackbar') as HTMLBodyElement);
  }

  ngOnDestroy(): void {
    // this.store.dispatch(new SetProgressStateDisplayState({ isOpen: false, job: {} })).subscribe((res) => {
    // });
  }

  close(): void{
    this.store.dispatch(new SetProgressStateDisplayState({ isOpen: false, job: {} })).subscribe((res) => {
    });
  }

}
