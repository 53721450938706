import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'featureString'
})
export class FeatureStringPipe implements PipeTransform {

    transform(value: any, args?: any): any {

        if (!value) {
            return [];
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return value.map(x => x.featureCode);
    }

}
