import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';

// ====== Guards
import { AuthenticationGuard } from '@shared/guards/authentication.guard';
import { AuthorizeGuard } from '@shared/guards/authorize.guard';
import { UserRoleGuard } from '@shared/guards/user-role.guard';
import { AdminGuardGuard } from '@shared/guards/admin-guard.guard';
import { DevelopmentGuard } from '@shared/guards/development.guard';
import { UnsavedChangesGuard } from '@shared/guards/unsaved-changes.guard';
import { LinksComponent } from '@shared/components/links/links.component';
import { NoAccessComponent } from './error-components/no-access/no-access.component';
import { NoContentComponent } from './error-components/no-content/no-content.component';
import { TermsPageComponent } from './modules/auth/components/terms-page/terms-page.component';
import { PrivacyPolicyPageComponent } from './modules/auth/components/privacy-policy-page/privacy-policy-page.component';

const appRoutes: Routes = [
  // Auth modules
  // { path: '', component: , canActivate: [AuthenticationGuard] },
  { path: '',  redirectTo: 'vaa', pathMatch: 'full', data: { breadcrumb: 'Home' } },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: 'Auth' },
  },
  { path: 'login', redirectTo: 'auth/login' },
  { path: 'logout', redirectTo: 'auth/logout' },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthenticationGuard, AdminGuardGuard],
    data: { breadcrumb: 'Admin' },
  },
  {
    path: 'app/:featureId',
    component: LinksComponent,
    canActivate: [AuthenticationGuard],
    data: { breadcrumb: 'App' },
  },
  {
    path: 'asset-repo',
    loadChildren: () =>
      import('./modules/asset-repo/asset-repo.module').then(
        (m) => m.AssetRepoModule
      ),
    canActivate: [AuthenticationGuard],
    data: { breadcrumb: 'Asset Repo' },
  },
  {
    path: 'vaa',
    loadChildren: () =>
      import('./modules/vaa/vaa.module').then((m) => m.VaaModule),
    canActivate: [AuthenticationGuard],
    data: { breadcrumb: 'Home' },
  },
  {
    path: 'style-guide',
    loadChildren: () =>
      import('./@style-guide/style-guide.module').then(
        (m) => m.StyleGuideModule
      ),
    canActivate: [DevelopmentGuard],
    data: { breadcrumb: 'Style Guide' },
  },
  { 
    path: 'terms', component: TermsPageComponent,
    canActivate: [AuthenticationGuard],
    data: { breadcrumb: 'Terms' }
  },
  { path: 'privacy-policy', component: PrivacyPolicyPageComponent,
    canActivate: [AuthenticationGuard],
    data: { breadcrumb: 'Privacy Policy' }
  },

  // otherwise redirect to home
  { path: 'no-access', component: NoAccessComponent },
  { path: '**', component: NoContentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [AuthenticationGuard, AuthorizeGuard, UserRoleGuard, UnsavedChangesGuard],
})
export class AppRoutingModule {}
