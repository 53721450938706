import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class OptimumAdminService {
  res = null;
  error = null;
  token = null;

  apiUrl = environment.api + '/api/admin/';

  constructor(private http: HttpClient) {
  }

  // User : Admin Services
  // Get User List
  getUserList(
    querySring?: string,
    pageSize?: number,
    skipRecords?: number,
    userSortOrder?: string
  ): Observable<any> {
    return this.http.get(
      this.apiUrl +
      'users/?query=' +
      querySring +
      '&limit=' +
      pageSize +
      '&skip=' +
      skipRecords +
      '&sort=' +
      userSortOrder
    );
  }

  // Get User
  getUser(userId): Observable<any> {
    return this.http.get(this.apiUrl + 'users/one/' + userId);
  }

  getAppGroups(doCache?: number): Observable<any> {
    return this.http.get(this.apiUrl + 'feature-group?cache=' + doCache);
  }

  getFeatureCategories(): Observable<any> {
    return this.http.get(this.apiUrl + 'features/category');
  }

  getIconList(): Observable<any> {
    return this.http.get(this.apiUrl + 'feature-group/icons/list');
  }

  getAppGroup(appGroupId: string): Observable<any> {
    return this.http.get(this.apiUrl + 'feature-group/' + appGroupId);
  }

  updateAppGroup(appGroupId: string, updatedAppGroup: any): Observable<any> {
    updatedAppGroup['logo'] = updatedAppGroup['logo']['_id'];
    return this.http.put(this.apiUrl + 'feature-group/' + appGroupId, {
      appGroup: updatedAppGroup
    });
  }

  addAppGroup(appGroup: any): Observable<any> {
    // Before saving we need to make sure the asset Name has been stringified
    appGroup['logo'] = appGroup['logo']['_id'];
    return this.http.post(this.apiUrl + 'feature-group/add', {
      appGroup: appGroup
    });
  }

  // getAppGroupFeatures(appGroupId: string) : Observable<any> {
  //   return this.http.get(environment.api + '/api/features/' + appGroupId).map(r =>{
  //     return (r.json()) as any;
  //   }) as Observable<any>;
  // }

  getFeature(featureId: string): Observable<any> {
    return this.http.get(this.apiUrl + 'features/' + featureId);
  }

  getCustomFeature(featureId: string): Observable<any> {
    return this.http.get(this.apiUrl + 'features/custom/' + featureId);
  }

  addFeature(feature: any): Observable<any> {
    feature['logo'] = feature['logo']['_id'];
    return this.http.post(this.apiUrl + 'features/', { feature: feature });
  }

  flushCache(): Observable<any> {
    return this.http.post(environment.api + '/api/flush', {});
  }

  updateFeature(featureId: string, feature: any): Observable<any> {
    feature['logo'] = feature['logo']['_id'];
    return this.http.put(this.apiUrl + 'features/' + featureId, {
      feature: feature
    });
  }

  deleteAppGroup(appGroupId: string): Observable<any> {
    return this.http.delete(this.apiUrl + 'feature-group/' + appGroupId);
  }

  deleteFeature(featureId: string): Observable<any> {
    return this.http.delete(this.apiUrl + 'features/' + featureId);
  }

  deleteCustomFeature(featureId: string): Observable<any> {
    return this.http.delete(this.apiUrl + 'features/custom/' + featureId);
  }

  getRoles(
    queryString?: string,
    pageSize?: number,
    skipRecords?: number
  ): Observable<any> {
    return this.http.get(
      this.apiUrl +
      'role/?query=' +
      queryString +
      '&limit=' +
      pageSize +
      '&skip=' +
      skipRecords
    );
  }

  saveRole(roleId: string, roleObject: any) {
    return this.http.put(this.apiUrl + 'roles/' + roleId, { role: roleObject });
  }

  saveUser(userId: string, userObj): Observable<any> {
    return this.http.put(this.apiUrl + 'users/one/' + userId, {
      user: userObj
    });
  }

  getAllUsers(limit: number, skip: number = 0) {
    // @ts-ignore
    // TODO: fix the ts-ignore issue. need a proper solution to translate a object into url params; maybe a services?
    const queryString = new URLSearchParams({ limit, skip }).toString();
    return this.http
      .get(environment.api + `/api/admin/users?${queryString}`)
      .pipe(map((res) => res['response']));
  }

  addUser(userObj: any): Observable<any> {
    return this.http.post(environment.api + '/api/admin/users/', {
      user: userObj
    });
  }

  notifyUser(userEmail: string): Observable<any> {
    return this.http.post(environment.api + '/api/welcome', {
      email: userEmail
    });
  }

  // Client Service Methods
  getClientList(queryString?: string, pageSize?: number, skipRecords?: number, clientSortOrder?: string): Observable<any> {
    return this.http.get(this.apiUrl + `client/?query=${queryString}&limit=${pageSize}&skip=${skipRecords}&sort=${clientSortOrder}`);
  }

  deleteClient(clientId: string): Observable<any> {
    return this.http.delete(this.apiUrl + `client/one/${clientId}`);
  }


  getFeatures(
    queryString?: string,
    pageSize?: number,
    skipRecords?: number
  ): Observable<any> {
    return this.http.get(
      this.apiUrl +
      'features/?query=' +
      queryString +
      '&limit=' +
      pageSize +
      '&skip=' +
      skipRecords
    );
  }

  getCustomFeatures(clientId: string, marketId?: string): Observable<any> {
    return this.http.get(
      this.apiUrl +
      'client/' +
      clientId +
      (marketId !== 'client' ? '/' + marketId : '') +
      '/custom-features'
    );
  }

  saveClientFeatures(clientId: string, featureList: [string]): Observable<any> {
    return this.http.put(this.apiUrl + 'client/' + clientId + '/features', {
      featureList: featureList
    });
  }

  saveCustomFeatures(
    customFeature: any,
    clientId: string,
    marketId?: string
  ): Observable<any> {
    return this.http.post(
      this.apiUrl +
      'client/' +
      clientId +
      (marketId !== 'client' ? '/' + marketId : '') +
      '/custom-feature',
      { feature: customFeature }
    );
  }

  updateCustomFeature(customFeature: any): Observable<any> {
    return this.http.put(
      environment.api + '/api/features/custom/' + customFeature._id,
      { customFeature: customFeature }
    );
  }

  saveMarketFeatures(
    clientId: string,
    marketId: string,
    featureList: [string]
  ): Observable<any> {
    return this.http.put(
      this.apiUrl + 'client/' + clientId + '/' + marketId + '/features',
      { featureList: featureList }
    );
  }

  addMarket(clientId: string, marketObject: any): Observable<any> {
    return this.http.post(this.apiUrl + `client/${clientId}`, {
      market: marketObject
    });
  }

  editMarket(
    clientId: string,
    marketId: string,
    marketObject: any
  ): Observable<any> {
    return this.http.put(this.apiUrl + '/' + clientId + '/market/' + marketId, {
      marketId,
      market: marketObject,
    });
  }

  getMarketList(clientId: string, queryString?: string, pageSize?: number, skipRecords?: number): Observable<any> {
    const qs = queryString || '';
    return this.http.get(this.apiUrl + `client/${clientId}?query=${qs}&limit=${pageSize}&skip=${skipRecords}`
    );
  }

  postLogout(): Observable<any> {
    return this.http.post(environment.api + '/api/logout', {});
  }

  getPartnerList(queryString?: string, ilimit?: string): Observable<any> {
    const limit = ilimit || '50';
    const qs = queryString || '';
    return this.http.get(
      this.apiUrl + 'partner/?query=' + qs + '&limit=' + limit
    );
  }

  addClient(clientObject): Observable<any> {
    return this.http.post(this.apiUrl + 'client/add', { client: clientObject });
  }

  getSSOUserList(searchString): Observable<any> {
    return this.http.get(this.apiUrl + `users/sso?query=${searchString}`);
  }

}
