import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import CreateRule = RuleActions.CreateRule;
import GetRules = RuleActions.GetRules;
import ToggleRuleState = RuleActions.ToggleRuleState;
import { RuleStateModel } from './rule.state';
import { RolesService } from '@core/core-services/roles.service';
import { RuleActions } from './rule.actions';
import { tap } from 'rxjs/operators';

@State<RuleStateModel>({
  name: 'Rule',
  defaults: {
    rules: [],
  },
})
@Injectable()
export class RuleState {
  constructor(private roleService: RolesService) {}

  @Selector()
  static rules(state: RuleStateModel) {
    return state.rules;
  }
  @Action(CreateRule)
  createRule(ctx: StateContext<any>, action: CreateRule) {
    return this.roleService.addRule(action.rule).pipe(
      tap((data: any) => {
        if (data) {
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            rules: [...data, ...state.rules],
          });
        }
      })
    );
  }

  @Action(GetRules)
  getRules(ctx: StateContext<any>, action: GetRules) {
    return this.roleService.getRules().pipe(
      tap((data: any) => {
        if (data) {
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            rules: data,
          });
        }
      })
    );
  }

  @Action(ToggleRuleState)
  toggleRuleState(ctx: StateContext<any>, action: ToggleRuleState) {
    return this.roleService.toggleRuleState(action.rule_id, action.state).pipe(
      tap((data: any) => {
        if (data) {
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            rules: [...state.rules].map((e) => {
              if (e._id === data._id) {
                return data;
              }
              return e;
            }),
          });
        }
      })
    );
  }
}
