import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit {
  @Input() outline = true;
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() disabled = false;
  @Input() evaIconBefore: string;
  @Input() evaIconAfter: string;

  @Input() fullWidth = false;
  @Input() chunky = false;
  @Input() small = false;

  constructor() {}

  ngOnInit(): void {}
}
