import { Injectable } from '@angular/core';
import {Observable, Subject, BehaviorSubject, Observer, of} from "rxjs";
import { share } from 'rxjs/operators';

// const CAMPAIGN_GRID_COLS = new Map([
//     ["xs", 1],
//     ["sm", 3],
//     ["md", 3],
//     ["lg", 4],
//     ["xl", 4]]);


@Injectable()
export class GlobalsService {
    public clients = new BehaviorSubject<any>(null);
    public client$ = this.clients.asObservable();

    public markets = new BehaviorSubject<any>(null);
    public market$ = this.markets.asObservable();

    public apps = new BehaviorSubject<any>(null);
    public apps$ = this.apps.asObservable();

    public compilationName = new BehaviorSubject<any>(null);
    public compilationName$ = this.compilationName.asObservable();


    public cardCount: Observable<number>;

    progressObserver= new BehaviorSubject<any>(null);
    progress$: any;
    progress: any;

    public breadCrumbTitle = new BehaviorSubject<any>(null);
    public breadCrumbTitle$ = this.breadCrumbTitle.asObservable();

    debug = false;


    constructor() {
        this.progress$ = Observable.create(observer => {
            this.progressObserver = observer;
        }).pipe(share());

        // this.progress$ = of(this.progressObserver);

        // this.progress$ = Observable.create(observer => {
        //     this.progressObserver = observer
        // }).share();

        // this.cardCount = this.observableMedia.asObservable()
        //     .map(change => {
        //         return CAMPAIGN_GRID_COLS.get(change.mqAlias);
        //     });
    }

    // private clientSnap = new BehaviorSubject<string>(this.clientMapper());
    public setGlobalClient(data: any) {
        if (this.debug){
            console.log('setting client in gs:'  + JSON.stringify(data));
        }
        localStorage.setItem('Client', JSON.stringify(data.selectedClient));
        this.clients.next(data);
    }

    // private clientSnap = new BehaviorSubject<string>(this.clientMapper());
    public setGlobalMarket(data: any) {
        localStorage.setItem('Market', JSON.stringify(data.selectedMarket));
        this.markets.next(data);
    }
    public setCompilation(data: any) {
        this.compilationName.next(data);
    }

    public setGlobalApps(data: any) {
        this.apps.next(data);
    }

    public setbreadCrumbTitle(data: any) {
        this.breadCrumbTitle.next(data);
    }

    makeFileRequest (url: string, params: string[], file: File): Observable<any> {
        return Observable.create(observer => {
            // let formData: FormData = new FormData(),
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next();
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);
                this.progressObserver.next(this.progress);
            };

            xhr.open('PUT', url, true);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.send(file);
        });
    }

    makeFileRequest2 (uploadInfo: any, params: string[], file: File): Observable<any> {
        return Observable.create(observer => {
            let fd = new FormData();
            fd.append('key', uploadInfo.key);
            fd.append('Content-Type', uploadInfo['Content-Type']);
            fd.append('GoogleAccessId', uploadInfo['GoogleAccessId']);
            fd.append('policy', uploadInfo.policy);
            fd.append('signature', uploadInfo.signature);
            fd.append('file', file);

            let xhr: XMLHttpRequest = new XMLHttpRequest();

            // for (let i = 0; i < files.length; i++) {
            //   formData.append("uploads[]", files[i], files[i].name);
            // }
            // formData.append("file", file, file.name);



            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 204) {
                        console.log(xhr.response);
                        observer.next();
                        observer.complete();
                    } else {
                        console.log(xhr.status);
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);
                this.progressObserver.next(this.progress);
            };

            xhr.open('POST', "https://" + uploadInfo.bucket + ".storage.googleapis.com/");

            // Send our FormData object; HTTP headers are set automatically
            xhr.send(fd);

            // xhr.open('PUT', url, true);
            // xhr.setRequestHeader("Content-Type", file.type);
            // xhr.send(file);
        });
    }
}
