import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-text-truncated',
  templateUrl: './text-truncated.component.html',
  styleUrls: ['./text-truncated.component.scss'],
})
export class TextTruncatedComponent implements OnChanges {
  @Input() text: string;
  @ViewChild('textContainer', { static: true }) textContainer: ElementRef;
  tooltipDisabled: boolean;

  constructor() {}

  ngOnChanges() {
    this.tooltipDisabled =
      this.textContainer?.nativeElement.scrollWidth <=
      this.textContainer?.nativeElement.clientWidth;
  }
}
