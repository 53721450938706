import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  TitleCasePipe,
  UpperCasePipe
} from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SheetComponent } from './components/sheet/sheet.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { NotifierComponent } from './components/notifier/notifier.component';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { MatTableExporterModule } from 'mat-table-exporter';
import { DownloadDataUrlService } from './helpers/download-data-url.service.ts.service';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts/core';
import { BarChart, LineChart, PieChart, ScatterChart } from 'echarts/charts';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { SortByComponent } from '@shared/components/sort-by/sort-by.component';

import {
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  DataZoomComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  LineChart,
  BarChart,
  PieChart,
  ScatterChart,
  CanvasRenderer,
  LegendComponent,
  BrushComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  DataZoomComponent
]);
import { ConfirmComponent } from './components/dialogs/confirm/confirm.component';
import { DialogConfirm } from './components/dialog-confirm/dialog-confirm';
import { AngularToastifyModule } from 'angular-toastify';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { NewSheetComponent } from '@shared/ui-components/new-sheet/new-sheet.component';
import { CellComponent } from './ui-components/new-sheet/cell/cell.component';
import { GroupByPipe } from '@shared/pipes/pipes/group-by.pipe';
import { NgLetModule } from 'ng-let';
import { KpiPipe } from '@shared/pipes/pipes/kpi.pipe';
import { CustomSnackBarComponent } from '@shared/components/custom-snack-bar/custom-snack-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    UiComponentsModule,
    PipesModule,
    DirectivesModule,
    AngularToastifyModule,
    NgxEchartsModule.forRoot({ echarts }),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    FlexLayoutModule,
    NgSelectModule,
    NgxMasonryModule,
    MatTableExporterModule,
    NgxMatSelectSearchModule,
    NgxDatatableModule,
    ToastrModule.forRoot(),

    SlickCarouselModule,
    NgxFileDragDropModule,
    NgLetModule
  ],
  declarations: [
    NewSheetComponent,
    CellComponent,
    SheetComponent,
    NotifierComponent,
    DialogConfirm,
    ConfirmComponent,
    CustomSnackBarComponent,
    SortByComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    UiComponentsModule,
    PipesModule,
    DirectivesModule,
    UiComponentsModule,
    SlickCarouselModule,
    AngularToastifyModule,
    NgxEchartsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    FlexLayoutModule,
    NgSelectModule,
    NgxMasonryModule,
    MatTableExporterModule,
    NgxMatSelectSearchModule,
    NgxDatatableModule,
    ToastrModule,
    NewSheetComponent,
    CellComponent,
    SheetComponent,
    NotifierComponent,
    DialogConfirm,
    ConfirmComponent,
    NgxFileDragDropModule,
    NgLetModule,
    CustomSnackBarComponent,
    SortByComponent
  ],
  providers: [
    DecimalPipe,
    DownloadDataUrlService,
    DatePipe,
    UpperCasePipe,
    TitleCasePipe,
    GroupByPipe,
    KpiPipe
  ],
  entryComponents: [CustomSnackBarComponent]
})
export class SharedModule {
}
