<div class="progress" [ngClass]="{'progress--fullScreen': fullScreen}">
  <!-- <mat-spinner></mat-spinner> -->
  <!-- <p>{{selectedMsg}}</p> -->

  <div class="flex flex-col justify-center">
    <div class="spinner"></div>
    <p>Loading..</p>
  </div>

</div>
