import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CellClass } from './cell.class';

@Component({
  selector: 'cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss'],
})
export class CellComponent implements OnInit {
  constructor() {}

  @Input() cellInput: CellClass = new CellClass();
  @Output() functionOutput = new EventEmitter();

  ngOnInit(): void {}
  cellFunction(key) {
    this.functionOutput.emit({
      row: this.cellInput.row,
      column: this.cellInput.column,
      key: key,
    });
  }
}
